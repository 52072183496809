import { Storage } from 'aws-amplify';
import { S3Image } from 'aws-amplify-react';
import React, { Component } from 'react';
import {Button, Table, Card} from 'react-bootstrap'
import PropTypes from 'prop-types';
import uuid from 'uuid/v1';
import Logger from '../../utils/Logger'
const logger = new Logger("Attachments.js");

const AttachmentMode = {
    AllowDelete: "AllowDelete", 
    AllowDownload: "AllowDownload", 
    AllowUpload: "AllowUpload"};

const AttachmentType = {
    Images: "Images",
    Files: "Files"
}

const defaultAttachmentModes = [
    AttachmentMode.AllowDelete,
    AttachmentMode.AllowDownload,
    AttachmentMode.AllowUpload];

const defaultAttachmentType = AttachmentType.Files;

class Attachments extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            title: this.props.title,
            files: this.props.files || [],
            attachmentModes: this.props.attachmentModes || defaultAttachmentModes,
            attachmentType: this.props.attachmentType || defaultAttachmentType,
            onCreate: this.props.onCreate || function(file){return file;},
            onDelete: this.props.onDelete || function(){},
            loading: false
        }
    }

    componentDidMount() {
        
    }

    delete(file) {
        const s3Id = file.s3Id;

        if (!window.confirm("Are you sure you wish to delete this item? This cannot be undone!")) return;

        Storage.remove(s3Id)
        .then(result => {
            this.setState(state => {
                return {files: state.files.filter(item => item !== file)}
            });
        })
        .then(() => this.state.onDelete(file))
        .catch(logger.handleError);
    }

    onAdd = (e) => {
        const file = e.target.files[0];

        if (!file) return;

        this.setState({loading: true})

        Storage.put(uuid(), file, {
            contentType: file.type,
            contentDisposition: "attachment; filename=" + file.name //Fix to make sure files get downloaded with their actual name/ extension
        })
        .then((response) => this.state.onCreate({s3Id: response.key, label: file.name}))
        .then(file => {
            this.setState(state => {
                return {files: state.files.concat(file)}
            })
        })
        .then(() => this.setState({loading: false}))
        .catch(logger.handleError);
    }

    download = (s3Id) => {
        Storage.get(s3Id, {expires: 60})
        .then(result => {
            window.open(result);
        })
        .catch(logger.handleError);
    }

    resolveContentType = (attachmentType) => {
        switch (attachmentType) {
            case AttachmentType.Images:
                return 'image';
            case AttachmentType.Files:
                return '*';
            default:
                return '*';
        }
    }

    deleteButton = (item, text) => 
        <Button variant="danger" size="sm" onClick={() => this.delete(item)}>{text}</Button>

    uploader = () => 
        <input
            type="file" accept={this.resolveContentType(this.state.attachmentType)}
            onChange={(e) => this.onAdd(e)}
        />

    downloadLink = (item) =>
        <Button variant="link" onClick={() => this.download(item.s3Id)}>{item.label}</Button>


    checkForMode = (mode) => this.state.attachmentModes.indexOf(mode) >= 0;

    filesTable = () => 
        <Table striped bordered>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {this.state.files.map(item => (
                    <tr key={item.s3Id}>
                        <td>{this.checkForMode(AttachmentMode.AllowDownload) ? this.downloadLink(item) : <span>{item.label}</span>}</td>
                        <td>{this.checkForMode(AttachmentMode.AllowDelete) && this.deleteButton(item, 'Delete')}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    

    imagesTable = () => 
        <Table striped bordered>
            <thead>
                <tr>
                    {this.state.files.map(item => (
                        <th key={item.s3Id}>{item.label} {this.checkForMode(AttachmentMode.AllowDelete) && this.deleteButton(item, 'X')}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                <tr>
                    {this.state.files.map(item => (
                        <td key={item.s3Id}><S3Image imgKey={item.s3Id} /></td>
                    ))}
                </tr>
            </tbody>
        </Table>

    loadingMessage = () => "Uploading... Please Wait..."

    render() {
        return (
            <Card.Body>
                <Card.Title>{this.state.title}</Card.Title>
                <Card.Subtitle>{this.checkForMode(AttachmentMode.AllowUpload) && !this.state.loading && this.uploader()} {this.state.loading && this.loadingMessage()}</Card.Subtitle>
                {this.state.attachmentType === AttachmentType.Files ? this.filesTable() : this.imagesTable()}
            </Card.Body>
        )
    }
}

Attachments.propTypes = {
    title: PropTypes.string.isRequired,
    /** An array of attachments to start with. Defaults to empty. Must have shape below at minimum, but could have extra props (e.g. fileId). */
    files: PropTypes.arrayOf(PropTypes.shape({
        /** The display label for this attachment */
        label: PropTypes.string.isRequired,
        /** The S3 id (key) for this attachment */
        s3Id: PropTypes.string.isRequired
    })),
    /** An array of AttachmentMode to indicate what operations are permitted. Defaults to all. */
    attachmentModes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(AttachmentMode))),
    /** The Attachment Type this component should deal with */
    attachmentType: PropTypes.oneOf(Object.values(AttachmentType)).isRequired,
    /** A function which accepts a file object and returns the same file object (or a promise that returns) but modified (e.g. add fileId) */
    onCreate: PropTypes.func.isRequired,
    /** A function which accepts a file object. Should delete in outside system. */
    onDelete: PropTypes.func.isRequired
}

export default Attachments

export {AttachmentMode, AttachmentType}
