import React, { Component, Fragment } from 'react';
import {
  API,
  graphqlOperation
} from 'aws-amplify'
import { Button, Badge, Col, Form } from 'react-bootstrap'
import * as mutations from '../../graphql/mutations'
import Select from 'react-select'

import uuid from 'uuid/v1'
import Bottleneck from 'bottleneck'
import makeComponentTrashable from 'trashable-react';
import Logger from '../../utils/Logger'

const logger = new Logger("ParticularRow.js");

class ParticularRow extends Component {

  gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

  constructor(props) {
    super(props);

    this.state = {
      questionData: this.props.questionData,
      allAnswers: this.props.allAnswers,
      influencer: this.props.influencer
    }
  }

  render = () => {
    const {
      question,
      answers
    } = this.state.questionData;

    return (
      <tr key={uuid()}>
        <td>{question.label}</td>
        <td>{answers.map((a) => (
              <Fragment key={uuid()}>
                <Badge variant="secondary">{a.label}</Badge><Badge variant="danger" type="button" onClick={() => this.deleteResponse(a)}>X</Badge><span>   </span>
              </Fragment>
            ))}
        </td>
        <td>
          <Form>
            <Form.Row>
              <Col sm={8}>
                <Select
                  value={this.state.newResponse}
                  onChange={(selectedOption) => this.setState({ newResponse: selectedOption })}
                  options={this.dropDownOptions(question, answers)}
                  placeholder={'Select Response...'}
                />
              </Col>
              <Col sm={4}>
                <Button variant='outline-primary' onClick={() => this.addResponse()}>Add</Button>
              </Col>
            </Form.Row>
          </Form>
        </td>
      </tr>
    )
  }

  dropDownOptions = (question, answers) => {
    let answersForQuestion = this.state.allAnswers.filter(a => a.tagId === question.tagId);
    return answersForQuestion.filter(a => answers.findIndex(i => i.tagValueId === a.tagValueId) < 0)
  }

  addResponse = () => {
    let newResponse = this.state.newResponse;
    if (!newResponse) return;

    let questionStateCopy = Object.assign({}, this.state.questionData);
    questionStateCopy.answers.push(newResponse);

    let createInfluencerTagValueInput = {
      influencerId: this.state.influencer.influencerId,
      tagValueId: newResponse.tagValueId
    }

    this.graphqlOp(graphqlOperation(mutations.createInfluencerTagValue, { createInfluencerTagValueInput }))
      .then(() => {
        this.setState({ questionData: questionStateCopy });
        this.setState({ newResponse: undefined });
      })
      .catch(logger.handleError)
  }

  deleteResponse = (responseToDelete) => {
    let questionStateCopy = Object.assign({}, this.state.questionData);
    let currentResponses = this.state.questionData.answers;
    let responsesAfterDeletion = currentResponses.filter(res => res.tagValueId !== responseToDelete.tagValueId);

    questionStateCopy.answers = responsesAfterDeletion;

    let deleteObj = {
      tagValueId: responseToDelete.tagValueId,
      influencerId: this.state.influencer.influencerId
    }

    const {
      tagValueId,
      influencerId
    } = deleteObj;

    this.graphqlOp(graphqlOperation(mutations.deleteInfluencerTagValue, { tagValueId, influencerId }))
      .then(() => {
        this.setState({ questionData: questionStateCopy });
        this.props.updateParentQuestionMapCB(questionStateCopy);
      })
      .catch(logger.handleError)
  }
}

export default makeComponentTrashable(ParticularRow)