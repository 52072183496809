import React from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../../graphql/queries'
import { renderLoading } from '../../utils/format'
import PropTypes from 'prop-types'
import { Card, Form } from 'react-bootstrap'
import makeComponentTrashable from 'trashable-react';
import Bottleneck from 'bottleneck'
import Logger from '../../utils/Logger'
const logger = new Logger("RelationshipStatusSelect.js");

class RelationshipStatusSelect extends React.Component {
    gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        /** If no onChange, just return the text */
        onChange: PropTypes.func
    }

    constructor(props) {
        super(props)

        this.state = {
            loadingStatus: true
        }
    }

    componentDidMount = () => {
        this.graphqlOp(graphqlOperation(queries.listInfluencerRelationshipStatuss))
        .then(response => this.setState({loadingStatus: false, statuses: this.getListOfOptions(response.data.listInfluencerRelationshipStatuss)}))
        .catch(logger.handleError);
    }

    getListOfOptions(dbStatuses) {
        return dbStatuses.map(status => (<option key={status.influencerRelationshipStatusId} value={status.influencerRelationshipStatusId}>{status.relationshipLabel}</option>));
    }

    renderForm = () => (
        <Form.Group controlId="relationshipStatus">
            <Form.Control
                as="select"
                name="influencerRelationshipStatusId"
                value={this.props.value}
                onChange={this.props.onChange}
            >
                {this.state.statuses}
            </Form.Control>
        </Form.Group>
    )

    renderValue = () => {
        return (
        <p>{this.state.statuses.find(x => x.props.value === this.props.value).props.children}</p>   
    )
        }

    renderStatusCard = () => (
        <Card className="mt-4">
            <Card.Body>
                <Card.Title>Relationship Status</Card.Title>
                {this.props.onChange ? this.renderForm() : this.renderValue()}
            </Card.Body>
        </Card>
    )

    render() {
        return this.state.loadingStatus ? renderLoading("Status") : this.renderStatusCard();
    }
}

export default makeComponentTrashable(RelationshipStatusSelect)