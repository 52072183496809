import React, { Component } from 'react'
import {
  API,
  graphqlOperation
} from 'aws-amplify'
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row
} from 'react-bootstrap'
import makeComponentTrashable from 'trashable-react';
import RelationshipStatusSelect from '../common/RelationshipStatusSelect'
import { transformFieldArray, replaceNullFieldValues } from '../common/FormFieldStateHelper';
import Bottleneck from 'bottleneck'
import {
  Redirect,
  Link
} from 'react-router-dom'
import { getInfluencerPlatformStats, createInfluencer, updateInfluencer } from '../influencer/influencerUtils';
import * as queries from '../../graphql/queries'
import * as format from '../../utils/format'
import _ from 'lodash'
import Logger from '../../utils/Logger'
import CustomTagsModal from '../common/CustomTagsModal'

const logger = new Logger("EditInfluencer.js")

const Title = ({ mode }) =>
  mode === 'create' ? <h2>Create Influencer</h2> : <h2>Edit Influencer</h2>

const FormErrors = ({ message, show }) =>
  show ? <Alert variant="danger">{message}</Alert> : null;

const FormSuccess = ({ message, show }) =>
  show ? <Alert variant="success">{message}</Alert> : null;

class EditInfluencer extends Component {
  gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

  constructor(props) {
    super(props)

    this.handleProfileChange = this.handleProfileChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.fields = [
      'influencerId',
      'prefix',
      'firstName',
      'lastName',
      'suffix',
      'pinterestHandle',
      'pinterestFollowers',
      'pinterestFollowersUpdatedTime',
      'linkedInURL',
      'linkedInFollowers',
      'linkedInFollowersUpdatedTime',
      'websiteURL',
      'websiteFollowers',
      'websiteFollowersUpdatedTime',
      'snapchatHandle',
      'tumblrHandle',
      'unsubscribed',
      'phone',
      'blogURL',
      'emailAddress1',
      'emailAddress2',
      'influencerRelationshipStatusId',
      'addressLine1',
      'city',
      'state',
      'zip',
      'country',
      'bio',
      'notes',
      'constantContactId',
      'replyId',
      'ytChannelUrl',
      'customTags'
    ];

    this.platformStats = {
      statFB: {username: '', pageLikes: ''},
      statIG: {handle: '', followers: ''},
      statTW: {username: '', followers: ''},
      statYT: {username: '', subscribers: '', channelUrl: ''},
      fbOriginalData: {username: '', pageLikes: ''},
      igOriginalData: {handle: '', followers: ''},
      twOriginalData: {username: '', followers: ''},
      ytOriginalData: {username: '', subscribers: '', channelUrl: ''},
    }

    this.state = {
      mode: 'create',
      loadingProfile: true,
      loadingPlatforms: true,
      igOperation: 'update',
      fbOperation: 'update',
      ytOperation: 'update',
      twOperation: 'update',
      platformStats: this.platformStats,
      profile: transformFieldArray(this.fields),
      redirect: false,
      numericFields: [
        'influencerId',
        'pinterestFollowers',
        'linkedInFollowers',
        'websiteFollowers',
        'unsubscribed',
        'linkedInFollowersUpdatedTime',
        'pinterestFollowersUpdatedTime',
        'websiteFollowersUpdatedTime',
        'influencerRelationshipStatusId',
        'followers',
        'pageLikes',
        'subscribers'
      ],
      fieldmap: {
        emailAddress: {object: 'profile', property: 'emailAddress1'},
        linkedInURL: {object: 'profile', property: 'linkedInURL'},
        pinterestHandle: {object: 'profile', property: 'pinterestHandle'},
        ytUsername: {object: 'statYT', property: 'username'},
        twUsername: {object: 'statTW', property: 'username'},
        igHandle: {object: 'statIG', property: 'handle'},
        fbUsername: {object: 'statFB', property: 'username'},
        ytChannelUrl: {object: 'statYT', property: 'channelUrl'}
      }
    }
  }

  componentDidMount = () => {
    const { id } = this.props.match.params
    if (id) {
      this.setState({
        mode: 'edit'
      }, () => this.getInfluencerById(id));
    } else {
      this.setState({
        loadingProfile: false,
        loadingPlatforms: false
      });
      if (this.props.location) {
        let thisInstance = this;
        _.forOwn(this.props.location.state, (value, field) => {
          let map = thisInstance.state.fieldmap[field];
          let obj = map.object === 'profile' ? thisInstance.state[map.object] : thisInstance.state.platformStats[map.object];
          obj[map.property] = value;
          thisInstance.setState({obj})
        })
      }
    }
  }

  componentDidUpdate = () => {
    if (this.state.platformStats.statIG && this.state.platformStats.statFB && this.state.platformStats.statTW && this.state.platformStats.statYT && this.state.loadingPlatforms) {
      this.setState({ loadingPlatforms: false });
    }
  }

  getInfluencerById = (id) => {
    this.graphqlOp(graphqlOperation(queries.getInfluencer, { influencerId: id }))
      .then((response) => {
        let influencer = replaceNullFieldValues(format.undoEscapesJson(response.data.getInfluencer));
        this.setState({
          loadingProfile: false,
          profile: influencer,
          originalProfile: influencer
        }, () => getInfluencerPlatformStats(id)
          .then(stats => {
            let influencerStats = {};
            Object.keys(this.platformStats).forEach(key => {
              influencerStats[key] = (Object.keys(stats[key]).length === 0) ? this.platformStats[key] : stats[key];
            })
            this.setState({platformStats: influencerStats});
          })
          .catch(error => {
            logger.handleError(error);
            this.setState({platformStats: this.platformStats});
          })
        );
      })
      .catch(logger.handleError)
  }

  handleProfileChange = event => {
    let profileCopy = Object.assign({}, this.state.profile);
    profileCopy[event.target.name] = event.target.value;

    this.setState({ profile: profileCopy });
  }

  handleProfileCheckChange = event => {
    let profileCopy = Object.assign({}, this.state.profile);
    profileCopy[event.target.name] = (event.target.checked ? 1 : 0);

    this.setState({ profile: profileCopy });
  }

  handleSocialProfileChange = (event, field, object) => {
    const stats = this.state.platformStats;
    let socialCopy = Object.assign({}, stats[object]);
    socialCopy[field] = event.target.value;
    stats[object] = socialCopy;

    this.setState({ platformStats: stats });
  }

  handleSubmit = event => {
    let profileCopy = Object.assign({}, this.state.profile);

    this.setState({ profile: profileCopy });

    event.preventDefault()

    let influencer = format.escapeJson(profileCopy);
    influencer.unsubscribed = parseInt(influencer.unsubscribed);

    if (this.state.mode !== 'create') {
      if (this.state.originalProfile.pinterestFollowers !== this.state.profile.pinterestFollowers) {
        influencer.pinterestFollowersUpdatedTime = format.getCurrentTimestamp();
      }

      if (this.state.originalProfile.linkedInFollowers !== this.state.profile.linkedInFollowers) {
        influencer.linkedInFollowersUpdatedTime = format.getCurrentTimestamp();
      }

      if (this.state.originalProfile.websiteFollowers !== this.state.profile.websiteFollowers) {
        influencer.websiteFollowersUpdatedTime = format.getCurrentTimestamp();
      }

      const input = _.pick(influencer, this.fields);
      _.forEach(input, (value, key) => {
        if (value !== '') return;
        if (this.state.numericFields.includes(key)){
          input[key] = undefined;
        }
      })

      logger.handleTrace('updating...')

      updateInfluencer(input, this.state.platformStats)
      .then(() => {
        this.setState({
          formSuccess: true,
          formSuccessMessage: 'Influencer updated!',
          redirect: true
        });
      })
      .catch(logger.handleError);

    } else {

      logger.handleTrace('creating...')

      const timestamp = format.getCurrentTimestamp();

      influencer.unsubscribed = 0
      influencer.constantContactId = 0
      influencer.replyId = 0
      influencer.creationSource = "Manual Entry"
      if (this.state.profile.pinterestFollowers) influencer.pinterestFollowersUpdatedTime = timestamp;
      if (this.state.profile.linkedInFollowers) influencer.linkedInFollowersUpdatedTime = timestamp;
      if (this.state.profile.websiteFollowers) influencer.websiteFollowersUpdatedTime = timestamp;

      influencer = _.pickBy(influencer, value => {
        return !_.isEmpty(_.toString(value));
      });

      createInfluencer(influencer, this.state.platformStats)
      .then(resInfluencerId => {
         let profileCopy = Object.assign({}, this.state.profile);
          profileCopy.influencerId = resInfluencerId;
          this.setState({
            profile: profileCopy,
            formSuccess: true,
            formSuccessMessage: 'Influencer created!',
            redirect: true
          });
      })
      .catch(logger.handleError);
    }
  }

  renderNameCard = () => {
    const data = this.state.profile;
    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Name</Card.Title>
          <Form.Group controlId="influencerPrefix">
            <Form.Label>Prefix</Form.Label>
            <Form.Control
              maxLength="10"
              name="prefix"
              value={data.prefix}
              onChange={this.handleProfileChange}
            />
          </Form.Group>

          <Form.Group controlId="influencerFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              maxLength="50"
              name="firstName"
              value={data.firstName}
              onChange={this.handleProfileChange}
            />
          </Form.Group>

          <Form.Group controlId="influencerLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              maxLength="50"
              name="lastName"
              value={data.lastName}
              onChange={this.handleProfileChange}
            />
          </Form.Group>

          <Form.Group controlId="influencerSuffix">
            <Form.Label>Suffix</Form.Label>
            <Form.Control
              maxLength="10"
              name="suffix"
              value={data.suffix}
              onChange={this.handleProfileChange}
            />
          </Form.Group>
        </Card.Body>
      </Card>
    )
  }

  renderSocialCard = () => {
    let profileData = this.state.profile;

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Social</Card.Title>
          <Row>
            <Col>
              <Form.Group controlId="facebook.username">
                <Form.Label><strong>Facebook</strong> Username</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="facebook.username"
                  value={this.state.platformStats.statFB.username}
                  onChange={event => {
                    this.handleSocialProfileChange(event, 'username', 'statFB');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="facebook.pageLikes">
                <Form.Label>Page Likes</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="facebook.pageLikes"
                  value={this.state.platformStats.statFB.pageLikes}
                  onChange={event => {
                    this.handleSocialProfileChange(event, 'pageLikes', 'statFB');
                  }}
                />
                <Form.Text>
                  {format.renderTimeStamp(this.state.platformStats.statFB.pageLikesLastUpdated)}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="instagram.handle">
                <Form.Label><strong>Instagram</strong>  Handle</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="instagram.handle"
                  value={this.state.platformStats.statIG.handle}
                  onChange={event => {
                    this.handleSocialProfileChange(event, 'handle', 'statIG');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="instagram.followers">
                <Form.Label>Followers</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="instagram.followers"
                  value={this.state.platformStats.statIG.followers}
                  onChange={event => {
                    this.handleSocialProfileChange(event, 'followers', 'statIG');
                  }}
                />
                <Form.Text className="text-muted">
                  {format.renderTimeStamp(this.state.platformStats.statIG.followersLastUpdated)}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="twitter.username">
                <Form.Label><strong>Twitter</strong> Username</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="twitter.username"
                  value={this.state.platformStats.statTW.username}
                  onChange={event => {
                    this.handleSocialProfileChange(event, 'username', 'statTW');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="twitter.followers">
                <Form.Label>Followers</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="twitter.followers"
                  value={this.state.platformStats.statTW.followers}
                  onChange={event => {
                    this.handleSocialProfileChange(event, 'followers', 'statTW');
                  }}
                />
                <Form.Text className="text-muted">
                  {format.renderTimeStamp(this.state.platformStats.statTW.followersLastUpdated)}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>


          <Row>
            <Col>
              <Form.Group controlId="youTube.username">
                <Form.Label><strong>Youtube</strong> Username</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="youTube.username"
                  value={this.state.platformStats.statYT.username}
                  onChange={event => {
                    this.handleSocialProfileChange(event, 'username', 'statYT');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="youTube.subscribers">
                <Form.Label>Subscribers</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="youTube.subscribers"
                  value={this.state.platformStats.statYT.subscribers}
                  onChange={event => {
                    this.handleSocialProfileChange(event, 'subscribers', 'statYT');
                  }}
                />
                <Form.Text className="text-muted">
                  {format.renderTimeStamp(this.state.platformStats.statYT.subscribersLastUpdated)}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="youTube.channelUrl">
                <Form.Label>Channel URL</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="youTube.channelUrl"
                  value={this.state.platformStats.statYT.channelUrl}
                  onChange={event => {
                    this.handleSocialProfileChange(event, 'channelUrl', 'statYT');
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="pinterest.handle">
                <Form.Label><strong>Pinterest</strong> Handle</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="pinterestHandle"
                  value={profileData.pinterestHandle}
                  onChange={this.handleProfileChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="pinterest.followerCount">
                <Form.Label>Followers</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="pinterestFollowers"
                  value={profileData.pinterestFollowers}
                  onChange={this.handleProfileChange}

                />
                <Form.Text className="text-muted">
                  {format.renderTimeStamp(profileData.pinterestFollowersUpdatedTime)}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="linkedInHandle">
                <Form.Label><strong>TikTok</strong> Handle</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="linkedInURL"
                  value={profileData.linkedInURL}
                  onChange={this.handleProfileChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="linkedInCount">
                <Form.Label>Followers</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="linkedInFollowers"
                  value={profileData.linkedInFollowers}
                  onChange={this.handleProfileChange}

                />
                <Form.Text className="text-muted">
                  {format.renderTimeStamp(profileData.linkedInFollowersUpdatedTime)}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="influencerWebsite">
                <Form.Label><strong>Website</strong> Url</Form.Label>
                <Form.Control
                  maxLength="100"
                  name="websiteURL"
                  value={profileData.websiteURL}
                  onChange={this.handleProfileChange}

                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="influencerWebsiteFollowers">
                <Form.Label>Blog UMV</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="websiteFollowers"
                  value={profileData.websiteFollowers}
                  onChange={this.handleProfileChange}
                />
                <Form.Text className="text-muted">
                  {format.renderTimeStamp(profileData.websiteFollowersUpdatedTime)}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          {<Row>
            <Col /*Snapchat field was reused to save the Threads Handle*/>
              <Form.Group controlId="snapchatHandle" >
                <Form.Label><strong>Threads</strong> Handle</Form.Label> 
                <Form.Control
                  maxLength="50"
                  name="snapchatHandle"
                  value={profileData.snapchatHandle}
                  onChange={this.handleProfileChange}
                />
              </Form.Group>
            </Col>
            <Col> </Col>
          </Row>
          /* <Row>
            <Col>
              <Form.Group controlId="tumblrHandle">
                <Form.Label><strong>Tumblr</strong> Handle</Form.Label>
                <Form.Control
                  maxLength="50"
                  name="tumblrHandle"
                  value={profileData.tumblrHandle}
                  onChange={this.handleProfileChange}
                />
              </Form.Group>
            </Col>
            <Col> </Col>
          </Row> */}
        </Card.Body>
      </Card>
    )
  }

  renderContactCard = () => {
    let data = this.state.profile

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Contact</Card.Title>
          <Form.Group controlId="influencerUnsubscribed">
            <Form.Label>Unsubscribed?&nbsp;</Form.Label>
            <Form.Check
              inline
              name="unsubscribed"
              type="checkbox"
              checked={data.unsubscribed === 1 ? true : false}
              onChange={this.handleProfileCheckChange}
            />
          </Form.Group>
          <Form.Group controlId="influencerPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              maxLength="45"
              name="phone"
              value={data.phone}
              onChange={this.handleProfileChange}
            />
          </Form.Group>
          <Form.Group controlId="influencerBlog">
            <Form.Label>Blog</Form.Label>
            <Form.Control
              type="url"
              maxLength="100"
              name="blogURL"
              value={data.blogURL}
              onChange={this.handleProfileChange}
            />
          </Form.Group>
        </Card.Body>
      </Card>
    )
  }

  renderEmailsCard = () => {
    let data = this.state.profile

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Emails</Card.Title>
          <Form.Group controlId="primaryEmail">
            <Form.Label className="sr-only">Primary Email</Form.Label>
            <Form.Control
              name="emailAddress1"
              value={data.emailAddress1}
              onChange={this.handleProfileChange}
            />
          </Form.Group>
          <Form.Group controlId="secondaryEmail">
            <Form.Label className="sr-only">Secondary Email</Form.Label>
            <Form.Control
              name="emailAddress2"
              value={data.emailAddress2}
              onChange={this.handleProfileChange}
            />
          </Form.Group>
        </Card.Body>
      </Card>
    )
  }

  renderStatusCard = () => {
    let data = this.state.profile

    data.influencerRelationshipStatusId = data.influencerRelationshipStatusId || 1;

    return (
      <RelationshipStatusSelect value={data.influencerRelationshipStatusId} onChange={this.handleProfileChange} makeSelectable={true} />
    )
  }

  renderAddressCard = () => {
    let data = this.state.profile

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Address</Card.Title>
          <Form.Group controlId="influencerAddressLine1">
            <Form.Label>Street</Form.Label>
            <Form.Control
              name="addressLine1"
              value={data.addressLine1}
              onChange={this.handleProfileChange}
            />
          </Form.Group>
          <Form.Group controlId="influencerCity">
            <Form.Label>City</Form.Label>
            <Form.Control
              name="city"
              value={data.city}
              onChange={this.handleProfileChange}
            />
          </Form.Group>
          <Form.Group controlId="influencerState">
            <Form.Label >State</Form.Label>
            <Form.Control
              name="state"
              value={data.state}
              onChange={this.handleProfileChange}
            />
          </Form.Group>
          <Form.Group controlId="influencerZip">
            <Form.Label>ZIP</Form.Label>
            <Form.Control
              name="zip"
              value={data.zip}
              onChange={this.handleProfileChange}
            />
          </Form.Group>
          <Form.Group controlId="influencerCountry">
            <Form.Label>Country</Form.Label>
            <Form.Control
              name="country"
              value={data.country}
              onChange={this.handleProfileChange}
            />
          </Form.Group>
        </Card.Body>
      </Card>
    )
  }
  renderBioCard = () => {
    let data = this.state.profile

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Bio</Card.Title>
          <Form.Group controlId="influencerBio">
            <Form.Label className="sr-only">Bio</Form.Label>
            <Form.Control
              as="textarea"
              name="bio"
              value={data.bio}
              onChange={this.handleProfileChange}
            />
          </Form.Group>
        </Card.Body>
      </Card>
    )
  }

  renderNotesCard = () => {
    let data = this.state.profile

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Important Notes</Card.Title>
          <Form.Group controlId="influencerNotes">
            <Form.Label className="sr-only">Important Notes</Form.Label>
            <Form.Control
              as="textarea"
              name="notes"
              value={data.notes}
              onChange={this.handleProfileChange}
            />
          </Form.Group>
        </Card.Body>
      </Card>
    )
  }
  renderTagsCard = () => {
    let data = this.state.profile
    
    if (!data) return
    
    let customTagsModalClose = () => { this.setState({ showCustomTagsModal: false }) }
    
    return (
      <><CustomTagsModal
        show={this.state.showCustomTagsModal}
        influencer={this.state.profile}
        onHide={customTagsModalClose} 
        onSubmit={(data) => {
          this.setState({ profile: { ...this.state.profile, customTags: data } });
        }}/>
        <Card className="mt-4">
          <Card.Body>
            <Card.Title>
              Tags<Button className="ml-2" variant="outline-primary" onClick={() => this.setState({ showCustomTagsModal: true })}>Edit</Button>
            </Card.Title>
            {data.customTags}
          </Card.Body>
        </Card></>
    )
  }
  render() {
    const {
      loadingProfile,
      loadingPlatforms,
      redirect } = this.state
    if (redirect) {
      return <Redirect to={`/influencers/details/${this.state.profile.influencerId}`} />;
    }
    const { id } = this.props.match.params;
    return (
      <Container fluid>
        <Form id="EditInfluencerForm" onSubmit={event => this.handleSubmit(event)}>
          <Row>
            <Col>
              <Title mode={this.state.mode} />
              <FormSuccess message={this.state.formSuccessMessage} show={this.state.formSuccess} />
              <FormErrors message={this.state.formErrorMessage} show={this.state.formError} />
            </Col>
          </Row>
          <Row>
            <Col>
              {loadingProfile ? format.renderLoading('Profile') : this.renderNameCard()}
              {loadingPlatforms ? format.renderLoading('Social Info') : this.renderSocialCard()}
              {loadingProfile ? format.renderLoading('Tags') : this.renderTagsCard()}
            </Col>
            <Col>
              {loadingProfile ? format.renderLoading('Emails') : this.renderEmailsCard()}
              {loadingProfile ? format.renderLoading('Status') : this.renderStatusCard()}
              {loadingProfile ? format.renderLoading('Contact Info') : this.renderContactCard()}
              {loadingProfile ? format.renderLoading('Address') : this.renderAddressCard()}
              {loadingProfile ? format.renderLoading('Bio') : this.renderBioCard()}
              {loadingProfile ? format.renderLoading('Notes') : this.renderNotesCard()}
            </Col>
          </Row>
          <Row>
            <Col className="mt-4 mb-4">
                <Button variant="secondary" type="submit" onClick={this.handleSubmit}>
                  Submit
                </Button>
                <Link to={id ? `/influencers/details/${id}` : `/influencers/`}>
                  <Button variant="outline-secondary" className="ml-2">Cancel</Button>
                </Link>
            </Col>
          </Row>
        </Form>
      </Container>
    )
  }
}

export default makeComponentTrashable(EditInfluencer)
