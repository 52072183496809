import React, { Component } from 'react'
import {
    Modal,
    Button,
    Form
} from 'react-bootstrap'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from '../../graphql/mutations'
import Logger from '../../utils/Logger'
import makeComponentTrashable from 'trashable-react';
const logger = new Logger("CampaignInfluencerModal.js");

class CampaignInfluencerModal extends Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      influencerCampaign: (this.props.influencercampaign) ? this.props.influencercampaign : null,
      feePaid: (this.props.influencercampaign) ? this.props.influencercampaign.feePaid : null
    }
  }

  componentDidMount = () => {
    this._isMounted = true
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  handleFeePaidChange = event => {
    this.setState({
      feePaid: event.target.value
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    const {
      feePaid,
      influencerCampaign
    } = this.state

    influencerCampaign.feePaid = feePaid
    API.graphql(graphqlOperation(mutations.updateInfluencerCampaign, { updateInfluencerCampaignInput: influencerCampaign }))
      .then(logger.handleTrace('Influencer/campaign updated.'))
      .catch(logger.handleError)

    this.props.onHide()

  }

  render() {
    const {
      feePaid
    } = this.state

    return (
      <Modal onHide={this.props.onHide} show={this.props.show}>
        <Modal.Header>
          Modify Influencer/Campaign Information
        </Modal.Header>
        <Modal.Body>
          <Form id="EditInfluencerCampaign">
            <Form.Label>Fee Paid</Form.Label>
            <Form.Control
              name="feePaid"
              onChange={this.handleFeePaidChange}
              value={feePaid}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" onClick={this.handleSubmit}>Submit</Button>
          <Button variant="danger" onClick={this.props.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default makeComponentTrashable(CampaignInfluencerModal)
