import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Table
} from 'react-bootstrap'
import { StatIG, StatIGB, StatTW, StatYT, StatFB  } from '../common/SocialStats'
import withPermissions from '../../security/withPermissions'
import { Redirect } from 'react-router-dom'

import InfluencerMerge from '../influencer/InfluencerMerge'

import * as mutations from '../../graphql/mutations'
import * as queries from '../../graphql/queries'
import uuid from 'uuid/v1'

import { CSVLink } from 'react-csv'
import CampaignInfluencerModal from '../../components/common/CampaignInfluencerModal'
import {undoEscapesJson} from '../../utils/format'
import makeComponentTrashable from 'trashable-react';
import Bottleneck from 'bottleneck'
import Logger from '../../utils/Logger'
const logger = new Logger("CampaignDetails.js");

const LabeledField = ({ label, data }) =>
  <p><strong>{label}:</strong> {data}</p>

class CampaignDetails extends Component {
  gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

  constructor(props) {
    super(props)
    this.state = {
      loadingCampaign: true,
      loadingInfluencers: true,
      facebookPosts: [],
      twitterPosts: [],
      youtubePosts: [],
      instagramPosts: [],
      instagramBusinessPosts: [],
      blogPosts: [],
      influencerCampaign: [],
      campaignReport: [],
      showEditCampaignInfluencerModal: null,
      refresh: false
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    if (id) {
      this.graphqlOp(graphqlOperation(queries.getCampaign, { campaignId: id }))
        .then(response => this.setState({ loadingCampaign: false, campaign: response.data.getCampaign }))
        .catch(logger.handleError)

      this.processPosts(id, 'listPostFacebooksByInfluencerCampaignId', 'facebookPosts')
      this.processPosts(id, 'listPostTwittersByInfluencerCampaignId', 'twitterPosts')
      this.processPosts(id, 'listPostYoutubesByInfluencerCampaignId', 'youtubePosts')
      this.processPosts(id, 'listPostInstagramsByInfluencerCampaignId', 'instagramPosts')
      this.processPosts(id, 'listPostInstagramBusinesssByInfluencerCampaignId', 'instagramBusinessPosts')
      this.processPosts(id, 'listPostBlogsByInfluencerCampaignId', 'blogPosts')

      this.graphqlOp(graphqlOperation(queries.listInfluencersByCampaignId, { campaignId: id }))
        .then(response => {
          this.setState({ loadingInfluencers: false, influencers: undoEscapesJson(response.data.listInfluencersByCampaignId) })
        })
        .catch(logger.handleError);

      this.graphqlOp(graphqlOperation(queries.listInfluencerCampaigns))
        .then(response => {
          this.setState({ influencerCampaign: response.data.listInfluencerCampaigns })
        })
        .catch(logger.handleError);

      ['Fb', 'Ig', 'Igb', 'Yt', 'Tw'].forEach(marker => this.getCampaignStat(id, marker))
    }
  }

  getCampaignStat = (campaignId, postMarker) => {
    this.graphqlOp(graphqlOperation(queries['getCampaignStat' + postMarker], { campaignId }))
    .then(response => {
      this.setState({ ['loading' + postMarker + 'Stats']: false, ['campaignStats' + postMarker]: undoEscapesJson(response.data['getCampaignStat' + postMarker.toUpperCase()]) })
    })
    .catch(logger.handleError)
  }

  processPosts = (campaignId, queryName, stateName) => {
    this.graphqlOp(graphqlOperation(queries[queryName], { campaignId: campaignId }))
      .then(socialResponse => {
        if (socialResponse.data[queryName].length > 0) {

          let socialPosts = this.state[stateName]

          socialResponse.data[queryName].forEach(post => {
            if (!socialPosts[post.influencerId]) {
              socialPosts[post.influencerId] = []
            }
            socialPosts[post.influencerId].push({ 'link': post.link })
          })

          this.setState({
            stateName: socialPosts
          })
        }
      })
      .catch(logger.handleError)
  }

  renderLoading = message => {
    return (
      <Row>
        <Col>
          <Alert variant="info" className="mt-4">Loading {message}...</Alert>
        </Col>
      </Row>
    )
  }

  renderEditButton = item =>
    <Link to={`/campaigns/edit/${item}`} className="btn btn-secondary">Edit</Link>

  renderReportButton = (campaign, influencers) => {
    let reportHeaders = [
      "influencer_name",
      "campaign_name",
      "job_number",
      "fee_paid",
      "post",
      "post_source"
    ]

    if (!campaign || !influencers) return

    let campaignReport = this.generateReport(campaign, influencers)

    return (
      <CSVLink
        filename={"campaign_posts.csv"}
        headers={reportHeaders}
        data={campaignReport}
        className="btn btn-primary"
        target="_blank">Campaign Post Report
      </CSVLink>
    )

  }

  generateReport = (campaign, influencers) => {
    let campaignReport = []

    const {
      facebookPosts,
      twitterPosts,
      youtubePosts,
      instagramPosts,
      instagramBusinessPosts,
      blogPosts
    } = this.state;

    let influencerRecordBase = {}
    influencerRecordBase.campaign_name = campaign.campaignName
    influencerRecordBase.job_number = campaign.jobNumber
    influencerRecordBase.post = null
    influencerRecordBase.post_source = null

    // Go through each influencer and grab the proper data.
    influencers.forEach(influencer => {
      // If an influencer has no social posts in our database, just one record will be placed for them with null values for post link and source.
      let hasSocialPosts = false

      influencerRecordBase.influencer_name = (influencer.firstName || '') + ' ' + (influencer.lastName || '')
      influencerRecordBase.fee_paid = this.getFeePaid(influencer.influencerId, campaign.campaignId)

      if (facebookPosts[influencer.influencerId]) {
        facebookPosts[influencer.influencerId].forEach((post, indexId) => {
          let copyRecord = Object.assign({}, influencerRecordBase)
          hasSocialPosts = true
          copyRecord.post = post.link
          copyRecord.post_source = "facebook"
          campaignReport.push(copyRecord)
        })
      }

      if (twitterPosts[influencer.influencerId]) {
        twitterPosts[influencer.influencerId].forEach((post, indexId) => {
          let copyRecord = Object.assign({}, influencerRecordBase)
          hasSocialPosts = true
          copyRecord.post = post.link
          copyRecord.post_source = "twitter"
          campaignReport.push(copyRecord)
        })
      }

      if (youtubePosts[influencer.influencerId]) {
        youtubePosts[influencer.influencerId].forEach((post, indexId) => {
          let copyRecord = Object.assign({}, influencerRecordBase)
          hasSocialPosts = true
          copyRecord.post = post.link
          copyRecord.post_source = "youtube"
          campaignReport.push(copyRecord)
        })
      }

      if (instagramPosts[influencer.influencerId]) {
        instagramPosts[influencer.influencerId].forEach((post, indexId) => {
          let copyRecord = Object.assign({}, influencerRecordBase)
          hasSocialPosts = true
          copyRecord.post = post.link
          copyRecord.post_source = "instagram"
          campaignReport.push(copyRecord)
        })
      }

      if (instagramBusinessPosts[influencer.influencerId]) {
        instagramBusinessPosts[influencer.influencerId].forEach((post, indexId) => {
          let copyRecord = Object.assign({}, influencerRecordBase)
          hasSocialPosts = true
          copyRecord.post = post.link
          copyRecord.post_source = "instagram_business"
          campaignReport.push(copyRecord)
        })
      }

      if (blogPosts[influencer.influencerId]) {
        blogPosts[influencer.influencerId].forEach((post, indexId) => {
          let copyRecord = Object.assign({}, influencerRecordBase)
          hasSocialPosts = true
          copyRecord.post = post.link
          copyRecord.post_source = "blog"
          campaignReport.push(copyRecord)
        })
      }

      // Influencer has no social posts, so add one record for them with null post and post source information
      if (!hasSocialPosts) {
        campaignReport.push(Object.assign({}, influencerRecordBase))
      }
    })

    return campaignReport
  }

  renderCampaign = data => {
    return (
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <LabeledField label="Campaign Name" data={data.campaignName}></LabeledField>
              <LabeledField label="Client" data={data.client}></LabeledField>
              <LabeledField label="Job Number" data={data.jobNumber}></LabeledField>
              <LabeledField label="Description" data={data.campaignDescription}></LabeledField>
              <LabeledField label="Assignment" data={data.client}></LabeledField>
              <LabeledField label="Deliverables" data={data.deliverables}></LabeledField>
              <LabeledField label="From Dovetale?" data={data.dovetaleId ? "Yes" : "No"}></LabeledField>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  getFeePaid = (influencerId, campaignId) => {
    let influencerCampaign = this.getInfluencerCampaign(influencerId, campaignId)
    return (influencerCampaign) ? influencerCampaign.feePaid : 0
  }

  getInfluencerCampaign = (influencerId, campaignId) => {
    let index = this.state.influencerCampaign.findIndex(info => info.influencerId === influencerId && info.campaignId === campaignId)
    return (index >= 0) ? this.state.influencerCampaign[index] : null
  }

  showInfluencerCampaignModal = id => {
    this.setState({ showEditCampaignInfluencerModal: id })
  }

  removeFromCampaign = influencer => {
    this.graphqlOp(graphqlOperation(mutations.deleteInfluencerCampaign, { influencerId: influencer.influencerId, campaignId: this.state.campaign.campaignId }))
    .then(response => {
      this.graphqlOp(graphqlOperation(queries.listInfluencersByCampaignId, { campaignId: this.state.campaign.campaignId }))
      .then(response => this.setState({ loadingInfluencers: false, influencers: undoEscapesJson(response.data.listInfluencersByCampaignId)}))
      .catch(logger.handleError)
    })
  }

  renderInfluencers = data => {
    const { id } = this.props.match.params
    const { campaign } = this.state

    let campaignInfluencerModalClose = () => {
      // reload influencer list ??
      this.setState({ showEditCampaignInfluencerModal: false})
    }

    if (!data) {
      this.setState({ loadingInfluencers: false })
      return
    }
    return (
      <Row>
        <Col>
          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Influencers</Card.Title>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th></th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Primary Email</th>
                    <th>Posts</th>
                    <th>Fee Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {(data && data.length) ? data.map(item => (
                    <tr key={uuid()}>
                      <td>
                      {this.props.permissions.can("editCampaigns") && <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={
                            () => {window.confirm('Are you sure you wish to delete this influencer from campaign? Note: This will not remove any posts.') && this.removeFromCampaign(item) }
                          }>
                            Remove
                          </Button>}
                        <Link to={"/influencers/details/" + item.influencerId}>
                          <Button size="sm" variant="outline-secondary">Details</Button>
                        </Link>
                        <Button
                            size="sm"
                            variant="outline-secondary"
                            onClick={() => this.showInfluencerCampaignModal(item.influencerId)}
                          >Edit Fee Paid</Button>
                        <CampaignInfluencerModal
                          show={this.state.showEditCampaignInfluencerModal === item.influencerId}
                          onHide={campaignInfluencerModalClose}
                          influencercampaign={campaign && this.getInfluencerCampaign(item.influencerId, campaign.campaignId)}
                        />
                      </td>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.emailAddress1}</td>
                      <td><Link to={`/campaigns/details/${id}/posts/${item.influencerId}`}>Posts</Link></td>
                      <td>{campaign && this.getFeePaid(item.influencerId, campaign.campaignId)}</td>
                    </tr>
                  )) : <tr>
                      <td colSpan="6">No influencers found for this campaign.</td>
                    </tr>}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  processStatObject = (data, code) => {
    switch (code) {
      case "ig":
        return <StatIG data={data} />;
      case "igb":
          return <StatIGB data={data} />;
      case "fb":
          return <StatFB data={data} />;
      case "tw":
          return <StatTW data={data} />;
      case "yt":
          return <StatYT data={data} />;
      default:
        logger.handleError("No stat object for type " + code)
        return <p>Error</p>
    }
  }

  renderPlatformStats = (platform, data, code) => {
    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>{platform}</Card.Title>
          {data ? this.processStatObject(data, code) : "No Data"}
        </Card.Body>
      </Card>
    )
  }

  renderMergeDuplicatesButton = () => (!this.state.loadingCampaign && !this.state.loadingInfluencers) ? <InfluencerMerge influencers={this.state.influencers}  onMergeComplete={() => this.setState({refresh: true})} /> : ''

  renderPullFromDovetaleButton = (id) => {
    if (this.state.loadingCampaign || this.state.loadingInfluencers) return '';

    const dovetaleId = this.state.campaign.dovetaleId;

    if (!dovetaleId || !id || !this.props.permissions.can("syncCampaigns")) return '';
    
    return <Link to={`/campaigns/dovetale/${dovetaleId}/import`}><Button variant="secondary">Pull From Dovetale</Button></Link>
  }

  render() {

    const {
      loadingCampaign,
      loadingInfluencers,
      loadingFbStats,
      loadingIgStats,
      loadingIgbStats,
      loadingTwStats,
      loadingYtStats,
      campaign,
      influencers,
      campaignStatsFb,
      campaignStatsIg,
      campaignStatsIgb,
      campaignStatsTw,
      campaignStatsYt,
      refresh
    } = this.state;

    return (
      <Container fluid>
        {refresh && <Redirect to={"/campaigns/details/r/" + campaign.campaignId}/>}

        <Row>
          <Col>
            <h1>Campaign Details</h1>
            <h1>{this.renderEditButton(this.props.match.params.id)} {this.renderReportButton(campaign, influencers)} {this.renderMergeDuplicatesButton()} {this.renderPullFromDovetaleButton(this.props.match.params.id)}</h1>
          </Col>
        </Row>

        {loadingCampaign ? this.renderLoading('Campaign') : this.renderCampaign(campaign)}
        {loadingInfluencers ? this.renderLoading('Influencers') : this.renderInfluencers(influencers)}

        <Row>
          <Col>
            <Card className="mt-4 mb-4">
              <Card.Body>
                <Card.Title>Stats</Card.Title>
                <Row>
                  <Col>
                    {loadingFbStats ? this.renderLoading('Facebook Stats') : this.renderPlatformStats('Facebook', campaignStatsFb, 'fb')}
                    {loadingIgStats ? this.renderLoading('Instagram Stats') : this.renderPlatformStats('Instagram', campaignStatsIg, 'ig')}
                    {loadingIgbStats ? this.renderLoading('Instagram Business Stats') : this.renderPlatformStats('Instagram Business', campaignStatsIgb, 'igb')}
                  </Col>
                  <Col>
                    {loadingTwStats ? this.renderLoading('Twitter Stats') : this.renderPlatformStats('Twitter', campaignStatsTw, 'tw')}
                    {loadingYtStats ? this.renderLoading('YouTube Stats') : this.renderPlatformStats('YouTube', campaignStatsYt, 'yt')}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>


      </Container>
    );
  }
}

export default makeComponentTrashable(withPermissions(CampaignDetails))