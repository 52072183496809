import React from 'react';
import { Alert } from 'react-bootstrap'
import _ from 'lodash'

/* We use |u| and |\u| to demark the unicode literal in the DB for non-Latin1 chars. We decode this on the frontend.
*  This is because the DB and AppSync only are allowing Latin1 chars through.
*/
const escapeUnicode = (unicodeChar) => {
    let hex = unicodeChar.codePointAt(0).toString(16);
    return "|u|" + "0000".substring(0, 4 - hex.length) + hex + "|/u|";
}

const escapeString = (str) => {
    //Escape non Latin1 chars
    const regex = /[^\000-\377]/g;
    let match = regex.exec(str);
    let result = str;
    while (match) {
        const charMatch = match[0];
        result = result.replace(charMatch, [...charMatch].map(escapeUnicode).join(''));
        match = regex.exec(str)
    }

    //Escape literal { }
    result = result.replace(/[{}]/g, match => escapeUnicode(match));

    return result;
}

/**
 * Unescape a single string;
 * @param {string} str 
 */
export const undoEscapes = (str) => str ? str.replace(/\|u\|(.*?)\|\/u\|/g, (match, p1) => String.fromCodePoint(parseInt(p1,16))) : str;

/**
 * Cycles through all string values and unescapes them. Will loop through Arrays to find more objects and Objects for values.
 * @param {object} obj 
 */
export const undoEscapesJson = (obj) => {
    if (obj instanceof Array) {
        return obj.map(undoEscapesJson);
    }

    if (obj instanceof Object) {
        Object.keys(obj).forEach(key => {
            obj[key] = undoEscapesJson(obj[key]);
        });
    }

    if (typeof obj === 'string') {
        obj = undoEscapes(obj);
    }

    return obj;
}

const escapeRecursively = (obj) => {
    if (obj instanceof Array) {
        return obj.map(escapeRecursively);
    }

    if (obj instanceof Object) {
        Object.keys(obj).forEach(key => {
            obj[key] = escapeRecursively(obj[key]);
        });
    }

    if (typeof obj === 'string') {
        obj = escapeString(obj);
    }

    return obj;
}

const replaceEscapedSingleChar = (match) => {
    if ((match.match(/\\/g) || []).length % 2 === 0) return match
    return "\\" + match
}

/**
 * Cycles through all top level string values of an object and escapes them for AppSync processing.
 * @param {object} json 
 */
export const escapeJson = (json) => { 
    let firstPass = JSON.parse(
        JSON.stringify(json)
            .replace(/\\\\/g, "\\\\\\\\\\\\\\\\")
            .replace(/\\+[a-zA-Z]/g, replaceEscapedSingleChar)
            .replace(/'/g, "''")
            .replace(/\\"/g, "\\\\\\\"")
    );

    return escapeRecursively(firstPass);
}

export const removeEmptyObjVals = (obj) => {
    Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key]);
}

export const getDateFromTimestamp = (timestamp) => timestamp && new Date(timestamp * 1000);

export const renderTimeStamp = (item) => {
    if (!item) return item;
    let d = new Date(item * 1000)
    return d.toLocaleString(undefined, {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'})
}

export const renderDateStamp = (item) => {
    if (!item) return
    let d = new Date(item * 1000)
    return d.toLocaleDateString()
}

export const numToYesNo = (num) => num === 1 ? 'Yes' : 'No';

export const truthyToYesNo = (value) => value ? 'Yes' : 'No';

export const getCurrentTimestamp = () => Math.floor((new Date()).getTime()/1000);

export const getTimestampFromMilli = (time) => Math.floor(time/1000);

export const convertISOToLocalDate = (iso) => (new Date(iso)).toLocaleDateString();

export const makeCancelable = (promise) => {
    let hasCanceled_ = false;
  
    const wrappedPromise = new Promise((resolve, reject) => {
      promise.then(
        val => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
        error => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
      );
    });
  
    return {
      promise: wrappedPromise,
      cancel() {
        hasCanceled_ = true;
      },
    };
  };

  function formLink(item) {
      return !item.startsWith("http://") && !item.startsWith("https://") ? "https://" + item : item;
  }

  export const renderExternalUrl = item => item ? <a target="_blank" rel="noopener noreferrer" href={formLink(item)}>{item}</a> : item;

  export const renderLoading = message => (<Alert variant="info" className="mt-4">Loading {message}...</Alert>);

  export const getTimestampFromDateString = (string) => {
        if (_.endsWith(string, '.000Z')) {
            let d = new Date(string.substring(0, string.indexOf('.000Z')))
            return getTimestampFromMilli(d.getTime())
        } else if (_.endsWith(string, ' UTC')) {
            let d = new Date(string.substring(0, string.indexOf(' UTC')))
            return getTimestampFromMilli(d.getTime())
        } else if (_.endsWith(string, ' +0000')) {
            let d = new Date(string.substring(0, string.indexOf(' +0000')))
            return getTimestampFromMilli(d.getTime())
        } else {
            let d = new Date(string)
            if (_.isDate(d)) {
                return getTimestampFromMilli(d.getTime())
            } else {
                return 0
            }
        }
    }

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

/**
 * Will return an object with either firstName if the name does not contain a space,
 * or with firstName and lastName if it does and split accordingly.
 * Most useful with the ... operator.
 * @param {string} name 
 */
export const splitNameOrAssignFirst = (name) => {
    if (!name.includes(' ')) return {firstName: name}
    return {
        firstName: name.substr(0,name.indexOf(' ')),
        lastName: name.substr(name.indexOf(' ')+1)
    }
}