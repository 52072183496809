import React from 'react'
import _ from 'lodash'

import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'

import { capitalize, undoEscapesJson, escapeJson } from '../../utils/format'

import withPermissions from '../../security/withPermissions'
import { Form, Modal, Button } from 'react-bootstrap'

import Bottleneck from 'bottleneck'

import PropTypes from 'prop-types';
import makeComponentTrashable from 'trashable-react';

import Logger from '../../utils/Logger'
const logger = new Logger("InfluencerMerge.js");

const fieldMergeRules = {
    influencerId: (val1) => val1,
    unsubscribed: (val1, val2) => val1 || val2,
    mergeStringRule: (val1, val2) => [(val1 || ''), (val2 || '')].filter(x => x !== '').join(' '),
    defaultRule: (val1, val2) => val1 || val2
}

const mergeType = {
    MERGE: "MERGE",
    TRANSFER: "TRANSFER"
}

const gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10});

class InfluencerMerge extends React.Component {
    gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

    static propTypes = {
        /** The list of influencers to choose from */
        influencers: PropTypes.array.isRequired,
        /** An optional function for callback after done merging */
        onMergeComplete: PropTypes.func
    }

    cleanState = {
        inf1Id: 0,
        inf2Id: 0,
        show: false,
        loading: false
    }

    constructor(props) {
        super(props);
        this.state = {...this.cleanState}
    }

    submit = () => {
        this.setState({loading: true})
        this.merge().then(() => {
            this.setState(this.cleanState)
            if (this.props.onMergeComplete) this.props.onMergeComplete()
        })
    }

    displayNameFromInf = (inf) => (inf.firstName || '') + " " + (inf.lastName || '')

    fieldSelect(obj, fieldSubSelect) {
        if (!obj || !fieldSubSelect) return obj;

        if (_.isArray(obj)) return obj.map(entry => this.fieldSelect(entry, fieldSubSelect))

        let newObj = {};
        Object.keys(obj)
            .filter(key => fieldSubSelect.includes(key))
            .forEach(key => newObj[key] = obj[key])

        return newObj;
    }

    generateQueryPromiseObj(queryName, id, type, graqhqlBaseName, dataTypeOverride, fieldSubSelect) {
        return {
            promise: gqlLimiter.schedule(() => this.graphqlOp(graphqlOperation(queries[queryName], { influencerId: id })))
                .then(response => this.fieldSelect(undoEscapesJson(response.data[dataTypeOverride || queryName]), fieldSubSelect))
                .catch(logger.handleError),
            type,
            graqhqlBaseName
        }
    }
    
    merge = async () => {
        let {inf1Id, inf2Id} = this.state
        
        inf1Id = parseInt(inf1Id)
        inf2Id = parseInt(inf2Id)

        logger.handleTrace(`Starting merge of influencer ID ${inf2Id} into ${inf1Id}`)

        let inf1Promises = {};
        let inf2Promises = {};
        const {MERGE, TRANSFER} = mergeType;

        // Get all promises for both influencers
        [{id: inf1Id, promises: inf1Promises}, {id: inf2Id, promises: inf2Promises}].forEach(entry => {
            let {id, promises} = entry
            
            promises.influencer = this.generateQueryPromiseObj("getInfluencer", id, MERGE)

            promises.influencerList = this.generateQueryPromiseObj("listInfluencerListsByInfluencerId", id, TRANSFER, null, null, ["influencerId", "listId"])
            promises.influencerCampaign = this.generateQueryPromiseObj("listCampaignsByInfluencerId", id, TRANSFER, null, null, ["influencerId", "campaignId"])

            promises.communication = this.generateQueryPromiseObj("listCommunicationsByInfluencerId", id, TRANSFER)

            promises.attachment = this.generateQueryPromiseObj("listAttachmentsByInfluencerId", id, TRANSFER)
            promises.influencerProfilePic = this.generateQueryPromiseObj("listInfluencerProfilePicsByInfluencerId", id, TRANSFER)

            promises.postBlog = this.generateQueryPromiseObj("listPostBlogsByInfluencerCampaignId", id, TRANSFER)
            promises.postFacebook = this.generateQueryPromiseObj("listPostFacebooksByInfluencerCampaignId", id, TRANSFER)
            promises.postInstagramBusiness = this.generateQueryPromiseObj("listPostInstagramBusinesssByInfluencerCampaignId", id, TRANSFER)
            promises.postInstagram = this.generateQueryPromiseObj("listPostInstagramsByInfluencerCampaignId", id, TRANSFER)
            promises.postTwitter = this.generateQueryPromiseObj("listPostTwittersByInfluencerCampaignId", id, TRANSFER)
            promises.postYoutube = this.generateQueryPromiseObj("listPostYoutubesByInfluencerCampaignId", id, TRANSFER)

            promises.influencerStatFb = this.generateQueryPromiseObj("getInfluencerStatFb", id, MERGE, "influencerStatFB", "getInfluencerStatFB")
            promises.influencerStatIg = this.generateQueryPromiseObj("getInfluencerStatIg", id, MERGE, "influencerStatIG", "getInfluencerStatIG")
            promises.influencerStatTw = this.generateQueryPromiseObj("getInfluencerStatTw", id, MERGE, "influencerStatTW", "getInfluencerStatTW")
            promises.influencerStatYt = this.generateQueryPromiseObj("getInfluencerStatYt", id, MERGE, "influencerStatYT", "getInfluencerStatYT")

            promises.influencerTagValue = this.generateQueryPromiseObj("listInfluencerTagValuesByInfluencerId", id, TRANSFER)
        })

        if (!await inf1Promises.influencer.promise || !await inf2Promises.influencer.promise) return;

        let pendingActionsMerge = Object.keys(inf1Promises)
            .filter(key => inf1Promises[key].type === MERGE)
            .map(key => this.mergeObjs(inf1Promises[key], inf2Promises[key], key, inf1Id))

        let pendingActionsTransfer = Object.keys(inf2Promises)
            .filter(key => inf2Promises[key].type === TRANSFER)
            .map(key => this.transferObj(inf2Promises[key], key, inf1Id))

        await Promise.all(pendingActionsMerge.concat(pendingActionsTransfer).map(p => p.catch(Logger.handleError)))

        //Delete the second influencer
        await this.graphqlOp(graphqlOperation(mutations.deleteInfluencer, { influencerId: inf2Id })).catch(Logger.handleError)

        logger.handleTrace(`Finished merge of influencer ID ${inf2Id} into ${inf1Id}`)
    }

    async mergeObjs(obj1PromiseObj, obj2PromiseObj, objName, influencerId) {
        let [obj1, obj2] = await Promise.all([obj1PromiseObj.promise, obj2PromiseObj.promise])
        let updateObj = {}

        // If no object for either, no action to perform
        if (!obj1 && !obj2) return Promise.resolve()

        let creationFlag = false

        if (!obj1) {
            obj1 = {influencerId};
            creationFlag = true;
        }
        if (!obj2) obj2 = {}

        Object.keys(creationFlag ? obj2 : obj1).forEach(key => {
            const val1 = obj1[key]
            const val2 = obj2[key]
            let fieldRule = fieldMergeRules[key]
            if (!fieldRule) {
                if (_.isNil(val1) && _.isNil(val2)) return;

                if (_.isString(val1) || _.isString(val2)) {
                    fieldRule = fieldMergeRules.defaultRule
                } else if (_.isNumber(val1) || _.isNumber(val2)) {
                    fieldRule = fieldMergeRules.defaultRule
                } else {
                    logger.handleError("Unknown field in influencer field merge: " + key);
                    return;
                }

            }
            updateObj[key] = fieldRule(val1, val2)
        })

        delete updateObj["creationTime"]
        delete updateObj["creationSource"]
        delete updateObj["resultsCount"]
        const commandName = (creationFlag ? "create" : "update") 
        const command = commandName + capitalize(objName)

        const commandInput = {[commandName + capitalize(obj1PromiseObj.graqhqlBaseName || objName) + "Input"]: escapeJson(_.omitBy(updateObj, _.isNil))}

        return this.graphqlOp(graphqlOperation(mutations[command], commandInput))
    }

    async transferObj(promiseObj, objName, influencerId) {
        let obj = await promiseObj.promise;
        if (!obj) return Promise.resolve()

        if (!_.isArray(obj)) obj = [obj];

        let pendingActions = [];

        obj.forEach(item => {
            item.influencerId = influencerId
            const itemIdLabel = objName + "Id"
            const oldItemId = item[itemIdLabel] //In case we need to delete this one manually
            delete item[itemIdLabel] //If item has its own id, remove it

            const createCommand = "create" + capitalize(objName)
            const deleteCommand = "delete" + capitalize(objName)
            const createInput = {["create" + capitalize(promiseObj.graqhqlBaseName || objName) + "Input"]: escapeJson(_.omitBy(item, _.isNil))}

            pendingActions.push(this.graphqlOp(graphqlOperation(mutations[createCommand], createInput)))
            if (oldItemId) pendingActions.push(this.graphqlOp(graphqlOperation(mutations[deleteCommand], {[itemIdLabel]: oldItemId})))
        })

        return Promise.all(pendingActions.map(p => p.catch(Logger.handleError)))
    }

    onChange = event => this.setState({ [event.target.name]: event.target.value })

    influencerOptions = () => {
        const {inf1Id} = this.state
        const {influencers} = this.props
        let results = influencers
            .filter(x => x.influencerId !== parseInt(inf1Id))
            .map(inf => (<option key={inf.influencerId} value={inf.influencerId}>{this.displayNameFromInf(inf)}</option>));

        results.unshift(<option key={0} value={0}>-- Select --</option>)

        return results;
    }

    influencerSelect = (storedVal) => (
        <Form.Group controlId={storedVal}>
            <Form.Control
                as="select"
                name={storedVal}
                value={this.state[storedVal]}
                onChange={this.onChange}
            >
                {this.influencerOptions()}
            </Form.Control>
        </Form.Group>
    )

    render() {
        const {inf1Id, inf2Id, show, loading} = this.state
        const {influencers} = this.props

        return this.props.permissions.can("deleteInfluencers") ?
            <React.Fragment>
                <Button onClick={() => this.setState({show: true})}>Merge Duplicate Influencers</Button>
            
                <Modal show={show} onHide={() => loading || this.setState(this.cleanState)}>
                    <Modal.Header >
                        <Modal.Title>Merge Influencers</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Select the two influencers to merge. The first influencer selected will take preference on field population. Make sure to check the merged influencer after the fact to verify the data merged correctly and modify if needed.</p>
                        <p>Select First Influencer to Merge <b>(Will Remain)</b>:</p>
                        <b>{inf1Id ? this.displayNameFromInf(influencers.find(x => x.influencerId === parseInt(inf1Id))) : this.influencerSelect("inf1Id")}</b>
                        <br /><br />
                        {inf1Id ?
                            <React.Fragment>
                                <p>Select Second Influencer to Merge <b>(Will Be Deleted)</b>:</p>
                                <b>{inf2Id ? this.displayNameFromInf(influencers.find(x => x.influencerId === parseInt(inf2Id))) : this.influencerSelect("inf2Id")}</b>
                            </React.Fragment> : ''}
                        <br /><br />
                        {loading ? <p><b>Processing... Please Wait...</b></p> : ''}
                    </Modal.Body>
                    {!loading ? <Modal.Footer>
                        <Button variant="primary" onClick={this.submit}>Merge</Button>
                        <Button variant="danger" onClick={() => this.setState(this.cleanState)}>Cancel</Button>
                    </Modal.Footer> : ''}
                </Modal>
            </React.Fragment>
        : ''
    }
}

export default makeComponentTrashable(withPermissions(InfluencerMerge))