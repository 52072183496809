import { Auth } from 'aws-amplify';
import Roles, { RolesEnum } from './Roles'
import React from 'react'

const accessMode = {
    view: "view",
    edit: "edit",
    create: "create",
    delete: "delete",
    download: "download",
    search: "search",
    sync: "sync"
};

const securable = {
    Users: {name: "Users", 
        availableModes: [accessMode.view, accessMode.edit, accessMode.create                                                                            ]},
    Lists: {name: "Lists", 
        availableModes: [accessMode.view, accessMode.edit, accessMode.create, accessMode.delete,                                         accessMode.sync]},
    Campaigns: {name: "Campaigns", 
        availableModes: [accessMode.view, accessMode.edit,                                                                               accessMode.sync]},
    Influencers: {name: "Influencers", 
        availableModes: [accessMode.view, accessMode.edit, accessMode.create, accessMode.delete, accessMode.download, accessMode.search                 ]},
    Tags: {name: "Tags", 
        availableModes: [accessMode.view, accessMode.edit, accessMode.create                                                                            ]},
    Communications: {name: "Communications", 
        availableModes: [accessMode.view, accessMode.edit, accessMode.create, accessMode.delete                                                         ]},
    Attachments: {name: "Attachments",
        availableModes: [accessMode.view,                  accessMode.create, accessMode.delete, accessMode.download                                    ]}
};

const securables = Object.values(securable);

const availablePermissions = function() {
    return securables.map(item => 
        item.availableModes.map(modeType => {
             return {
                 name: modeType + item.name,
                 mode: modeType,
                 securable: item}
        })
    ).reduce((acc, val) => acc.concat(val), []);
}();

const programManagerPermissions = function() {
    return availablePermissions.filter(perm => 
        [securable.Lists, securable.Campaigns, securable.Influencers, securable.Tags, securable.Communications, securable.Attachments].some(item => item.name === perm.securable.name)
    );
}();

const freelancerPermissions = function() {
    return availablePermissions.filter(perm =>
        ([accessMode.view, accessMode.edit, accessMode.search].some(item => item === perm.mode) &&
        [securable.Lists, securable.Campaigns, securable.Influencers, securable.Tags, securable.Communications, securable.Attachments].some(item => item.name === perm.securable.name))
        ||
        ([accessMode.download, accessMode.create].some(item => item === perm.mode) &&
        [securable.Attachments].some(item => item.name === perm.securable.name))
    );
}();

const checkPermissionByRole = (permission, role) => {
    return role.roleid === RolesEnum.Admin.roleid ||
    (role.roleid === RolesEnum.ProgramManager.roleid && programManagerPermissions.some(perm => perm.name === permission)) ||
    (role.roleid === RolesEnum.Freelancer.roleid && freelancerPermissions.some(perm => perm.name === permission))
}

class Permissions {
    constructor(role) {
        this.role = role;
    }

    can = (permission) => {
        return checkPermissionByRole(permission, this.role);
    }
}

const PermissionsLoader = () =>
    Auth.currentSession().then(sess => { 
        const group = sess.getAccessToken().payload['cognito:groups'][0];
        const role = Roles.getAllRoles().find(role => group.includes(role.group));
        return new Permissions(role);
    })

const withPermissions = (WrappedComponent) => {
    class PermissionsComponent extends React.Component {
        _isMounted = false;

        constructor(props) {
            super(props);
            this.state = {
                loadingPermissions: true
            }
        }

        addPermissions = (perm) => {
            if (this._isMounted) {
                this.setState({ loadingPermissions: false, permissions: perm});
            }
        }

        componentDidMount() {
            this._isMounted = true;
            PermissionsLoader().then(this.addPermissions);
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        render() {
            return this.state.loadingPermissions || (
                <WrappedComponent permissions={this.state.permissions} {...this.props} />
            );
        }
    }

    return PermissionsComponent;
}

export default withPermissions;