import React, { Component } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';

class TableActions extends Component {

  render() {

    return (
      <ButtonGroup>
        {this.props.canView && <Link to={`/${this.props.itemPath}/details/${this.props.itemId}`}><Button size="sm" variant="outline-secondary">Details</Button></Link>}
        {this.props.canEdit && <Link to={`/${this.props.itemPath}/edit/${this.props.itemId}`}><Button size="sm" variant="outline-secondary">Edit</Button></Link>}
        {this.props.canDelete && <Link to={`/${this.props.itemPath}/delete/${this.props.itemId}`}><Button size="sm" variant="outline-danger">Delete</Button></Link>}
      </ButtonGroup>
    )
  }
}

TableActions.propTypes = {
  /** The ID of this item */
  itemId: PropTypes.number.isRequired,
  /** The route path fragment for this entity (e.g., "list" or "user") */
  itemPath: PropTypes.string.isRequired,
  /** Whether the user can view this object */
  canView: PropTypes.bool.isRequired,
  /** Whether the user can edit this object */
  canEdit: PropTypes.bool.isRequired,
  /** Whether the user can delete this object */
  canDelete: PropTypes.bool.isRequired
}

export default TableActions