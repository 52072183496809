import React from 'react';
import uuid from 'uuid/v1';

import CognitoProvider from '../../client/CognitoProviderClient'
import { Table, Button } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom'
import Logger from '../../utils/Logger'
const logger = new Logger("UserList.js");

const lambdaUsername = 'lambda';

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, provider: null, refresh: false }
      }

      componentDidMount() {
        var component = this;

        var provider = CognitoProvider.getIdentityServiceProvider();
        var users = provider.then(client => client.listUsers({UserPoolId: CognitoProvider.userPoolId}).promise());

        provider.then(provider => this.setState({provider}))

        Promise.all([provider, users]).then(([provider, response]) => Promise.all(response.Users.map(item => CognitoProvider.processUser(item, provider))))
            .then(response => component.setState({ loading: false, data: response.filter(item => item.username !== lambdaUsername)}))
            .catch(logger.handleError);
      }

      handleDelete = (username) => {
        const { provider } = this.state
        if (window.confirm("Are you sure you want to delete " + username + "?"))
          CognitoProvider.deleteUser(username, provider).then(this.setState({refresh: true}))
      }

      renderEditButton = item =>
      <Link to={`/users/edit/${item}`}>
        <Button variant="outline-secondary" className="ml-2">Edit</Button>  
      </Link>

      renderDeleteButton = (enabled, username) => {
        return <Button variant="outline-danger" className="ml-2" onClick={() => enabled ? window.alert("Please deactivate this user before attempting to delete them.") : this.handleDelete(username)}>Delete</Button>
      }
        

      render() {
        const { loading, data, refresh } = this.state;

        return <React.Fragment>
          {refresh && <Redirect to={`/users/r`}></Redirect>}
        
          {loading ? "loading..." :
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Active?</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map(item => (
                  <tr key={uuid()}>
                    <td>{item.username}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.rolename}</td>
                    <td>{item.enabled ? 'Yes' : 'No'}</td>
                    <td>{this.renderEditButton(item.username)} {this.renderDeleteButton(item.enabled, item.username)}</td>
                  </tr>
                ))
                }
              </tbody>
            </Table> }
          </React.Fragment>
      }
}

export default UserList