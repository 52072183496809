import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
  ListGroup
} from 'react-bootstrap'

import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import withPermissions from '../../security/withPermissions'
import makeComponentTrashable from 'trashable-react';
import uuid from 'uuid/v1'
import _ from 'lodash'
import ReportModal from '../../components/common/ReportModal'
import { undoEscapesJson } from '../../utils/format'

import { Link } from 'react-router-dom'
import Bottleneck from 'bottleneck'

import Logger from '../../utils/Logger'
const logger = new Logger("ListDetails.js");

const LabeledField = ({ label, data }) =>
  data ? <p><strong>{label}:</strong> {data}</p> : null;

class ListDetails extends Component {

  gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

  constructor(props) {
    super(props)
    this.state = {
      loadingList: true,
      loadingInfluencers: true,
      showReportModal: false,
      influencers: [],
      pushResults: null,
      pushActive: false
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    if (id) {
      this.graphqlOp(graphqlOperation(queries.getList, { listId: id }))
        .then(response => this.setState({ loadingList: false, list: response.data.getList }))
        .catch(logger.handleError)
      this.graphqlOp(graphqlOperation(queries.listInfluencersByListId, { listId: id }))
        .then(response => this.setState({ loadingInfluencers: false, influencers: undoEscapesJson(response.data.listInfluencersByListId) }))
        .catch(logger.handleError)
    }
  }

  renderLoading = message => {
    return (
      <Row>
        <Col>
          <Alert variant="info" className="mt-4">Loading {message}...</Alert>
        </Col>
      </Row>
    )
  }

  renderExportButton = () => {
    return this.props.permissions.can("downloadInfluencers") ? (
      <Button variant="outline-primary" onClick={() => this.setState({showReportModal: true})}>Export</Button>
    ) : '';
  }

  renderEditButton = () =>
   this.props.permissions.can("editLists") ? <Link to={`/lists/edit/${this.props.match.params.id}`} className="btn btn-secondary">Edit</Link> : ''

  handlePushToDovetale = () => {
    const dovetaleId = this.state.list.dovetaleId
    const id = this.props.match.params.id
    this.setState({pushActive:true})
    this.graphqlOp(graphqlOperation(queries.listInfluencers, { filter: { list : { listId : {eq: id }}}}))
      .then(influencers => {
        let doveTalePromises = []
        influencers.data.listInfluencers.forEach( influencer => {
          if (!_.isEmpty(influencer.FBusername)) {
            doveTalePromises.push(API.post("wellnessamplifiedapi", `/dovetale/lists?id=${dovetaleId}&platform=facebook&handle=${influencer.FBusername}`))
          }
          if (!_.isEmpty(influencer.IGhandle)) {
            doveTalePromises.push(API.post("wellnessamplifiedapi", `/dovetale/lists?id=${dovetaleId}&platform=instagram&handle=${influencer.IGhandle}`))
          }
          if (!_.isEmpty(influencer.TWusername)) {
            doveTalePromises.push(API.post("wellnessamplifiedapi", `/dovetale/lists?id=${dovetaleId}&platform=twitter&handle=${influencer.TWusername}`))
          }
          if (!_.isEmpty(influencer.YTchannelUrl)) {
            doveTalePromises.push(API.post("wellnessamplifiedapi", `/dovetale/lists?id=${dovetaleId}&platform=twitter&url=${influencer.YTchannelUrl}`))
          }
        })
        return Promise.all(doveTalePromises)
      })
      .then(response => {
        this.publishPushResults(response)
      })
      .catch(logger.handleError)
  }

  publishPushResults = (results) => {
    const items = results.map(result => {
      let msg = ''
      let variant = 'info'
      if ( 'message' in result) {
        if ('account' in result) {
          msg = `${result.account.platform.toUpperCase()} : ${result.message}`
        }
        else if ('endpoint' in result) {
          msg = `${result.message}. Note: there no guarantee Dovetale will import this account you need to verify manually.`
        }
      }
      else if ('error' in result) {
        msg = `Dovetale reported the following error: ${result.error}`
        variant = 'danger'
      }
      return <ListGroup.Item variant = {variant}>{msg}</ListGroup.Item>
    })
    this.setState({
      pushResults: (<ListGroup>{items}</ListGroup>),
      pushActive:false
    })
  }

  renderPushToDovetaleButton = () => {
    let button = '';
    const id = this.props.match.params.id;
    const dovetaleId = this.state.list.dovetaleId;

    if (dovetaleId && id && this.props.permissions.can("syncLists")) {
      button = <Button
        variant = 'secondary'
        onClick = {() => this.handlePushToDovetale()}
        disabled = {this.state.pushActive} >
          {this.state.pushActive? 'Pushing to Dovetale' : 'Push To Dovetale'}
      </Button>
    }

    return (button)
  }

  renderPullFromDovetaleButton = () => {
    let button = '';
    const id = this.props.match.params.id;
    const dovetaleId = this.state.list.dovetaleId;

    if (dovetaleId && id && this.props.permissions.can("syncLists")) {
      button = <Link to={`/lists/dovetale/${dovetaleId}/import`}><Button variant="secondary">Pull From Dovetale</Button></Link>
    }

    return (button)
  }

  renderList = data => {
    if (!data) return
    return (
      <Row>
        <Col>
          <LabeledField label="Name" data={data.name}></LabeledField>
          <LabeledField label="From Dovetale?" data={data.dovetaleId ? "Yes" : "No"}></LabeledField>
        </Col>
      </Row>
    )
  }

  removeFromList = influencer => {
    this.graphqlOp(graphqlOperation(mutations.deleteInfluencerList, { influencerId: influencer.influencerId, listId: this.state.list.listId }))
    .then(response => {
      this.graphqlOp(graphqlOperation(queries.listInfluencersByListId, { listId: this.state.list.listId }))
      .then(response => this.setState({ loadingInfluencers: false, influencers: undoEscapesJson(response.data.listInfluencersByListId) }))
      .catch(logger.handleError)
    })
  }

  renderInfluencers = data => {
    if (!data) return
        return (
      <Row>
        <Col>
          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Influencers</Card.Title>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th></th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Primary Email</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(item => (
                    <tr key={uuid()}>
                      <td>
                        {this.props.permissions.can("editLists") && <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={
                            () => {window.confirm('Are you sure you wish to delete this influencer from list?') && this.removeFromList(item) }
                          }>
                            Remove
                          </Button>}
                          {this.props.permissions.can("viewInfluencers") && <Link to={"/influencers/details/" + item.influencerId}>
                            <Button size="sm" variant="outline-secondary">Details</Button>
                          </Link>}
                      </td>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.emailAddress1}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        {
          this.state.pushResults ?
          <Col>
          {this.state.pushResults}
          </Col>
          :null

        }
      </Row>
    )
  }

  render() {
    let reportModalClose = () => this.setState({showReportModal: false});

    const {
      loadingList,
      loadingInfluencers,
      list,
      influencers
    } = this.state;

    return (
      <Container fluid>
        <Row>
          <Col>
            <h1>List Details {this.renderEditButton()}&nbsp;
              {/* {loadingList || this.renderPushToDovetaleButton()}&nbsp;
              {loadingList || this.renderPullFromDovetaleButton()}&nbsp; */}
              {loadingList || (influencers.length > 0 && this.renderExportButton())}
            </h1>
            <ReportModal
                      show={this.state.showReportModal}
                      influencers={influencers}
                      onHide={reportModalClose} />
          </Col>
        </Row>

        {loadingList ? this.renderLoading('List') : this.renderList(list)}
        {loadingInfluencers ? this.renderLoading('Influencers') : this.renderInfluencers(influencers)}

      </Container>
    );
  }
}

export default makeComponentTrashable(withPermissions(ListDetails))
