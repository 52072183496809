import React from 'react'
import { Modal, Button} from 'react-bootstrap'
import PropTypes from 'prop-types';

import { StatIG, StatIGB, StatTW, StatFB, StatYT, StatBlog } from '../common/SocialStats'

import Logger from '../../utils/Logger'
const logger = new Logger("PostStatModal.js");

class PostStatModal extends React.Component {

  showSocialStat = (post, platform) => {
    if (!post || !platform) return

    let statCard = null
    switch(platform.toLowerCase()) {
      case 'facebook':
        statCard = <StatFB data={post} />
        break
      case 'twitter':
        statCard = <StatTW data={post} />
        break
      case 'instagram':
        statCard = <StatIG data={post} />
        break
      case 'instagram business':
        statCard = <StatIGB data={post} />
        break
      case 'youtube':
        statCard = <StatYT data={post} />
        break
      case 'blog':
        statCard = <StatBlog data={post} />
        break
      default:
        logger.handleErrpr("platform id not found")
    }

    return statCard
  }

  render() {
    let {post, platform} = this.props;
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
          <Modal.Header >
              <Modal.Title>Post Stats</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {this.showSocialStat(post, platform)}
          </Modal.Body>
          <Modal.Footer>
              <Button variant="danger" onClick={this.props.onHide}>Close</Button>
          </Modal.Footer>
      </Modal>
    );
  }
}

PostStatModal.propTypes = {
    /** The post to show stats for */
    post: PropTypes.object.isRequired,
    /** Should hide the modal */
    onHide: PropTypes.func.isRequired,
    /** Should determine whether modal is opened or not */
    show: PropTypes.bool.isRequired
}

export default PostStatModal;
