import React, { Component } from 'react'
import {
    API,
    graphqlOperation
} from 'aws-amplify'
import {
    Button,
    ListGroup,
    Col,
    Container,
    Form,
    Row,
    ButtonGroup
} from 'react-bootstrap'
import _ from 'lodash'
import makeComponentTrashable from 'trashable-react';
import Bottleneck from 'bottleneck'

import { Link } from 'react-router-dom'
import * as queries from '../../graphql/queries'

import Logger from '../../utils/Logger'
const logger = new Logger("InfluencerDupes.js");

class InfluencerDupes extends Component {
    gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

    constructor(props) {
        super(props)

        this.state = {
            updates: {
                emailAddress: '',
                linkedInURL: '',
                pinterestHandle: '',
                ytUsername: '',
                twUsername: '',
                igHandle: '',
                fbUsername: '',
                ytChannelUrl: ''
            },
            matches: []
        }
    }

    handleDupeCheck = (event) => {
        event.preventDefault()
        let filter = {}       
        _.forEach(this.state.updates, (element, key) => !_.isEmpty(element) ? filter[key] = element : null)
        if (Object.keys(filter).length) {
            return this.graphqlOp(graphqlOperation(queries.listDuplicateInfluencers, {filter}))
                .then((response) => {
                    if (response.data.listDuplicateInfluencers.length) {
                        this.setState({
                            valid : true, 
                            matches: response.data.listDuplicateInfluencers
                        })
                    }
                    else {
                        this.handleCreate()
                    }
                })
                .catch(logger.handleError)   
        }
        return null
    }

    
    handleChange = (event) => {
        const value = event.target.value
        let updates = {}
        updates[event.target.name] = value
        this.setState(state => ({updates: _.merge(state.updates, updates)}))
    }

    renderMatches = () => {
      return (
        <Col>
            <Row>
                <div>
                <div>The following are potential dupilcate Influencers, click each box to open a new tab and verify they are not dupllicates </div>
                <ul>
                    <li>
                    If these are not in fact duplicates then click the 'Continue with Creation' button 
                    </li>
                    <li>
                    Else click the 'Cancel Creation' button and go to the record in order to update it as needed.
                    </li>
                </ul> 
                </div>
            </Row>
            <Row>
                <ListGroup>
                    {
                       this.state.matches.map((match, index) => {
                            return (
                                <ListGroup.Item 
                                    key={"dupilcate-" + index} 
                                    action 
                                    href={"/influencers/details/" + match.influencerId} 
                                    target="_blank"
                                    variant="primary">
                                    {_.isNil(match.lastName) ? match.firstName : match.firstName + ' ' + match.lastName}
                                </ListGroup.Item>
                            )
                         }) 
                    }
                </ListGroup>
            </Row>
        </Col> 
      )
    }

    handleCreate = () => {
        this.props.history.push({
            pathname: `/influencers/create`,
            state: {
                emailAddress: this.state.updates.emailAddress,
                linkedInURL: this.state.updates.linkedInURL,
                pinterestHandle: this.state.updates.pinterestHandle,
                ytUsername: this.state.updates.ytUsername,
                twUsername: this.state.updates.twUsername,
                igHandle: this.state.updates.igHandle,
                fbUsername: this.state.updates.fbUsername,
                ytChannelUrl: this.state.updates.ytChannelUrl
            }
        })
    }

    render () {
        const {matches} = this.state
        return (

            <Container>
                { matches.length ?
                    this.renderMatches()
                    :null
                }
                <Form.Group>
                    <Form.Label>Primary Email</Form.Label>
                    <Form.Control
                        name="emailAddress"
                        onBlur = {(event) =>  this.handleChange(event)}
                    />
                    <Form.Label><strong>Instagram</strong>  Handle</Form.Label>
                    <Form.Control
                        maxLength="45"
                        name="igHandle"
                        onBlur = {(event) =>  this.handleChange(event)}
                    /> 
                    <Form.Label><strong>Facebook</strong> Username</Form.Label>
                    <Form.Control
                        maxLength="45"
                        name="fbUsername"
                        onBlur = {(event) =>  this.handleChange(event)}
                    />
                    <Form.Label><strong>TikTok</strong> Handle</Form.Label>
                    <Form.Control
                        maxLength="45"
                        name="linkedInURL"
                        onBlur = {(event) =>  this.handleChange(event)}
                    />
                    <Form.Label><strong>Youtube</strong> Username</Form.Label>
                    <Form.Control
                        maxLength="45"
                        name="ytUsername"
                        onBlur = {(event) =>  this.handleChange(event)}
                    />
                    <Form.Label><strong>Youtube</strong> Channel URL</Form.Label>
                    <Form.Control
                        name="ytChannelUrl"
                        onBlur = {(event) =>  this.handleChange(event)}
                    />
                    <Form.Label><strong>Pinterest</strong> Handle</Form.Label>
                    <Form.Control
                        maxLength="45"
                        name="pinterestHandle"
                        onBlur = {(event) =>  this.handleChange(event)}
                    />
                    <Form.Label><strong>Twitter</strong> Username</Form.Label>
                    <Form.Control
                        maxLength="45"
                        name="twUsername"
                        onBlur = {(event) =>  this.handleChange(event)}
                    /> 
                </Form.Group>
                <ButtonGroup className="mt-4 mb-4 pull-right">
                    <Button variant="primary" type="click" onClick={this.handleDupeCheck}>
                        Check for Duplicates
                    </Button> 
                </ButtonGroup> 
                { matches.length ?
                    <ButtonGroup>
                        <Link to="/influencers/dupe"><Button className="ml-3" variant="secondary" >Cancel Creation</Button></Link>
                        <Button className="ml-3" variant="secondary" type="click" onClick={() => this.handleCreate()} >
                            Continue with Creation
                        </Button> 
                    </ButtonGroup>
                    : null
                }
            </Container>
        )
    }
}

export default makeComponentTrashable(InfluencerDupes)