import React from 'react'
import CognitoProvider from '../../client/CognitoProviderClient'
import Roles from '../../security/Roles'
import {
    Alert,
    Button,
    Col,
    Container,
    Form,
    Row
} from 'react-bootstrap'
import {
  Link
} from 'react-router-dom'
import Logger from '../../utils/Logger'
const logger = new Logger("UserEdit.js");

const Title = ({ mode, username }) =>
    mode === 'create' ? <h2>Create User</h2> : <h2>Edit {username}</h2>

const FormErrors = ({ message, show }) =>
    show ? <Alert variant="danger">{message}</Alert> : null;

const FormSuccess = ({ message, show }) =>
    show ? <Alert variant="success">{message}</Alert> : null;


class UserEdit extends React.Component {

    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this);
        this.handleCheckedChange = this.handleCheckedChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.emptyState = {
            loading: true,
            mode: 'create',
            formSuccess: false,
            formError: false,
            formSuccessMessage: '',
            formErrorMessage: '',
            name: '',
            username: '',
            email: '',
            roleid: Roles.getDefaultRole().roleid,
            enabled: true
        };

        this.state = this.emptyState;
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.setState({ mode: 'edit' });

            let provider = CognitoProvider.getIdentityServiceProvider();
            let user = provider.then(client => client.adminGetUser({UserPoolId: CognitoProvider.userPoolId, Username: id}).promise());

            Promise.all([provider, user]).then(([provider, user]) => CognitoProvider.processUser(user, provider))
            .then(data => {this.setState(data); this.setState({oldData: JSON.stringify(data)})})
            .catch(logger.handleError);        
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleCheckedChange(event) {
        this.setState({ [event.target.name]: event.target.checked });
    }

    handleSubmit(event) {
        event.preventDefault();
        let user = this.getUserFromState();
        var provider = CognitoProvider.getIdentityServiceProvider();
        var rolemapping = provider.then(client => CognitoProvider.getRolePoolMapping(client));
        if (this.state.mode !== 'create') {
            let actions = [];

            let oldData = JSON.parse(this.state.oldData) || this.emptyState;
            
            if (oldData.enabled !== user.enabled)
                actions.push(provider.then(client => CognitoProvider.changeUserEnabled(user.username, user.enabled, client)));

            if (oldData.roleid !== user.roleid) {
                actions.push(
                    Promise.all([provider, rolemapping]).then(([client,rolemap]) => 
                        CognitoProvider.switchUserGroup(user.username, oldData.roleid, user.roleid, rolemap, client))
                    );
            }

            if (oldData.name !== user.name || oldData.email !== user.email)
                actions.push(provider.then(client => CognitoProvider.updateUser(user.username, user.name, user.email, client)));

            Promise.all(actions).then(this.setState({ formSuccess: true, formSuccessMessage: 'User updated!' }))
                .catch(logger.handleError);

        } else {
            
            Promise.all([provider, rolemapping])
                .then(([client, rolemap]) => CognitoProvider.createUser(user.username, user.name, user.email, user.roleid, rolemap, client))
                .then(this.setState(this.emptyState))
                .then(this.setState({ formSuccess: true, formSuccessMessage: 'User created!' }))
                .catch(logger.handleError);
        }
    }

    getUserFromState() {
        const user = {
            name: this.state.name,
            username: this.state.username,
            email: this.state.email,
            roleid: parseInt(this.state.roleid),
            enabled: this.state.enabled
        }
        return user;
    }

    render() {

        var listRoles = Roles.getAllRoles().map(role => 
                <option key={role.roleid} value={role.roleid}>{role.name}</option>
            );

        return (
            <Container fluid>
                <Form onSubmit={event => this.handleSubmit(event)}>
                    <Row>
                        <Col>
                            <Title mode={this.state.mode} username={this.props.match.params.id} />
                            <FormSuccess message={this.state.formSuccessMessage} show={this.state.formSuccess} />
                            <FormErrors message={this.state.formErrorMessage} show={this.state.formError} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            { this.state.mode === 'create' &&
                                <Form.Group controlId="userUsername">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        maxLength="20"
                                        name="username"
                                        value={this.state.username}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>
                            }

                            <Form.Group controlId="userName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    maxLength="30"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="userEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    maxLength="50"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="userRole">
                                <Form.Label>Role</Form.Label>
                                <Form.Control as="select"
                                    name="roleid"
                                    value={this.state.roleid}
                                    onChange={this.handleChange}
                                >
                                    {listRoles}
                                </Form.Control>
                            </Form.Group>

                            { this.state.mode !== 'create' &&
                                <Form.Group controlId="userEnabled">
                                    <Form.Label>Active?</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        name="enabled"
                                        checked={this.state.enabled}
                                        onChange={this.handleCheckedChange}
                                    />
                                </Form.Group>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-4 mb-4 pull-right">
                            <Button variant="secondary" type="submit" onClick={this.handleSubmit}>
                                    Submit
                            </Button>                
                            <Link to={`/users`}>
                                <Button variant="outline-secondary" className="ml-2">Cancel</Button>  
                            </Link>
                        </Col>
                    </Row>
                </Form>
            </Container>
        )
    }
}

export default UserEdit