import CloudWatchLogs from 'aws-sdk/clients/cloudwatchlogs';

import awsmobile from '../aws-exports';
import { Auth } from 'aws-amplify';

export default {
    getCloudWatchProvider: async () => {
        const credentials = await Auth.currentCredentials();
        return await Promise.resolve(new CloudWatchLogs({
            apiVersion: '2014-03-28',
            credentials: Auth.essentialCredentials(credentials),
            region: awsmobile.aws_project_region
        }));
    }
}