const RolesEnum = {
    Admin: {name: "Admin", group: "admin", roleid: 0},
    ProgramManager: {name: "Program Manager", group: "programmanager", roleid: 10},
    Freelancer: {name: "Freelancer", group: "freelancer", roleid: 20}
};

const allRoles = [RolesEnum.Admin, RolesEnum.ProgramManager, RolesEnum.Freelancer];

const Roles = {
    getAllRoles: () => allRoles.map(item => item),
    getDefaultRole: () => allRoles.sort((a,b) => b.roleid - a.roleid)[0]
};

export default Roles
export { RolesEnum }

