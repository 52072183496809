import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { Link, Redirect } from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap'

import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { replaceNullFieldValues } from '../common/FormFieldStateHelper';
import Bottleneck from 'bottleneck'
import makeComponentTrashable from 'trashable-react';
import Logger from '../../utils/Logger'
const logger = new Logger("EditCampaign.js");

const Title = ({ mode }) =>
  mode === 'create' ? <h2>Create Campaign</h2> : <h2>Edit Campaign</h2>

const FormErrors = ({ message, show }) =>
  show ? <Alert variant="danger">{message}</Alert> : null;

const FormSuccess = ({ message, show }) =>
  show ? <Alert variant="success">{message}</Alert> : null;

class EditCampaign extends Component {
  gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

  constructor(props) {
    super(props)

    this.state = {
      campaignId: 0,
      mode: 'create',
      loading: true,
      data: {
        campaignName: '',
        jobNumber: '',
        client: '',
        deliverables: '',
        campaignDescription: '',
        assignment: ''
      },
      influencers: [],
      redirect: false
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    this.setState({campaignId: id})
    if (id) {
      this.setState({ mode: 'edit' })
      this.graphqlOp(graphqlOperation(queries.getCampaign, { campaignId: id }))
        .then(response => {
          replaceNullFieldValues(response.data.getCampaign);
          this.setState({ loading: false, data: response.data.getCampaign });
        })
        .catch(logger.handleError)

      this.graphqlOp(graphqlOperation(queries.listInfluencerCampaignDetailsByCampaignInfluencerId, { campaignId: parseInt(id, 10) }))
        .then(response => this.setState({
          loadingInfluencers: false,
          influencers: response.data.listInfluencerCampaignDetailsByCampaignInfluencerId
        }))
        .catch(logger.handleError)
    } else {
      this.setState({ campaignId: id, loading: false })
    }
  }

  handleChange = event => {
      const dataCopy = JSON.parse(JSON.stringify(this.state.data))
      dataCopy[event.target.name] = event.target.value
      this.setState({ data: dataCopy })
  }

  handleInfluencerChange = event => {
    const influencerId = parseInt(event.target.name.replace('feePaid_', ''), 10)
    const influencerIndex = this.state.influencers.findIndex(i => i.influencerId === influencerId)
    let influencers = this.state.influencers
    if (influencerIndex > -1) {
      influencers[influencerIndex]['feePaid'] = event.target.value
      this.setState({ influencers: influencers });
    }
  }

  handleSubmit = async event => {

    event.preventDefault()
    const inputData = this.state.data
    const influencersData = this.state.influencers
    const influencerUpdates = []

    await this.graphqlOp(graphqlOperation(mutations.updateCampaign, {
      updateCampaignInput: inputData
    }))

    influencersData.forEach(function (item) {
      influencerUpdates.push(
        this.graphqlOp(graphqlOperation(mutations.updateInfluencerCampaign, {
          updateInfluencerCampaignInput: {
            influencerId: item.influencerId,
            campaignId: item.campaignId,
            feePaid: parseFloat(item.feePaid)
          }
        }))
      )
    })

    await Promise.all(influencerUpdates).then(
      this.setState({redirect: true})
    )
  }

  renderLoading = message => {
    return (
      <Row>
        <Col>
          <Alert variant="info" className="mt-4">Loading {message}...</Alert>
        </Col>
      </Row>
    )
  }

  renderCampaignForm = () => {
    return (
      <Row>
        <Col>
          <Card>
            <Card.Body>

              <Form.Group controlId="campaignName">
                <Form.Label>Campaign Name</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="campaignName"
                  value={this.state.data.campaignName}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="client">
                <Form.Label>Client</Form.Label>
                <Form.Control
                  maxLength="100"
                  name="client"
                  value={this.state.data.client}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="jobNumber">
                <Form.Label>Job Number</Form.Label>
                <Form.Control
                  maxLength="45"
                  name="jobNumber"
                  value={this.state.data.jobNumber}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="campaignDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  maxLength="1000"
                  name="campaignDescription"
                  value={this.state.data.campaignDescription}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="assignment">
                <Form.Label>Assignment</Form.Label>
                <Form.Control
                  maxLength="1000"
                  name="assignment"
                  value={this.state.data.assignment}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="deliverables">
                <Form.Label>Deliverables</Form.Label>
                <Form.Control
                  maxLength="1000"
                  name="deliverables"
                  value={this.state.data.deliverables}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  render() {

    const { campaignId, loading, redirect } = this.state

    if (redirect) {
      return <Redirect to={`/campaigns/details/${this.state.campaignId}`} />;
    }


    return (
      <Container fluid>
        <Form id="EditInfluencerForm" onSubmit={event => this.handleSubmit(event)}>
          <Row>
            <Col>
              <Title mode={this.state.mode} />
              <FormSuccess message={this.state.formSuccessMessage} show={this.state.formSuccess} />
              <FormErrors message={this.state.formErrorMessage} show={this.state.formError} />
            </Col>
          </Row>
          {loading ? this.renderLoading('Campaign') : this.renderCampaignForm()}
          <Row>
            <Col className="mt-4 mb-4 pull-right">
              <Button variant="secondary" type="submit" onClick={this.handleSubmit}>
                Submit
              </Button>
              <Link to={`/campaigns/details/${campaignId}`}>
                <Button variant="outline-secondary" className="ml-2">Cancel</Button>
              </Link>
            </Col>
          </Row>
        </Form>
      </Container>
    )
  }
}

export default makeComponentTrashable(EditCampaign)