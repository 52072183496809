import React, { Component } from 'react'

import {
  Button,
  Col,
  Container,
  Row
} from 'react-bootstrap'

import { Link } from 'react-router-dom'

import ListWALists from '../components/list/ListWALists'
import ListDovetaleLists from '../components/list/ListDovetaleLists'

import withPermissions from '../security/withPermissions';
import Logger from '../utils/Logger'

const logger = new Logger("Lists.js");

const waType = "wa";
const dovetaleType = "dovetale";

class IndexLists extends Component {
  constructor(props) {
    super(props)
    logger.handleInfo("User arrived at Lists page")
  }

  getClassName = (selectedType, linkType) => {
    let className = "nav-item nav-link";
    if (selectedType === linkType)
      className += " active";
    return className
  }

  getListComponent = (selectedType) => {
    switch (selectedType) {
      case waType:
        return (<ListWALists />);
      case dovetaleType:
        return (<ListDovetaleLists />);
      default:
        return null;
    }
  }

  render() {
    let { type } = this.props.match.params;

    type = type || waType;

    return (
      <Container fluid>
        <Row>
          <Col>
            <h1>Lists {this.props.permissions.can("createLists") && <Link to="/lists/create"><Button variant="secondary">Create</Button></Link>}</h1>
            <nav className="nav nav-tabs" role="tablist">
              <Link to={"/lists/" + waType} className={this.getClassName(type, waType)}>Wellness Amplified</Link>
              {/* <Link to={"/lists/" + dovetaleType} className={this.getClassName(type, dovetaleType)}>Dovetale</Link> */}
            </nav>
            {this.getListComponent(type)}
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withPermissions(IndexLists);