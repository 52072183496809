// eslint-disable
// this is an auto generated file. This will be overwritten

export const deleteAttachment = `mutation DeleteAttachment($attachmentId: Int!) {
  deleteAttachment(attachmentId: $attachmentId) {
    attachmentId
    influencerId
    s3Id
    type
    label
  }
}
`;
export const createAttachment = `mutation CreateAttachment($createAttachmentInput: CreateAttachmentInput!) {
  createAttachment(createAttachmentInput: $createAttachmentInput) {
    attachmentId
    influencerId
    s3Id
    type
    label
  }
}
`;
export const updateAttachment = `mutation UpdateAttachment($updateAttachmentInput: UpdateAttachmentInput!) {
  updateAttachment(updateAttachmentInput: $updateAttachmentInput) {
    attachmentId
    influencerId
    s3Id
    type
    label
  }
}
`;
export const deleteCampaign = `mutation DeleteCampaign($campaignId: Int!) {
  deleteCampaign(campaignId: $campaignId) {
    campaignId
    campaignName
    jobNumber
    deliverables
    campaignDescription
    client
    assignment
    dovetaleId
  }
}
`;
export const createCampaign = `mutation CreateCampaign($createCampaignInput: CreateCampaignInput!) {
  createCampaign(createCampaignInput: $createCampaignInput) {
    campaignId
    campaignName
    jobNumber
    deliverables
    campaignDescription
    client
    assignment
    dovetaleId
  }
}
`;
export const updateCampaign = `mutation UpdateCampaign($updateCampaignInput: UpdateCampaignInput!) {
  updateCampaign(updateCampaignInput: $updateCampaignInput) {
    campaignId
    campaignName
    jobNumber
    deliverables
    campaignDescription
    client
    assignment
    dovetaleId
  }
}
`;
export const deleteCampaignStatIg = `mutation DeleteCampaignStatIg($campaignId: Int!) {
  deleteCampaignStatIG(campaignId: $campaignId) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalReach
    totalImpressions
    totalLikes
    totalComments
    totalVideoViews
    totalEngagements
    totalEngagementRate
    demoAgeUnder21
    demoAge21To34
    demoAgeOver34
    demoGenderMale
    demoGenderFemale
    demoGenderOther
    audienceUS
    audienceCA
    sentimentPositive
    sentimentNeutral
    sentimentNegative
  }
}
`;
export const createCampaignStatIg = `mutation CreateCampaignStatIg(
  $createCampaignStatIGInput: CreateCampaignStatIGInput!
) {
  createCampaignStatIG(createCampaignStatIGInput: $createCampaignStatIGInput) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalReach
    totalImpressions
    totalLikes
    totalComments
    totalVideoViews
    totalEngagements
    totalEngagementRate
    demoAgeUnder21
    demoAge21To34
    demoAgeOver34
    demoGenderMale
    demoGenderFemale
    demoGenderOther
    audienceUS
    audienceCA
    sentimentPositive
    sentimentNeutral
    sentimentNegative
  }
}
`;
export const updateCampaignStatIg = `mutation UpdateCampaignStatIg(
  $updateCampaignStatIGInput: UpdateCampaignStatIGInput!
) {
  updateCampaignStatIG(updateCampaignStatIGInput: $updateCampaignStatIGInput) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalReach
    totalImpressions
    totalLikes
    totalComments
    totalVideoViews
    totalEngagements
    totalEngagementRate
    demoAgeUnder21
    demoAge21To34
    demoAgeOver34
    demoGenderMale
    demoGenderFemale
    demoGenderOther
    audienceUS
    audienceCA
    sentimentPositive
    sentimentNeutral
    sentimentNegative
  }
}
`;
export const deleteCampaignStatIgb = `mutation DeleteCampaignStatIgb($campaignId: Int!) {
  deleteCampaignStatIGB(campaignId: $campaignId) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalImpressions
    totalLikes
    totalComments
    totalEngagementRate
    audienceUS
    audienceCA
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
  }
}
`;
export const createCampaignStatIgb = `mutation CreateCampaignStatIgb(
  $createCampaignStatIGBInput: CreateCampaignStatIGBInput!
) {
  createCampaignStatIGB(
    createCampaignStatIGBInput: $createCampaignStatIGBInput
  ) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalImpressions
    totalLikes
    totalComments
    totalEngagementRate
    audienceUS
    audienceCA
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
  }
}
`;
export const updateCampaignStatIgb = `mutation UpdateCampaignStatIgb(
  $updateCampaignStatIGBInput: UpdateCampaignStatIGBInput!
) {
  updateCampaignStatIGB(
    updateCampaignStatIGBInput: $updateCampaignStatIGBInput
  ) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalImpressions
    totalLikes
    totalComments
    totalEngagementRate
    audienceUS
    audienceCA
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
  }
}
`;
export const deleteCampaignStatTw = `mutation DeleteCampaignStatTw($campaignId: Int!) {
  deleteCampaignStatTW(campaignId: $campaignId) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalLikes
    totalRetweets
    totalEngagementRate
  }
}
`;
export const createCampaignStatTw = `mutation CreateCampaignStatTw(
  $createCampaignStatTWInput: CreateCampaignStatTWInput!
) {
  createCampaignStatTW(createCampaignStatTWInput: $createCampaignStatTWInput) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalLikes
    totalRetweets
    totalEngagementRate
  }
}
`;
export const updateCampaignStatTw = `mutation UpdateCampaignStatTw(
  $updateCampaignStatTWInput: UpdateCampaignStatTWInput!
) {
  updateCampaignStatTW(updateCampaignStatTWInput: $updateCampaignStatTWInput) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalLikes
    totalRetweets
    totalEngagementRate
  }
}
`;
export const deleteCampaignStatFb = `mutation DeleteCampaignStatFb($campaignId: Int!) {
  deleteCampaignStatFB(campaignId: $campaignId) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalImpressions
    totalReach
    totalLikes
    totalComments
    totalEngagementRate
    totalShares
    totalReactions
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
  }
}
`;
export const createCampaignStatFb = `mutation CreateCampaignStatFb(
  $createCampaignStatFBInput: CreateCampaignStatFBInput!
) {
  createCampaignStatFB(createCampaignStatFBInput: $createCampaignStatFBInput) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalImpressions
    totalReach
    totalLikes
    totalComments
    totalEngagementRate
    totalShares
    totalReactions
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
  }
}
`;
export const updateCampaignStatFb = `mutation UpdateCampaignStatFb(
  $updateCampaignStatFBInput: UpdateCampaignStatFBInput!
) {
  updateCampaignStatFB(updateCampaignStatFBInput: $updateCampaignStatFBInput) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalImpressions
    totalReach
    totalLikes
    totalComments
    totalEngagementRate
    totalShares
    totalReactions
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
  }
}
`;
export const deleteCampaignStatYt = `mutation DeleteCampaignStatYt($campaignId: Int!) {
  deleteCampaignStatYT(campaignId: $campaignId) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalVideos
    totalViews
    totalLikes
    totalDislikes
    totalComments
    totalEngagements
    totalEngagementRate
    totalMinutesWatched
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    audienceUS
    audienceCA
  }
}
`;
export const createCampaignStatYt = `mutation CreateCampaignStatYt(
  $createCampaignStatYTInput: CreateCampaignStatYTInput!
) {
  createCampaignStatYT(createCampaignStatYTInput: $createCampaignStatYTInput) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalVideos
    totalViews
    totalLikes
    totalDislikes
    totalComments
    totalEngagements
    totalEngagementRate
    totalMinutesWatched
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    audienceUS
    audienceCA
  }
}
`;
export const updateCampaignStatYt = `mutation UpdateCampaignStatYt(
  $updateCampaignStatYTInput: UpdateCampaignStatYTInput!
) {
  updateCampaignStatYT(updateCampaignStatYTInput: $updateCampaignStatYTInput) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalVideos
    totalViews
    totalLikes
    totalDislikes
    totalComments
    totalEngagements
    totalEngagementRate
    totalMinutesWatched
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    audienceUS
    audienceCA
  }
}
`;
export const deleteCommunication = `mutation DeleteCommunication($communicationId: Int!) {
  deleteCommunication(communicationId: $communicationId) {
    communicationId
    communicationSourceId
    influencerId
    subject
    opened
    sentTime
    openedTime
    notes
    campaignName
  }
}
`;
export const createCommunication = `mutation CreateCommunication(
  $createCommunicationInput: CreateCommunicationInput!
) {
  createCommunication(createCommunicationInput: $createCommunicationInput) {
    communicationId
    communicationSourceId
    influencerId
    subject
    opened
    sentTime
    openedTime
    notes
    campaignName
  }
}
`;
export const updateCommunication = `mutation UpdateCommunication(
  $updateCommunicationInput: UpdateCommunicationInput!
) {
  updateCommunication(updateCommunicationInput: $updateCommunicationInput) {
    communicationId
    communicationSourceId
    influencerId
    subject
    opened
    sentTime
    openedTime
    notes
    campaignName
  }
}
`;
export const deleteCommunicationSource = `mutation DeleteCommunicationSource($communicationSourceId: Int!) {
  deleteCommunicationSource(communicationSourceId: $communicationSourceId) {
    communicationSourceId
    displayName
    lastSynced
  }
}
`;
export const createCommunicationSource = `mutation CreateCommunicationSource(
  $createCommunicationSourceInput: CreateCommunicationSourceInput!
) {
  createCommunicationSource(
    createCommunicationSourceInput: $createCommunicationSourceInput
  ) {
    communicationSourceId
    displayName
    lastSynced
  }
}
`;
export const updateCommunicationSource = `mutation UpdateCommunicationSource(
  $updateCommunicationSourceInput: UpdateCommunicationSourceInput!
) {
  updateCommunicationSource(
    updateCommunicationSourceInput: $updateCommunicationSourceInput
  ) {
    communicationSourceId
    displayName
    lastSynced
  }
}
`;
export const deleteConfig = `mutation DeleteConfig($configId: Int!) {
  deleteConfig(configId: $configId) {
    configId
    configValue
  }
}
`;
export const createConfig = `mutation CreateConfig($createConfigInput: CreateConfigInput!) {
  createConfig(createConfigInput: $createConfigInput) {
    configId
    configValue
  }
}
`;
export const updateConfig = `mutation UpdateConfig($updateConfigInput: UpdateConfigInput!) {
  updateConfig(updateConfigInput: $updateConfigInput) {
    configId
    configValue
  }
}
`;
export const deleteInfluencer = `mutation DeleteInfluencer($influencerId: Int!) {
  deleteInfluencer(influencerId: $influencerId) {
    influencerId
    unsubscribed
    influencerRelationshipStatusId
    prefix
    firstName
    lastName
    suffix
    bio
    notes
    city
    state
    zip
    country
    phone
    websiteURL
    websiteFollowers
    websiteFollowersUpdatedTime
    blogURL
    constantContactId
    replyId
    emailAddress1
    emailAddress2
    pinterestHandle
    pinterestFollowers
    pinterestFollowersUpdatedTime
    linkedInURL
    linkedInFollowers
    linkedInFollowersUpdatedTime
    IGhandle
    IGengagementRate
    IGfollowers
    IGbio
    IGsponsoredPercentage
    IGdemoAgeUnder21
    IGdemoAge21To34
    IGdemoAgeOver34
    IGdemoGenderMale
    IGdemoGenderFemale
    IGdemoGenderOther
    IGaudienceUS
    USaudienceCA
    TWusername
    TWengagementRate
    TWfollowers
    TWbio
    FBusername
    FBengagementRate
    FBpageLikes
    FBbio
    YTusername
    YTengagementRate
    YTsubscribers
    YTbio
    YTviewsPerVideo
    YTchannelUrl
    relationshipStatus
    customTags
    tumblrHandle
    snapchatHandle
    surveyDate
    creationTime
    creationSource
    totalFollowing
    addressLine1
    resultsCount
  }
}
`;
export const createInfluencer = `mutation CreateInfluencer($createInfluencerInput: CreateInfluencerInput!) {
  createInfluencer(createInfluencerInput: $createInfluencerInput) {
    influencerId
    unsubscribed
    influencerRelationshipStatusId
    prefix
    firstName
    lastName
    suffix
    bio
    notes
    city
    state
    zip
    country
    phone
    websiteURL
    websiteFollowers
    websiteFollowersUpdatedTime
    blogURL
    constantContactId
    replyId
    emailAddress1
    emailAddress2
    pinterestHandle
    pinterestFollowers
    pinterestFollowersUpdatedTime
    linkedInURL
    linkedInFollowers
    linkedInFollowersUpdatedTime
    IGhandle
    IGengagementRate
    IGfollowers
    IGbio
    IGsponsoredPercentage
    IGdemoAgeUnder21
    IGdemoAge21To34
    IGdemoAgeOver34
    IGdemoGenderMale
    IGdemoGenderFemale
    IGdemoGenderOther
    IGaudienceUS
    USaudienceCA
    TWusername
    TWengagementRate
    TWfollowers
    TWbio
    FBusername
    FBengagementRate
    FBpageLikes
    FBbio
    YTusername
    YTengagementRate
    YTsubscribers
    YTbio
    YTviewsPerVideo
    YTchannelUrl
    relationshipStatus
    customTags
    tumblrHandle
    snapchatHandle
    surveyDate
    creationTime
    creationSource
    totalFollowing
    addressLine1
    resultsCount
  }
}
`;
export const updateInfluencer = `mutation UpdateInfluencer($updateInfluencerInput: UpdateInfluencerInput!) {
  updateInfluencer(updateInfluencerInput: $updateInfluencerInput) {
    influencerId
    unsubscribed
    influencerRelationshipStatusId
    prefix
    firstName
    lastName
    suffix
    bio
    notes
    city
    state
    zip
    country
    phone
    websiteURL
    websiteFollowers
    websiteFollowersUpdatedTime
    blogURL
    constantContactId
    replyId
    emailAddress1
    emailAddress2
    pinterestHandle
    pinterestFollowers
    pinterestFollowersUpdatedTime
    linkedInURL
    linkedInFollowers
    linkedInFollowersUpdatedTime
    IGhandle
    IGengagementRate
    IGfollowers
    IGbio
    IGsponsoredPercentage
    IGdemoAgeUnder21
    IGdemoAge21To34
    IGdemoAgeOver34
    IGdemoGenderMale
    IGdemoGenderFemale
    IGdemoGenderOther
    IGaudienceUS
    USaudienceCA
    TWusername
    TWengagementRate
    TWfollowers
    TWbio
    FBusername
    FBengagementRate
    FBpageLikes
    FBbio
    YTusername
    YTengagementRate
    YTsubscribers
    YTbio
    YTviewsPerVideo
    YTchannelUrl
    relationshipStatus
    customTags
    tumblrHandle
    snapchatHandle
    surveyDate
    creationTime
    creationSource
    totalFollowing
    addressLine1
    resultsCount
  }
}
`;
export const deleteInfluencerCampaign = `mutation DeleteInfluencerCampaign($campaignId: Int!, $influencerId: Int!) {
  deleteInfluencerCampaign(
    campaignId: $campaignId
    influencerId: $influencerId
  ) {
    influencerId
    campaignId
    feePaid
  }
}
`;
export const createInfluencerCampaign = `mutation CreateInfluencerCampaign(
  $createInfluencerCampaignInput: CreateInfluencerCampaignInput!
) {
  createInfluencerCampaign(
    createInfluencerCampaignInput: $createInfluencerCampaignInput
  ) {
    influencerId
    campaignId
    feePaid
  }
}
`;
export const updateInfluencerCampaign = `mutation UpdateInfluencerCampaign(
  $updateInfluencerCampaignInput: UpdateInfluencerCampaignInput!
) {
  updateInfluencerCampaign(
    updateInfluencerCampaignInput: $updateInfluencerCampaignInput
  ) {
    influencerId
    campaignId
    feePaid
  }
}
`;
export const deleteInfluencerList = `mutation DeleteInfluencerList($listId: Int!, $influencerId: Int!) {
  deleteInfluencerList(listId: $listId, influencerId: $influencerId) {
    influencerId
    listId
  }
}
`;
export const createInfluencerList = `mutation CreateInfluencerList(
  $createInfluencerListInput: CreateInfluencerListInput!
) {
  createInfluencerList(createInfluencerListInput: $createInfluencerListInput) {
    influencerId
    listId
  }
}
`;
export const updateInfluencerList = `mutation UpdateInfluencerList(
  $updateInfluencerListInput: UpdateInfluencerListInput!
) {
  updateInfluencerList(updateInfluencerListInput: $updateInfluencerListInput) {
    influencerId
    listId
  }
}
`;
export const deleteInfluencerProfilePic = `mutation DeleteInfluencerProfilePic($influencerProfilePicId: Int!) {
  deleteInfluencerProfilePic(influencerProfilePicId: $influencerProfilePicId) {
    influencerProfilePicId
    influencerId
    s3Id
    label
  }
}
`;
export const createInfluencerProfilePic = `mutation CreateInfluencerProfilePic(
  $createInfluencerProfilePicInput: CreateInfluencerProfilePicInput!
) {
  createInfluencerProfilePic(
    createInfluencerProfilePicInput: $createInfluencerProfilePicInput
  ) {
    influencerProfilePicId
    influencerId
    s3Id
    label
  }
}
`;
export const updateInfluencerProfilePic = `mutation UpdateInfluencerProfilePic(
  $updateInfluencerProfilePicInput: UpdateInfluencerProfilePicInput!
) {
  updateInfluencerProfilePic(
    updateInfluencerProfilePicInput: $updateInfluencerProfilePicInput
  ) {
    influencerProfilePicId
    influencerId
    s3Id
    label
  }
}
`;
export const deleteInfluencerRelationshipStatus = `mutation DeleteInfluencerRelationshipStatus(
  $influencerRelationshipStatusId: Int!
) {
  deleteInfluencerRelationshipStatus(
    influencerRelationshipStatusId: $influencerRelationshipStatusId
  ) {
    influencerRelationshipStatusId
    relationshipLabel
  }
}
`;
export const createInfluencerRelationshipStatus = `mutation CreateInfluencerRelationshipStatus(
  $createInfluencerRelationshipStatusInput: CreateInfluencerRelationshipStatusInput!
) {
  createInfluencerRelationshipStatus(
    createInfluencerRelationshipStatusInput: $createInfluencerRelationshipStatusInput
  ) {
    influencerRelationshipStatusId
    relationshipLabel
  }
}
`;
export const updateInfluencerRelationshipStatus = `mutation UpdateInfluencerRelationshipStatus(
  $updateInfluencerRelationshipStatusInput: UpdateInfluencerRelationshipStatusInput!
) {
  updateInfluencerRelationshipStatus(
    updateInfluencerRelationshipStatusInput: $updateInfluencerRelationshipStatusInput
  ) {
    influencerRelationshipStatusId
    relationshipLabel
  }
}
`;
export const deleteInfluencerStatIg = `mutation DeleteInfluencerStatIg($influencerId: Int!) {
  deleteInfluencerStatIG(influencerId: $influencerId) {
    influencerId
    handle
    engagementRate
    engagementRateLastUpdated
    followers
    followersLastUpdated
    bio
    sponsoredPercentage
    demoAgeUnder21
    demoAge21To34
    demoAgeOver34
    demoGenderMale
    demoGenderFemale
    demoGenderOther
    audienceUS
    audienceCA
  }
}
`;
export const createInfluencerStatIg = `mutation CreateInfluencerStatIg(
  $createInfluencerStatIGInput: CreateInfluencerStatIGInput!
) {
  createInfluencerStatIG(
    createInfluencerStatIGInput: $createInfluencerStatIGInput
  ) {
    influencerId
    handle
    engagementRate
    engagementRateLastUpdated
    followers
    followersLastUpdated
    bio
    sponsoredPercentage
    demoAgeUnder21
    demoAge21To34
    demoAgeOver34
    demoGenderMale
    demoGenderFemale
    demoGenderOther
    audienceUS
    audienceCA
  }
}
`;
export const updateInfluencerStatIg = `mutation UpdateInfluencerStatIg(
  $updateInfluencerStatIGInput: UpdateInfluencerStatIGInput!
) {
  updateInfluencerStatIG(
    updateInfluencerStatIGInput: $updateInfluencerStatIGInput
  ) {
    influencerId
    handle
    engagementRate
    engagementRateLastUpdated
    followers
    followersLastUpdated
    bio
    sponsoredPercentage
    demoAgeUnder21
    demoAge21To34
    demoAgeOver34
    demoGenderMale
    demoGenderFemale
    demoGenderOther
    audienceUS
    audienceCA
  }
}
`;
export const deleteInfluencerStatTw = `mutation DeleteInfluencerStatTw($influencerId: Int!) {
  deleteInfluencerStatTW(influencerId: $influencerId) {
    influencerId
    username
    engagementRate
    engagementRateLastUpdated
    followers
    followersLastUpdated
    bio
  }
}
`;
export const createInfluencerStatTw = `mutation CreateInfluencerStatTw(
  $createInfluencerStatTWInput: CreateInfluencerStatTWInput!
) {
  createInfluencerStatTW(
    createInfluencerStatTWInput: $createInfluencerStatTWInput
  ) {
    influencerId
    username
    engagementRate
    engagementRateLastUpdated
    followers
    followersLastUpdated
    bio
  }
}
`;
export const updateInfluencerStatTw = `mutation UpdateInfluencerStatTw(
  $updateInfluencerStatTWInput: UpdateInfluencerStatTWInput!
) {
  updateInfluencerStatTW(
    updateInfluencerStatTWInput: $updateInfluencerStatTWInput
  ) {
    influencerId
    username
    engagementRate
    engagementRateLastUpdated
    followers
    followersLastUpdated
    bio
  }
}
`;
export const deleteInfluencerStatFb = `mutation DeleteInfluencerStatFb($influencerId: Int!) {
  deleteInfluencerStatFB(influencerId: $influencerId) {
    influencerId
    username
    engagementRate
    engagementRateLastUpdated
    pageLikes
    pageLikesLastUpdated
    bio
  }
}
`;
export const createInfluencerStatFb = `mutation CreateInfluencerStatFb(
  $createInfluencerStatFBInput: CreateInfluencerStatFBInput!
) {
  createInfluencerStatFB(
    createInfluencerStatFBInput: $createInfluencerStatFBInput
  ) {
    influencerId
    username
    engagementRate
    engagementRateLastUpdated
    pageLikes
    pageLikesLastUpdated
    bio
  }
}
`;
export const updateInfluencerStatFb = `mutation UpdateInfluencerStatFb(
  $updateInfluencerStatFBInput: UpdateInfluencerStatFBInput!
) {
  updateInfluencerStatFB(
    updateInfluencerStatFBInput: $updateInfluencerStatFBInput
  ) {
    influencerId
    username
    engagementRate
    engagementRateLastUpdated
    pageLikes
    pageLikesLastUpdated
    bio
  }
}
`;
export const deleteInfluencerStatYt = `mutation DeleteInfluencerStatYt($influencerId: Int!) {
  deleteInfluencerStatYT(influencerId: $influencerId) {
    influencerId
    username
    channelUrl
    engagementRate
    engagementRateLastUpdated
    subscribers
    subscribersLastUpdated
    bio
    viewsPerVideo
  }
}
`;
export const createInfluencerStatYt = `mutation CreateInfluencerStatYt(
  $createInfluencerStatYTInput: CreateInfluencerStatYTInput!
) {
  createInfluencerStatYT(
    createInfluencerStatYTInput: $createInfluencerStatYTInput
  ) {
    influencerId
    username
    channelUrl
    engagementRate
    engagementRateLastUpdated
    subscribers
    subscribersLastUpdated
    bio
    viewsPerVideo
  }
}
`;
export const updateInfluencerStatYt = `mutation UpdateInfluencerStatYt(
  $updateInfluencerStatYTInput: UpdateInfluencerStatYTInput!
) {
  updateInfluencerStatYT(
    updateInfluencerStatYTInput: $updateInfluencerStatYTInput
  ) {
    influencerId
    username
    channelUrl
    engagementRate
    engagementRateLastUpdated
    subscribers
    subscribersLastUpdated
    bio
    viewsPerVideo
  }
}
`;
export const deleteInfluencerTagValue = `mutation DeleteInfluencerTagValue($tagValueId: Int!, $influencerId: Int!) {
  deleteInfluencerTagValue(
    tagValueId: $tagValueId
    influencerId: $influencerId
  ) {
    influencerId
    tagValueId
  }
}
`;
export const createInfluencerTagValue = `mutation CreateInfluencerTagValue(
  $createInfluencerTagValueInput: CreateInfluencerTagValueInput!
) {
  createInfluencerTagValue(
    createInfluencerTagValueInput: $createInfluencerTagValueInput
  ) {
    influencerId
    tagValueId
  }
}
`;
export const deleteInfluencerTagValueBulk = `mutation DeleteInfluencerTagValueBulk(
  $deleteInfluencerTagValueBulkInput: DeleteInfluencerTagValueBulkInput!
) {
  deleteInfluencerTagValueBulk(
    deleteInfluencerTagValueBulkInput: $deleteInfluencerTagValueBulkInput
  )
}
`;
export const createInfluencerTagValueBulk = `mutation CreateInfluencerTagValueBulk(
  $createInfluencerTagValueBulkInput: CreateInfluencerTagValueBulkInput!
) {
  createInfluencerTagValueBulk(
    createInfluencerTagValueBulkInput: $createInfluencerTagValueBulkInput
  )
}
`;
export const updateInfluencerTagValue = `mutation UpdateInfluencerTagValue(
  $updateInfluencerTagValueInput: UpdateInfluencerTagValueInput!
) {
  updateInfluencerTagValue(
    updateInfluencerTagValueInput: $updateInfluencerTagValueInput
  ) {
    influencerId
    tagValueId
  }
}
`;
export const deleteList = `mutation DeleteList($listId: Int!) {
  deleteList(listId: $listId) {
    listId
    name
    dovetaleId
  }
}
`;
export const createList = `mutation CreateList($createListInput: CreateListInput!) {
  createList(createListInput: $createListInput) {
    listId
    name
    dovetaleId
  }
}
`;
export const updateList = `mutation UpdateList($updateListInput: UpdateListInput!) {
  updateList(updateListInput: $updateListInput) {
    listId
    name
    dovetaleId
  }
}
`;
export const deletePostBlog = `mutation DeletePostBlog($postBlogId: Int!) {
  deletePostBlog(postBlogId: $postBlogId) {
    postBlogId
    influencerId
    campaignId
    link
    postedAt
    impressions
  }
}
`;
export const createPostBlog = `mutation CreatePostBlog($createPostBlogInput: CreatePostBlogInput!) {
  createPostBlog(createPostBlogInput: $createPostBlogInput) {
    postBlogId
    influencerId
    campaignId
    link
    postedAt
    impressions
  }
}
`;
export const updatePostBlog = `mutation UpdatePostBlog($updatePostBlogInput: UpdatePostBlogInput!) {
  updatePostBlog(updatePostBlogInput: $updatePostBlogInput) {
    postBlogId
    influencerId
    campaignId
    link
    postedAt
    impressions
  }
}
`;
export const deletePostInstagram = `mutation DeletePostInstagram($postInstagramId: Int!) {
  deletePostInstagram(postInstagramId: $postInstagramId) {
    postInstagramId
    influencerId
    campaignId
    link
    contentUrl
    caption
    postedAt
    postedBy
    engagementRate
    estimatedImpressions
    likes
    demoAgeUnder21
    demoAge21To34
    demoAgeOver34
    demoGenderMale
    demoGenderFemale
    demoGenderOther
    tags
    reach
    impressions
    engagements
    comments
    shares
  }
}
`;
export const createPostInstagram = `mutation CreatePostInstagram(
  $createPostInstagramInput: CreatePostInstagramInput!
) {
  createPostInstagram(createPostInstagramInput: $createPostInstagramInput) {
    postInstagramId
    influencerId
    campaignId
    link
    contentUrl
    caption
    postedAt
    postedBy
    engagementRate
    estimatedImpressions
    likes
    demoAgeUnder21
    demoAge21To34
    demoAgeOver34
    demoGenderMale
    demoGenderFemale
    demoGenderOther
    tags
    reach
    impressions
    engagements
    comments
    shares
  }
}
`;
export const updatePostInstagram = `mutation UpdatePostInstagram(
  $updatePostInstagramInput: UpdatePostInstagramInput!
) {
  updatePostInstagram(updatePostInstagramInput: $updatePostInstagramInput) {
    postInstagramId
    influencerId
    campaignId
    link
    contentUrl
    caption
    postedAt
    postedBy
    engagementRate
    estimatedImpressions
    likes
    demoAgeUnder21
    demoAge21To34
    demoAgeOver34
    demoGenderMale
    demoGenderFemale
    demoGenderOther
    tags
    reach
    impressions
    engagements
    comments
    shares
  }
}
`;
export const deletePostInstagramBusiness = `mutation DeletePostInstagramBusiness($postInstagramBusinessId: Int!) {
  deletePostInstagramBusiness(
    postInstagramBusinessId: $postInstagramBusinessId
  ) {
    postInstagramBusinessId
    influencerId
    campaignId
    link
    caption
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    engagementRate
    impressions
    likes
    mediaUrl
    postedAt
    postedBy
    reach
    replies
    tags
  }
}
`;
export const createPostInstagramBusiness = `mutation CreatePostInstagramBusiness(
  $createPostInstagramBusinessInput: CreatePostInstagramBusinessInput!
) {
  createPostInstagramBusiness(
    createPostInstagramBusinessInput: $createPostInstagramBusinessInput
  ) {
    postInstagramBusinessId
    influencerId
    campaignId
    link
    caption
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    engagementRate
    impressions
    likes
    mediaUrl
    postedAt
    postedBy
    reach
    replies
    tags
  }
}
`;
export const updatePostInstagramBusiness = `mutation UpdatePostInstagramBusiness(
  $updatePostInstagramBusinessInput: UpdatePostInstagramBusinessInput!
) {
  updatePostInstagramBusiness(
    updatePostInstagramBusinessInput: $updatePostInstagramBusinessInput
  ) {
    postInstagramBusinessId
    influencerId
    campaignId
    link
    caption
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    engagementRate
    impressions
    likes
    mediaUrl
    postedAt
    postedBy
    reach
    replies
    tags
  }
}
`;
export const deletePostTwitter = `mutation DeletePostTwitter($postTwitterId: Int!) {
  deletePostTwitter(postTwitterId: $postTwitterId) {
    postTwitterId
    influencerId
    campaignId
    link
    engagementRate
    followers
    likes
    postedAt
    postedBy
    reach
    retweets
    tags
    tweet
    engagements
  }
}
`;
export const createPostTwitter = `mutation CreatePostTwitter($createPostTwitterInput: CreatePostTwitterInput!) {
  createPostTwitter(createPostTwitterInput: $createPostTwitterInput) {
    postTwitterId
    influencerId
    campaignId
    link
    engagementRate
    followers
    likes
    postedAt
    postedBy
    reach
    retweets
    tags
    tweet
    engagements
  }
}
`;
export const updatePostTwitter = `mutation UpdatePostTwitter($updatePostTwitterInput: UpdatePostTwitterInput!) {
  updatePostTwitter(updatePostTwitterInput: $updatePostTwitterInput) {
    postTwitterId
    influencerId
    campaignId
    link
    engagementRate
    followers
    likes
    postedAt
    postedBy
    reach
    retweets
    tags
    tweet
    engagements
  }
}
`;
export const deletePostFacebook = `mutation DeletePostFacebook($postFacebookId: Int!) {
  deletePostFacebook(postFacebookId: $postFacebookId) {
    postFacebookId
    influencerId
    campaignId
    link
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    engagementRate
    impressions
    likes
    message
    postedAt
    postedBy
    reach
    reactions
    shares
    tags
    thumbnailUrl
    engagements
    comments
  }
}
`;
export const createPostFacebook = `mutation CreatePostFacebook(
  $createPostFacebookInput: CreatePostFacebookInput!
) {
  createPostFacebook(createPostFacebookInput: $createPostFacebookInput) {
    postFacebookId
    influencerId
    campaignId
    link
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    engagementRate
    impressions
    likes
    message
    postedAt
    postedBy
    reach
    reactions
    shares
    tags
    thumbnailUrl
    engagements
    comments
  }
}
`;
export const updatePostFacebook = `mutation UpdatePostFacebook(
  $updatePostFacebookInput: UpdatePostFacebookInput!
) {
  updatePostFacebook(updatePostFacebookInput: $updatePostFacebookInput) {
    postFacebookId
    influencerId
    campaignId
    link
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    engagementRate
    impressions
    likes
    message
    postedAt
    postedBy
    reach
    reactions
    shares
    tags
    thumbnailUrl
    engagements
    comments
  }
}
`;
export const deletePostYoutube = `mutation DeletePostYoutube($postYoutubeId: Int!) {
  deletePostYoutube(postYoutubeId: $postYoutubeId) {
    postYoutubeId
    influencerId
    campaignId
    link
    comments
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    description
    dislikes
    engagementRate
    estimatedMinutesWatched
    likes
    postedAt
    postedBy
    shares
    tags
    thumbnailUrl
    title
    views
    audienceUS
    audienceCA
    reach
    engagements
  }
}
`;
export const createPostYoutube = `mutation CreatePostYoutube($createPostYoutubeInput: CreatePostYoutubeInput!) {
  createPostYoutube(createPostYoutubeInput: $createPostYoutubeInput) {
    postYoutubeId
    influencerId
    campaignId
    link
    comments
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    description
    dislikes
    engagementRate
    estimatedMinutesWatched
    likes
    postedAt
    postedBy
    shares
    tags
    thumbnailUrl
    title
    views
    audienceUS
    audienceCA
    reach
    engagements
  }
}
`;
export const updatePostYoutube = `mutation UpdatePostYoutube($updatePostYoutubeInput: UpdatePostYoutubeInput!) {
  updatePostYoutube(updatePostYoutubeInput: $updatePostYoutubeInput) {
    postYoutubeId
    influencerId
    campaignId
    link
    comments
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    description
    dislikes
    engagementRate
    estimatedMinutesWatched
    likes
    postedAt
    postedBy
    shares
    tags
    thumbnailUrl
    title
    views
    audienceUS
    audienceCA
    reach
    engagements
  }
}
`;
export const deleteTag = `mutation DeleteTag($tagId: Int!) {
  deleteTag(tagId: $tagId) {
    tagId
    label
  }
}
`;
export const createTag = `mutation CreateTag($createTagInput: CreateTagInput!) {
  createTag(createTagInput: $createTagInput) {
    tagId
    label
  }
}
`;
export const updateTag = `mutation UpdateTag($updateTagInput: UpdateTagInput!) {
  updateTag(updateTagInput: $updateTagInput) {
    tagId
    label
  }
}
`;
export const deleteTagValue = `mutation DeleteTagValue($tagValueId: Int!) {
  deleteTagValue(tagValueId: $tagValueId) {
    tagValueId
    tagId
    label
  }
}
`;
export const createTagValue = `mutation CreateTagValue($createTagValueInput: CreateTagValueInput!) {
  createTagValue(createTagValueInput: $createTagValueInput) {
    tagValueId
    tagId
    label
  }
}
`;
export const updateTagValue = `mutation UpdateTagValue($updateTagValueInput: UpdateTagValueInput!) {
  updateTagValue(updateTagValueInput: $updateTagValueInput) {
    tagValueId
    tagId
    label
  }
}
`;
