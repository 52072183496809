import {
  API,
  graphqlOperation
} from 'aws-amplify'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import * as format from '../../utils/format'
import _ from 'lodash'
import Logger from '../../utils/Logger'
const logger = new Logger("influencerUtils.js");

export const getInfluencerPlatformStats = (influencerId) => {
  const ig = getInfluencerStatIG(influencerId);
  const tw = getInfluencerStatTW(influencerId);
  const fb = getInfluencerStatFB(influencerId);
  const yt = getInfluencerStatYT(influencerId);
  const keys = ['statIG', 'statTW', 'statFB', 'statYT'];
  const origKeys = ['igOriginalData', 'twOriginalData', 'fbOriginalData', 'ytOriginalData'];
  return Promise.all([ig, tw, fb, yt]).then(response => {
    let map = {};
    response.forEach((obj, index) => {
      map[keys[index]] = obj || {};
      map[origKeys[index]] = obj || {};
    });
    return map;
  })
  .catch(logger.handleError)
}

const getInfluencerStatIG = (influencerId) => {
 return API.graphql(graphqlOperation(queries.getInfluencerStatIg, { influencerId }))
    .then(response => {
      let igResponse = response.data.getInfluencerStatIG;
      return format.undoEscapesJson(igResponse);
    })
    .catch(logger.handleError);
}

const getInfluencerStatTW = (influencerId) => {
  return API.graphql(graphqlOperation(queries.getInfluencerStatTw, { influencerId }))
    .then(response => {
      let twResponse = response.data.getInfluencerStatTW;
      return format.undoEscapesJson(twResponse);
    })
    .catch(logger.handleError);
}

const getInfluencerStatFB = (influencerId) => {
  return API.graphql(graphqlOperation(queries.getInfluencerStatFb, { influencerId }))
    .then(response => {
      let fbResponse = response.data.getInfluencerStatFB;
      return format.undoEscapesJson(fbResponse);
    })
    .catch(logger.handleError);
}

const getInfluencerStatYT = (influencerId) => {
  return API.graphql(graphqlOperation(queries.getInfluencerStatYt, { influencerId }))
    .then(response => {
      let ytResponse = response.data.getInfluencerStatYT;
      return format.undoEscapesJson(ytResponse);
    })
    .catch(logger.handleError);
}

export const createInfluencer = (influencer, stats) => {
  return API.graphql(graphqlOperation(mutations.createInfluencer, { createInfluencerInput: influencer }))
  .then((response) => {
    let { influencerId } = response.data.createInfluencer;
    return updateInfluencerStats(influencerId, stats);
  })
  .catch(logger.handleError)
}

export const updateInfluencer = (influencer, stats) => {
  return API.graphql(graphqlOperation(mutations.updateInfluencer, { updateInfluencerInput: influencer }))
  .then((response) => {
    let { influencerId } = response.data.updateInfluencer;
    return updateInfluencerStats(influencerId, stats);
  })
  .catch(logger.handleError)
}

const updateInfluencerStats = (influencerId, stats) => {
  let promises = [];
  promises.push(submitInfluencerStatIg(stats.statIG, influencerId, stats.igOriginalData));
  promises.push(submitInfluencerStatTw(stats.statTW, influencerId, stats.twOriginalData));
  promises.push(submitInfluencerStatFb(stats.statFB, influencerId, stats.fbOriginalData));
  promises.push(submitInfluencerStatYt(stats.statYT, influencerId, stats.ytOriginalData));
  return Promise.all(promises)
  .then(() => {
    return influencerId;
  })
  .catch(logger.handleError)
}

const formatStatObject = (stat, id, orig) => {
  let statObjCopy = Object.assign({}, stat);
  const numericFields = [
    'influencerId',
    'pinterestFollowers',
    'linkedInFollowers',
    'websiteFollowers',
    'unsubscribed',
    'linkedInFollowersUpdatedTime',
    'pinterestFollowersUpdatedTime',
    'websiteFollowersUpdatedTime',
    'influencerRelationshipStatusId',
    'followers',
    'pageLikes',
    'subscribers'
  ];

  statObjCopy.influencerId = id;
  format.removeEmptyObjVals(statObjCopy);
  _.forEach(statObjCopy, (value, key) => {
    if (value !== '') return;
    if (numericFields.includes(key)){
      if (orig[key] !== ''){
        statObjCopy[key] = 0;
      } else {
        delete statObjCopy[key];
      }
    }
  })
  statObjCopy = format.escapeJson(statObjCopy);
  return statObjCopy;
}

const submitInfluencerStatIg = (stat, id, orig) => {
  let stateIgCopy = formatStatObject(stat, id, orig);

  if (orig.followers !== stat.followers) {
    stateIgCopy.followersLastUpdated = format.getCurrentTimestamp();
  }

  if (!orig.influencerId) {
    return API.graphql(graphqlOperation(mutations.createInfluencerStatIg, { createInfluencerStatIGInput: stateIgCopy }));
  } else {
    return API.graphql(graphqlOperation(mutations.updateInfluencerStatIg, { updateInfluencerStatIGInput: stateIgCopy }));
  }
}

const submitInfluencerStatTw = (stat, id, orig) => {
  let stateTwCopy = formatStatObject(stat, id, orig);

  if (orig.followers !== stat.followers) {
    stateTwCopy.followersLastUpdated = format.getCurrentTimestamp();
  }

  if (!orig.influencerId) {
    return API.graphql(graphqlOperation(mutations.createInfluencerStatTw, { createInfluencerStatTWInput: stateTwCopy }));
  } else {
    return API.graphql(graphqlOperation(mutations.updateInfluencerStatTw, { updateInfluencerStatTWInput: stateTwCopy }));
  }
}

const submitInfluencerStatFb = (stat, id, orig) => {
  let stateFbCopy = formatStatObject(stat, id, orig);

  if (orig.pageLikes !== stat.pageLikes) {
    stateFbCopy.pageLikesLastUpdated = format.getCurrentTimestamp();
  }

  if (!orig.influencerId) {
    return API.graphql(graphqlOperation(mutations.createInfluencerStatFb, { createInfluencerStatFBInput: stateFbCopy }));
  } else {
    return API.graphql(graphqlOperation(mutations.updateInfluencerStatFb, { updateInfluencerStatFBInput: stateFbCopy }));
  }
}

const submitInfluencerStatYt = (stat, id, orig) => {
  let stateYtCopy = formatStatObject(stat, id, orig);

  if (orig.subscribers !== stat.subscribers) {
    stateYtCopy.subscribersLastUpdated = format.getCurrentTimestamp();
  }

  if (!orig.influencerId) {
    return API.graphql(graphqlOperation(mutations.createInfluencerStatYt, { createInfluencerStatYTInput: stateYtCopy }));
  } else {
    return API.graphql(graphqlOperation(mutations.updateInfluencerStatYt, { updateInfluencerStatYTInput: stateYtCopy }));
  }
}
