import React, {
  Component,
  Fragment
} from 'react'
import {
  API,
  graphqlOperation
} from 'aws-amplify'
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Tab,
  Table,
  Tabs
} from 'react-bootstrap'

import _ from 'lodash'
import makeComponentTrashable from 'trashable-react';

import { Link } from 'react-router-dom'
import { renderTimeStamp, numToYesNo, truthyToYesNo, renderExternalUrl, undoEscapesJson, renderDateStamp } from '../../utils/format'
import ParticularRow from '../particulars/ParticularRow'
import RelationshipStatusSelect from '../common/RelationshipStatusSelect'

import Attachments, { AttachmentType, AttachmentMode } from '../common/Attachments'
import withPermissions from '../../security/withPermissions'

//import { StatIG, StatTW, StatFB, StatYT, StatLI, StatPI, StatWB, StatSC, StatTM } from '../common/SocialStats'
import { StatIG, StatTW, StatFB, StatYT, StatLI, StatPI, StatWB,StatSC } from '../common/SocialStats'

import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import uuid from 'uuid/v1'

import CommunicationModal from '../../components/common/CommunicationModal'
import Bottleneck from 'bottleneck'
import AddToListModal from '../../components/common/AddToListModal'
import CustomTagsModal from '../common/CustomTagsModal'
import SurveyDateModal from '../common/SurveyDateModal'

import SocialPosts from '../common/SocialPosts'

import Logger from '../../utils/Logger'
const logger = new Logger("InfluencerDetails.js");

const LabeledField = ({ label, data }) =>
  data ? <p><strong>{label}:</strong> {data}</p> : null;

const LabeledCard = ({ label, data }) =>
  data ? <Card className="mt-4">
    <Card.Body>
      <Card.Title>{label}</Card.Title>
      {data}
    </Card.Body></Card> : null;


class InfluencerDetails extends Component {
  gqlLimiter = new Bottleneck({ minTime: 50, maxConcurrent: 10 })
  graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

  constructor(props) {
    super(props);
    this.state = {
      loadingInfluencer: true,
      loadingAttachments: true,
      loadingCampaigns: true,
      loadingCommunications: true,
      loadingEmailAddresses: true,
      loadingLists: true,
      loadingPlatforms: true,
      loadingProfilePics: true,
      loadinginfluencerAnswers: true,
      questionAnswersMap: [],
      allAnswers: [],
      newResponse: {},
      showCommunicationModal: false,
      showEditCommunicationModal: false,
      showAddToListModal: false,
      showSurveyDateModal: false
    }
  }

  handleBack = () => {
    this.props.history.push("/influencers", { from: 'goBack' });
  };

  componentDidMount = () => {
    const { id } = this.props.match.params
    if (id) {
      let influencerPromise = this.graphqlOp(graphqlOperation(queries.getInfluencer, { influencerId: id }))
        .then(response => this.setState({ loadingInfluencer: false, influencer: undoEscapesJson(response.data.getInfluencer) }))
        .catch(logger.handleError)
      this.graphqlOp(graphqlOperation(queries.listAttachmentsByInfluencerId, { influencerId: id }))
        .then(response => this.setState({ loadingAttachments: false, attachments: response.data.listAttachmentsByInfluencerId }))
        .catch(logger.handleError)
      this.graphqlOp(graphqlOperation(queries.listInfluencerCampaignDetailsByCampaignInfluencerId, { influencerId: id }))
        .then(response => {
          this.setState({
            loadingCampaigns: false, campaigns: response.data.listInfluencerCampaignDetailsByCampaignInfluencerId
          })
        })
        .catch(logger.handleError)
      this.graphqlOp(graphqlOperation(queries.listCommunicationsByInfluencerId, { influencerId: id }))
        .then(response => this.setState({ loadingCommunications: false, communications: response.data.listCommunicationsByInfluencerId }))
        .catch(logger.handleError)
      this.graphqlOp(graphqlOperation(queries.listInfluencerListsByInfluencerId, { influencerId: id }))
        .then(response => this.setState({ loadingLists: false, lists: response.data.listInfluencerListsByInfluencerId }))
        .catch(logger.handleError)
      this.graphqlOp(graphqlOperation(queries.listInfluencerProfilePicsByInfluencerId, { influencerId: id }))
        .then(response => this.setState({ loadingProfilePics: false, profilePics: response.data.listInfluencerProfilePicsByInfluencerId }))
        .catch(logger.handleError)

      let questionsPromise = this.graphqlOp(graphqlOperation(queries.listTags))
        .then(response => this.setState({ questions: response.data.listTags }))
        .catch(logger.handleError)
      let answersPromise = this.graphqlOp(graphqlOperation(queries.listTagValues))
        .then(response => this.setState({ allAnswers: response.data.listTagValues }))
        .catch(logger.handleError)
      let influencerAnswersPromise = this.graphqlOp(graphqlOperation(queries.listInfluencerTagValuesByInfluencerId, { influencerId: parseInt(id) }))
        .then(response => this.setState({ influencerTagValues: response.data.listInfluencerTagValuesByInfluencerId }))
        .catch(logger.handleError)

      Promise.all([influencerPromise, questionsPromise, answersPromise, influencerAnswersPromise])
        .then(() => {
          this.createQuestionAnswerMap(this.state.questions, this.state.allAnswers, this.state.influencerTagValues);
          this.setState({ loadinginfluencerAnswers: false });
        })
        .catch(logger.handleError);

      let statIGPromise = this.graphqlOp(graphqlOperation(queries.getInfluencerStatIg, { influencerId: id }))
        .then(response => this.setState({ statIG: undoEscapesJson(response.data.getInfluencerStatIG) }))
        .catch(logger.handleError);
      let statTWPromise = this.graphqlOp(graphqlOperation(queries.getInfluencerStatTw, { influencerId: id }))
        .then(response => this.setState({ statTW: undoEscapesJson(response.data.getInfluencerStatTW) }))
        .catch(logger.handleError);
      let statFBPromise = this.graphqlOp(graphqlOperation(queries.getInfluencerStatFb, { influencerId: id }))
        .then(response => this.setState({ statFB: undoEscapesJson(response.data.getInfluencerStatFB) }))
        .catch(logger.handleError);
      let statYTPromise = this.graphqlOp(graphqlOperation(queries.getInfluencerStatYt, { influencerId: id }))
        .then(response => this.setState({ statYT: undoEscapesJson(response.data.getInfluencerStatYT) }))
        .catch(logger.handleError);

      Promise.all([influencerPromise, statIGPromise, statTWPromise, statFBPromise, statYTPromise])
        .then(() => this.setState({ loadingPlatforms: false }));
    }
  }

  attachmentPermissions = function () {
    let perms = [];
    if (this.props.permissions.can("deleteAttachments"))
      perms.push(AttachmentMode.AllowDelete);
    if (this.props.permissions.can("downloadAttachments"))
      perms.push(AttachmentMode.AllowDownload);
    if (this.props.permissions.can("createAttachments"))
      perms.push(AttachmentMode.AllowUpload);

    return perms;
  }

  renderInfluencerProfile = data => {
    if (!data) return
    return (
      <Row>
        <Col>
          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Name</Card.Title>
              <LabeledField label="Prefix" data={data.prefix} />
              <LabeledField label="First Name" data={data.firstName} />
              <LabeledField label="Last Name" data={data.lastName} />
              <LabeledField label="Suffix" data={data.suffix} />
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Address</Card.Title>
              <LabeledField label="Street" data={data.addressLine1} />
              <LabeledField label="City" data={data.city} />
              <LabeledField label="State" data={data.state} />
              <LabeledField label="ZIP" data={data.zip} />
              <LabeledField label="Country" data={data.country} />
            </Card.Body>
          </Card>
          {/* <CustomTagsModal
            show={this.state.showCustomTagsModal}
            influencer={this.state.influencer}
            onHide={customTagsModalClose}
          /> */}
          <Card className="mt-4">
            <Card.Body>
            <Card.Title>Tags</Card.Title>
              {data.customTags}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Contact</Card.Title>
              <LabeledField label="Unsubscribed?" data={numToYesNo(data.unsubscribed)} />
              <LabeledField label="Phone" data={data.phone} />
              <LabeledField label="Blog URL" data={renderExternalUrl(data.blogURL)} />
              <LabeledField label="Website URL" data={renderExternalUrl(data.websiteURL)} />
            </Card.Body>
          </Card>
          <RelationshipStatusSelect value={data.influencerRelationshipStatusId} />
          <LabeledCard label="Bio" data={data.bio} />
          <LabeledCard label="Important Notes" data={data.notes} />
        </Col>
      </Row>
    )
  }

  renderInfluencerCampaigns = data => {
    if (!data) return
    return (
      <Row>
        <Col>
          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Campaigns</Card.Title>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th></th>
                    <th>Campaign Name</th>
                    <th>Job Number</th>
                    <th>Fee Paid</th>
                    <th>From Dovetale?</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(item => (
                    <tr key={uuid()}>
                      <td>
                        {this.props.permissions.can("editCampaigns") && <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={
                            () => { window.confirm('Are you sure you wish to remove this influencer from the campaign? Note: This will not remove any posts.') && this.removeFromCampaign(item) }
                          }>
                          Remove
                        </Button>}
                        <Link to={"/campaigns/details/" + item.campaignId}>
                          <Button size="sm" variant="outline-secondary">Details</Button>
                        </Link>
                      </td>
                      <td>{item.campaignName}</td>
                      <td>{item.jobNumber}</td>
                      <td>{item.feePaid}</td>
                      <td>{truthyToYesNo(item.dovetaleId)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  showEditCommunicationModal = (id) => {
    this.setState({ showEditCommunicationModal: id });
  }

  deleteCommunication = (communication, influencerId) => {
    logger.handleTrace('Deleting communication ' + communication.communicationId)
    this.graphqlOp(graphqlOperation(mutations.deleteCommunication, { communicationId: communication.communicationId }))
      .catch(logger.handleError)

    this.graphqlOp(graphqlOperation(queries.listCommunicationsByInfluencerId, { influencerId: influencerId }))
      .then(response => this.setState({ showEditCommunicationModal: false, showCommunicationModal: false, loadingCommunications: false, communications: response.data.listCommunicationsByInfluencerId }))
      .catch(logger.handleError)

  }

  renderInfluencerCommunications = data => {
    let influencerId = null

    if (data.influencer) {
      influencerId = data.influencer.influencerId
    } else if (data.communications[0]) {
      influencerId = data.communications[0].influencerId
    }

    let communicationModalClose = () => {
      const id = influencerId
      if (id) {
        this.graphqlOp(graphqlOperation(queries.listCommunicationsByInfluencerId, { influencerId: id }))
          .then(response => this.setState({ showEditCommunicationModal: false, showCommunicationModal: false, loadingCommunications: false, communications: response.data.listCommunicationsByInfluencerId }))
          .catch(logger.handleError)
      }
      this.setState({ showCommunicationModal: false, showEditCommunicationModal: false })
    };

    if (!data) return
    return (
      <Row>
        <Col>
          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Communications {
                this.props.permissions.can("createCommunications") &&
                <Fragment>
                  <Button variant="outline-primary" onClick={() => this.setState({ showCommunicationModal: true })}>Create Communication</Button>
                  <CommunicationModal
                    show={this.state.showCommunicationModal}
                    influencerid={influencerId}
                    onHide={communicationModalClose} />
                </Fragment>
              }
              </Card.Title>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th></th>
                    <th>Subject</th>
                    <th>Source</th>
                    <th>Opened</th>
                    <th>Opened Time</th>
                    <th>Sent</th>
                    <th>Notes</th>
                    <th>Campaign</th>
                  </tr>
                </thead>
                <tbody>
                  {_.orderBy(data.communications, ['sentTime'], ['desc']).map(item => (
                    <tr key={uuid()}>
                      <td>
                        {this.props.permissions.can("editCommunications") &&
                          <Button
                            size="sm"
                            variant="outline-secondary"
                            onClick={() => this.showEditCommunicationModal(item.communicationId)}
                          >Edit</Button>
                        }
                        {this.props.permissions.can("deleteCommunications") &&
                          <Button
                            size="sm"
                            variant="outline-danger"
                            onClick={
                              () => { window.confirm('Are you sure you wish to delete this communication?') && this.deleteCommunication(item, influencerId) }
                            }
                          >Delete</Button>
                        }
                        <CommunicationModal
                          show={this.state.showEditCommunicationModal === item.communicationId}
                          influencerid={influencerId}
                          onHide={communicationModalClose}
                          communication={item} />
                      </td>
                      <td>{item.subject}</td>
                      <td>{this.renderCommunicationSource(item.communicationSourceId)}</td>
                      <td>{numToYesNo(item.opened)}</td>
                      <td>{renderTimeStamp(item.openedTime)}</td>
                      <td>{renderTimeStamp(item.sentTime)}</td>
                      <td>{item.notes}</td>
                      <td>{item.campaignName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  renderInfluencerEmailAddresses = data => {
    if (!data) return
    return (
      <Row>
        <Col>
          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Email Addresses</Card.Title>
              <LabeledField label="Primary Email" data={data.emailAddress1} />
              <LabeledField label="Secondary Email" data={data.emailAddress2} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  renderInfluencerPlatforms = data => {
    if (!data) return
    return (
      <Fragment>
        <Row>
          <Col>
            <StatIG useCard={true} data={data.statIG} />
          </Col>
          <Col>
            <StatTW useCard={true} data={data.statTW} />
          </Col>
        </Row>
        <Row>
          <Col>
            <StatFB useCard={true} data={data.statFB} />
          </Col>
          <Col>
            <StatYT useCard={true} data={data.statYT} />
          </Col>
        </Row>
        <Row>
          <Col>
            <StatPI data={data.influencer} />
          </Col>
          <Col>
            <StatLI data={data.influencer} />
          </Col>
        </Row>
        <Row>
          <Col>
            <StatSC data={data.influencer} />
          </Col>
          {/* <Col>
            <StatTM data={data.influencer} />
          </Col> */}
        </Row> 
        <Row>
          <Col>
            <StatWB data={data.influencer} />
          </Col>
        </Row>
      </Fragment>
    )
  }

  renderInfluencerAttachments = data => {
    if (!data || !this.props.permissions.can("viewAttachments")) return
    return (
      <Row>
        <Col>
          <Card className="mt-4">
            <Attachments
              title="Attachments"
              attachmentType={AttachmentType.Files}
              attachmentModes={this.attachmentPermissions()}
              onCreate={(file) => {
                file.influencerId = this.state.influencer.influencerId;
                return this.graphqlOp(graphqlOperation(mutations.createAttachment, { createAttachmentInput: file }))
                  .then(data => data.data.createAttachment)
                  .catch(logger.handleError)
              }}
              onDelete={(file) => {
                this.graphqlOp(graphqlOperation(mutations.deleteAttachment, { attachmentId: file.attachmentId }))
              }}
              files={data}
            />
          </Card>
        </Col>
      </Row>
    )
  }

  createQuestionAnswerMap = (questions, allAnswers, allInfluencerTagValues) => {
    let influencerTagValues = allInfluencerTagValues.filter(itv => itv.influencerId === this.state.influencer.influencerId).map(obj => obj.tagValueId);
    let influencerAnswers = allAnswers.filter(a => influencerTagValues.includes(a.tagValueId))
    let questionAnswersMap = [];

    questions.forEach((question) => {
      let answers = influencerAnswers.filter(a => a.tagId === question.tagId);
      let obj = {
        question,
        answers
      }

      questionAnswersMap.push(obj);
    });

    this.setState({ questionAnswersMap });
  }

  renderInfluencerParticulars = (qaMap) => {
    let data = this.state.influencer || {};

    let customTagsModalClose = () => { this.setState({ showCustomTagsModal: false }) }
    let surveyDateModalClose = () => { this.setState({ showSurveyDateModal: false }) }

    return (
      <Row>
        <Col>
          <Row>
            <Col>
              <CustomTagsModal
                show={this.state.showCustomTagsModal}
                influencer={this.state.influencer}
                onHide={customTagsModalClose}
              />

              <Card className="mt-4">
                <Card.Body>
                  <Card.Title>
                    Tags<Button className="ml-2" variant="outline-primary" onClick={() => this.setState({ showCustomTagsModal: true })}>Edit</Button>
                  </Card.Title>
                  {data.customTags}
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <SurveyDateModal
                show={this.state.showSurveyDateModal}
                influencer={this.state.influencer}
                onChange={newSurveyDate => this.setState((state) => { let newInf = { ...state.influencer }; newInf.surveyDate = newSurveyDate; return { influencer: newInf } })}
                onHide={surveyDateModalClose}
              />

              <Card className="mt-4">
                <Card.Body>
                  <Card.Title>
                    Survey Date<Button className="ml-2" variant="outline-primary" onClick={() => this.setState({ showSurveyDateModal: true })}>Edit</Button>
                  </Card.Title>
                  {data.surveyDate ? renderDateStamp(data.surveyDate) : null}
                </Card.Body>
              </Card>
            </Col>
          </Row>




          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Particulars</Card.Title>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th width="40%">Question</th>
                    <th width="35%">Answers</th>
                    <th width="25%">Add Response</th>
                  </tr>
                </thead>
                <tbody>
                  {qaMap.map((qa) => (
                    <ParticularRow
                      key={uuid()}
                      questionData={qa}
                      allAnswers={this.state.allAnswers}
                      influencer={this.state.influencer}
                      updateParentQuestionMapCB={this.updateQAMap}
                    />
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  updateQAMap = (qaWithRemovedAnswer) => {
    let stateMapCopy = this.state.questionAnswersMap.slice();
    let indexToReplace = stateMapCopy.indexOf(stateMapCopy.find((qa) => qa.question.tagId === qaWithRemovedAnswer.question.tagId));

    stateMapCopy[indexToReplace] = qaWithRemovedAnswer;
    this.setState({ questionAnswersMap: stateMapCopy });
  }

  removeFromList = list => {
    this.graphqlOp(graphqlOperation(mutations.deleteInfluencerList, { influencerId: this.state.influencer.influencerId, listId: list.listId }))
      .then(response => {
        this.graphqlOp(graphqlOperation(queries.listInfluencerListsByInfluencerId, { influencerId: this.state.influencer.influencerId }))
          .then(response => this.setState({ lists: response.data.listInfluencerListsByInfluencerId }))
          .catch(logger.handleError)
      })
      .catch(logger.handleError)
  }

  removeFromCampaign = campaign => {
    this.graphqlOp(graphqlOperation(mutations.deleteInfluencerCampaign, { influencerId: this.state.influencer.influencerId, campaignId: campaign.campaignId }))
      .then(response => {
        this.graphqlOp(graphqlOperation(queries.listInfluencerCampaignDetailsByCampaignInfluencerId, { influencerId: this.state.influencer.influencerId }))
          .then(response => this.setState({ campaigns: response.data.listInfluencerCampaignDetailsByCampaignInfluencerId }))
          .catch(logger.handleError)
      })
      .catch(logger.handleError)
  }

  renderInfluencerLists = data => {

    let addToListModalClose = () => {
      this.graphqlOp(graphqlOperation(queries.listInfluencerListsByInfluencerId, { influencerId: this.state.influencer.influencerId }))
        .then(
          response => this.setState({
            loadingLists: false, lists: response.data.listInfluencerListsByInfluencerId
          }))
        .catch(logger.handleError)
      this.setState({ showAddToListModal: false })
    }

    if (!data) return
    return (
      <Row>
        <Col>
          {this.props.permissions.can("editLists")
            && <Button variant="outline-primary" onClick={() => this.setState({ showAddToListModal: true })}>Add to List</Button>}
          <AddToListModal
            show={this.state.showAddToListModal}
            influencers={[this.state.influencer]}
            onHide={addToListModalClose} />
          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Lists</Card.Title>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>From Dovetale?</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(item => (
                    <tr key={uuid()}>
                      <td>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={
                            () => { window.confirm('Are you sure you wish to remove this influencer from the list?') && this.removeFromList(item) }
                          }>
                          Remove
                        </Button>
                        <Link to={"/lists/details/" + item.listId}>
                          <Button size="sm" variant="outline-secondary">Details</Button>
                        </Link>
                      </td>
                      <td>{item.name}</td>
                      <td>{truthyToYesNo(item.dovetaleId)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  renderInfluencerProfilePics = data => {
    if (!data || !this.props.permissions.can("viewAttachments")) return
    return (
      <Row>
        <Col>
          <Card className="mt-4">
            <Attachments
              title="Profile Pics"
              attachmentType={AttachmentType.Images}
              attachmentModes={this.attachmentPermissions()}
              onCreate={(file) => {
                file.influencerId = this.state.influencer.influencerId;
                return this.graphqlOp(graphqlOperation(mutations.createInfluencerProfilePic, { createInfluencerProfilePicInput: file }))
                  .then(data => data.data.createInfluencerProfilePic)
                  .catch(logger.handleError)
              }}
              onDelete={(file) => {
                this.graphqlOp(graphqlOperation(mutations.deleteInfluencerProfilePic, { influencerProfilePicId: file.influencerProfilePicId }))
              }}
              files={data}
            />
          </Card>
        </Col>
      </Row>
    )
  }

  renderPlatformPosts = () => {
    const { campaigns, influencer } = this.state
    if (!campaigns) return

    let campaignIds = []
    campaigns.forEach((campaign) => {
      campaignIds.push(campaign.campaignId)
    })

    return (
      <Fragment>
        <SocialPosts
          influencerid={influencer.influencerId}
          displaytitle={false}
        />
      </Fragment>
    )
  }

  renderEditButton = item =>
    <Link to={`/influencers/edit/${item}`} className="btn btn-secondary">Edit</Link>

  renderCommunicationSource = item => {
    switch (item) {
      case 1:
        return 'Reply'
      case 2:
        return 'Constant Contact'
      case 3:
        return 'Manual'
      default:
        return ''
    }
  }

  renderLoading = message => {
    return (
      <Row>
        <Col>
          <Alert variant="info" className="mt-4">Loading {message}...</Alert>
        </Col>
      </Row>
    )
  }

  renderInfluencerIdentifier = () => {
    const {
      influencer,
      statIG,
      statFB,
      statTW,
      statYT
    } = this.state

    let identifier = null
    if (influencer.firstName || influencer.lastName) {
      identifier = (influencer.firstName) ? influencer.firstName : ''
      identifier += ' ' + ((influencer.lastName) ? influencer.lastName : '')
    } else if (statFB) {
      identifier = statFB.username
    } else if (statTW) {
      identifier = statTW.username
    } else if (statIG) {
      identifier = statIG.handle
    } else if (statYT) {
      identifier = statYT.username
    } else if (influencer.pinterestHandle) {
      identifier = influencer.pinterestHandle
    } else if (influencer.snapchatHandle) {
      identifier = influencer.snapchatHandle
    } else if (influencer.tumblrHandle) {
      identifier = influencer.tumblrHandle
    } else if (influencer.emailAddress1) {
      identifier = influencer.emailAddress1
    } else if (influencer.emailAddress2) {
      identifier = influencer.emailAddress2
    }

    const creationStamp = renderTimeStamp(influencer.creationTime)

    return (
      <Fragment>
        {identifier && <h3>{identifier}</h3>}
        <i>{creationStamp && "Joined on " + creationStamp} {influencer.creationSource && " via " + influencer.creationSource}</i>
      </Fragment>
    )
  }

  render() {
    const {
      loadingInfluencer,
      loadingAttachments,
      loadingCampaigns,
      loadingCommunications,
      loadingLists,
      loadingPlatforms,
      loadingProfilePics,
      //loadinginfluencerAnswers,
      attachments,
      campaigns,
      communications,
      influencer,
      lists,
      profilePics,
      statIG,
      statFB,
      statTW,
      statYT,
      //questionAnswersMap
    } = this.state;

    return (
      <Container fluid>
        <Row>
          <Col>
          <div className="d-flex align-items-center">
          <button className="btn btn-link p-0 mr-2" onClick={this.handleBack} style={{ fontSize: '2.5rem' }}>
            &#8592; {/* Unicode left arrow */}
          </button>
            <h1>Influencer Details {this.props.permissions.can("editInfluencers") && this.renderEditButton(this.props.match.params.id)} </h1>
            </div>
            {!loadingInfluencer ? this.renderInfluencerIdentifier() : ''}
          </Col>
        </Row>
        <Tabs defaultActiveKey="profile">
          <Tab eventKey="profile" title="Profile">
            <Fragment>
              {loadingInfluencer ? this.renderLoading('Influencer') : this.renderInfluencerProfile(influencer)}
            </Fragment>
            <Fragment>
              {loadingInfluencer ? this.renderLoading('Email Addresses') : this.renderInfluencerEmailAddresses(influencer)}
            </Fragment>
          </Tab>
          <Tab eventKey="platforms" title="Platforms">
            <Fragment>
              {loadingProfilePics ? this.renderLoading('Profile Pics') : this.renderInfluencerProfilePics(profilePics)}
            </Fragment>
            <Fragment>
              {loadingPlatforms ? this.renderLoading('Platforms') : this.renderInfluencerPlatforms({ statIG, statFB, statTW, statYT, influencer })}
            </Fragment>
          </Tab>
          <Tab eventKey="campaigns" title="Campaigns">
            <Fragment>
              {loadingCampaigns ? this.renderLoading('Campaigns') : this.renderInfluencerCampaigns(campaigns)}
            </Fragment>
            <Fragment>
              {loadingInfluencer || loadingCampaigns ? this.renderLoading('Posts') : this.renderPlatformPosts()}
            </Fragment>
          </Tab>
          <Tab eventKey="lists" title="Lists">
            <Fragment>
              {loadingLists ? this.renderLoading('Lists') : this.renderInfluencerLists(lists)}
            </Fragment>
          </Tab>
          <Tab eventKey="attachments" title="Attachments">
            <Fragment>
              {loadingAttachments ? this.renderLoading('Attachments') : this.renderInfluencerAttachments(attachments)}
            </Fragment>
          </Tab>
          <Tab eventKey="communications" title="Communications">
            <Fragment>
              {loadingCommunications ? this.renderLoading('Communications') : this.renderInfluencerCommunications({ communications, influencer })}
            </Fragment>
          </Tab>
          {/* <Tab eventKey="particulars" title="Particulars">
            <Fragment>
              {loadinginfluencerAnswers ? this.renderLoading('Particulars') : this.renderInfluencerParticulars(questionAnswersMap)}
            </Fragment>
          </Tab> */}
        </Tabs>
      </Container>
    );
  }
}

export default makeComponentTrashable(withPermissions(InfluencerDetails))
