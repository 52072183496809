import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import UserList from '../components/user/UserList';

import Logger from '../utils/Logger'

const logger = new Logger("Users.js");

class IndexUsers extends Component {
  constructor(props) {
    super(props)
    logger.handleInfo("User arrived at Users page")
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Fragment>
              <Container fluid>
                <Row>
                  <Col>
                    <h1>Users <Link to="/users/create" className="btn btn-secondary">Create</Link></h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <UserList />
                  </Col>
                </Row>
              </Container>
            </Fragment>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default IndexUsers