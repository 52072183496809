import React from 'react'
import ConfirmDeleteModal from '../common/ConfirmDeleteModal';
import { API, graphqlOperation, Storage } from 'aws-amplify'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { Redirect } from 'react-router-dom';
import makeComponentTrashable from 'trashable-react';
import Bottleneck from 'bottleneck'
import Logger from '../../utils/Logger'
const logger = new Logger("DeleteInfluencer.js");
  

class DeleteInfluencer extends React.Component {
    gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

    constructor(props) {
        super(props);
        this.state = {
          loadingInfluencer: true,
          cancel: false,
          confirm: false
        }
    }

    componentDidMount() {
        console.log("test")
        const { id } = this.props.match.params;
        if (id) {
            this.graphqlOp(graphqlOperation(queries.getInfluencer, { influencerId: id }))
                .then(response => this.setState({ loadingInfluencer: false, influencer: response.data.getInfluencer }))
                .catch(logger.handleError);
        }
    }

    deleteAttachment = async (s3Id, attachmentId) => {
        await Storage.remove(s3Id);
        await this.graphqlOp(graphqlOperation(mutations.deleteAttachment, { attachmentId }));
    }

    deleteInfluencerAttachments = async (id) => {
        const attachmentResponse = await this.graphqlOp(graphqlOperation(queries.listAttachmentsByInfluencerId, { influencerId: id }));
        const deletions = attachmentResponse.data.listAttachmentsByInfluencerId.map( async attachment => this.deleteAttachment(attachment.s3Id, attachment.attachmentId));
        return Promise.all(deletions);
    }

    deleteProfilePic = async (s3Id, influencerProfilePicId) => {
        await Storage.remove(s3Id);
        await this.graphqlOp(graphqlOperation(mutations.deleteInfluencerProfilePic, { influencerProfilePicId }));
    }

    deleteInfluencerProfilePics = async (id) => {
        const picResponse = await this.graphqlOp(graphqlOperation(queries.listInfluencerProfilePicsByInfluencerId, { influencerId: id }));
        const deletions = picResponse.data.listInfluencerProfilePicsByInfluencerId.map( async pic => this.deleteProfilePic(pic.s3Id, pic.influencerProfilePicId));
        return Promise.all(deletions);
    }

    deleteInfluencer(id) {
        Promise.all([this.deleteInfluencerAttachments(id), this.deleteInfluencerProfilePics(id)])
            .then(() => this.graphqlOp(graphqlOperation(mutations.deleteInfluencer, { influencerId: id })))
            .then(() => {
                this.setState({confirm: true})
                this.props.history.push("/influencers", { from: 'goBack' })
                }
            )
            .catch(logger.handleError);
        
    }
    
    render() {
        return this.state.loadingInfluencer ||
            <React.Fragment>
                {this.state.cancel && <Redirect to="/influencers" />}
                {this.state.confirm && <Redirect to="/influencers/r" />}
                <ConfirmDeleteModal
                    confirmmessage={"Are you sure you want to delete the influencer " + (this.state.influencer.firstName || "with id " + this.state.influencer.influencerId) + "?"}
                    cancelaction={() => this.setState({cancel: true})}
                    confirmaction={() => this.deleteInfluencer(this.state.influencer.influencerId)}
                />
            </React.Fragment>;
    }
}

export default makeComponentTrashable(DeleteInfluencer)