import _ from 'lodash'
export const transformFieldArray = (fields) => {
    const record = {};
    fields.forEach(field => {
        record[field] = '';
    });   
    return record;
}

export const replaceNullFieldValues = (object) => {
    return _.forOwn(object, function(value, key) {
        object[key] = _.isNil(value) ? "" : value;
    })
}