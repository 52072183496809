import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { API, graphqlOperation } from 'aws-amplify'
import TableActions from '../components/common/TableActions'
import uuid from 'uuid/v1'
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
  Table,
  Form
} from 'react-bootstrap'
import * as queries from '../graphql/queries'
import ReportModal from '../components/common/ReportModal'
import BulkInfluencerImportModal from '../components/common/BulkInfluencerImportModal'
import AddToListsModal from '../components/common/AddToListModal'
import withPermissions from '../security/withPermissions'
import SearchContainer from '../components/search/SearchContainer'
import { transform } from '../components/common/StringTransform'
import { numToYesNo, renderExternalUrl, undoEscapesJson, escapeJson } from '../utils/format'
import Paging from '../components/common/Paging'
import CommunicationModal from '../components/common/CommunicationModal'
import { Link } from 'react-router-dom'
import Logger from '../utils/Logger'
import Bottleneck from 'bottleneck'
import makeComponentTrashable from 'trashable-react';

const logger = new Logger("Influencers.js");

class IndexInfluencers extends Component {
  gqlLimiter = new Bottleneck({ minTime: 50, maxConcurrent: 10 })
  graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))
  constructor(props) {
    logger.handleInfo("User arrived at Influencers page")
    super(props)
    this.state = {
      loading: true,
      selectAllPages: [], // For each page, is select all selected?
      showReportModal: false,
      showUploadParticularsModal: false,
      showAddToListsModal: false,
      showImportModal: false,
      showCommunicationModal: false,
      data: [],
      pages: [],
      pagesLoadedData: [], // Is the page loaded? Here's the data
      defaultLimit: 100,
      currentPage: 0
    }
    if (this.props.location.state && this.props.location.state.from === 'goBack') {
      
    } else {
      sessionStorage.removeItem('state');
      sessionStorage.removeItem('searchFilter');
    }
  }

  componentDidMount() {
    // Check if the component should fetch data based on the current state
    if (this.state.loading) {
      this.updateList({});
    }
    const searchFilter = JSON.parse(sessionStorage.getItem('searchFilter'));

    if (searchFilter) {
      // Reinitialize search with stored filter
      this.updateList(searchFilter);
    }
  }
  _currentRequestId = uuid();

  checkIfCurrentRequestId = (id) => this._currentRequestId === id

  generateNewRequestId = () => this._currentRequestId = uuid()

  getPage = async (reqId, filter = {}, page = 0) => {
    const response = await this.getSourceData(filter, page)
    if (!this.checkIfCurrentRequestId(reqId)) return []

    const data = undoEscapesJson(response.data.listInfluencers).map(item => ({ ...item, selected: false }))

    this.setState(state => {
      let copyPages = [...state.pages], copyPagesLoadedData = [...state.pagesLoadedData]
      copyPages[page] = async () => data
      copyPagesLoadedData[page] = data
      return { pages: copyPages, pagesLoadedData: copyPagesLoadedData }
    })

    return data
  }

  // Take the results count off the first influencer, or return 0
  getResultsCountTotalFromData = (listInfluencers) => (listInfluencers && listInfluencers.length && listInfluencers[0] && listInfluencers[0].resultsCount) || 0

  updateList = (filter) => {
    this.setState({ loading: true })
    const reqId = this.generateNewRequestId()

    this.getSourceData(filter)
      .then(response => {
        const data = undoEscapesJson(response.data.listInfluencers).map(item => ({ ...item, selected: false }))
        const totalPages = Math.ceil(this.getResultsCountTotalFromData(response.data.listInfluencers) / this.state.defaultLimit)
        let pages = [async () => data]
        let pagesLoadedData = [data]
        let selectAllPages = [false]
        for (let i = 1; i < totalPages; i++) {
          pages[i] = async () => await this.getPage(reqId, filter, i)
          pagesLoadedData[i] = []
          selectAllPages[i] = false
        }
        if (this.checkIfCurrentRequestId(reqId)) {
          this.setState({
            loading: false,
            data,
            pages,
            pagesLoadedData,
            selectAllPages
          })
        }
      })
  }

  getPageDataOrFetch = async (index) => {
    this.setState({ loading: true })
    const data = await this.state.pages[index]()
    this.setState({ loading: false })
    return data
  }

  updateData = async (pageNumber) => {
    let data = await this.getPageDataOrFetch(pageNumber - 1)
    if (!data) data = [];
    this.setState({
      currentPage: pageNumber - 1,
      data
    })
  }

  getSourceData = (queryFilter = {}, queryPage = 0) => {
    return this.props.registerPromise(this.graphqlOp(graphqlOperation(queries.listInfluencers, { page: queryPage, limit: this.state.defaultLimit, filter: escapeJson(queryFilter) })))
      .then(response => response)
      .catch(logger.handleError)
  }

  handleCheckedChange = (event) => {
    const id = parseInt(event.target.id);

    this.setState(state => {
      const newData = state.data.map(item => {
        if (item.influencerId === id)
          item.selected = !item.selected;

        return item;
      });

      return { data: newData }
    });
  }

  handleSelectAllCheckedChange = () => {
    const allData = this.state.data
    this.setState(state => {
      const newSelectValue = !state.selectAllPages[state.currentPage]
      let copySelectAllPages = [...state.selectAllPages]
      copySelectAllPages[state.currentPage] = newSelectValue
      const newData = allData.map(item => ({ ...item, selected: newSelectValue }))
      let copyPages = [...state.pages]
      copyPages[state.currentPage] = async () => newData
      let copyPagesLoadedData = [...state.pagesLoadedData]
      copyPagesLoadedData[state.currentPage] = newData
      return { data: newData, selectAllPages: copySelectAllPages, pages: copyPages, pagesLoadedData: copyPagesLoadedData }
    });
  }

  renderInfluencersList = data => {
    return (
      <Table striped bordered>
        <thead>
          <tr>
            <th>
              <Form.Check
                id="0"
                type="checkbox"
                name="selected"
                checked={this.state.selectAllPages[this.state.currentPage]}
                onChange={this.handleSelectAllCheckedChange}
              />
            </th>
            <th></th>
            <th>{transform("firstName")}</th>
            <th>{transform("lastName")}</th>
            <th>{transform("websiteURL")}</th>
            <th>{transform("addressLine1")}</th>
            <th>{transform("city")}</th>
            <th>{transform("state")}</th>
            <th>{transform("emailAddress1")}</th>
            <th>{transform("bio")}</th>
            <th>{transform("customTags")}</th>
            <th>{transform("unsubscribed")}</th>
            <th>{transform("totalFollowing")}</th>
            <th>{transform("IGhandle")}</th>
            <th>{transform("IGfollowers")}</th>
            <th>{transform("FBusername")}</th>
            <th>{transform("FBpageLikes")}</th>
            <th>{transform("linkedInURL")}</th>
            <th>{transform("linkedInFollowers")}</th>
            <th>{transform("YTusername")}</th>
            <th>{transform("YTsubscribers")}</th>
            <th>{transform("pinterestHandle")}</th>
            <th>{transform("pinterestFollowers")}</th>
            <th>{transform("TWusername")}</th>
            <th>{transform("TWfollowers")}</th>
            {/* <th>{transform("tumblrHandle")}</th> */
            <th>{transform("snapchatHandle")}</th>}
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={uuid()}>
              <td>
                <Form.Check
                  id={item.influencerId}
                  type="checkbox"
                  name="selected"
                  checked={item.selected}
                  onChange={this.handleCheckedChange}
                />
              </td>
              <td>
                <TableActions
                  itemId={item.influencerId}
                  itemPath="influencers"
                  canView={this.props.permissions.can("viewInfluencers")}
                  canEdit={this.props.permissions.can("editInfluencers")}
                  canDelete={this.props.permissions.can("deleteInfluencers")}
                />
              </td>
              <td>{item.firstName}</td>
              <td>{item.lastName}</td>
              <td>{renderExternalUrl(item.websiteURL)}</td>
              <td>{item.addressLine1}</td>
              <td>{item.city}</td>
              <td>{item.state}</td>
              <td>{item.emailAddress1}</td>
              <td>{item.bio}</td>
              <td>{item.customTags}</td>
              <td>{numToYesNo(item.unsubscribed)}</td>
              <td>{item.totalFollowing}</td>
              <td>{item.IGhandle}</td>
              <td>{item.IGfollowers}</td>
              <td>{item.FBusername}</td>
              <td>{item.FBpageLikes}</td>
              <td>{item.linkedInURL}</td>
              <td>{item.linkedInFollowers}</td>
              <td>{item.YTusername}</td>
              <td>{item.YTsubscribers}</td>
              <td>{item.pinterestHandle}</td>
              <td>{item.pinterestFollowers}</td>
              <td>{item.TWusername}</td>
              <td>{item.TWfollowers}</td>
              {/* <td>{item.tumblrHandle}</td> */
              <td>{item.snapchatHandle}</td>}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  // Get selected data across all pages
  getSelectedData = () => _.flatten(this.state.pagesLoadedData).filter(item => item.selected);

  render() {
    let reportModalClose = () => this.setState({ showReportModal: false })
    let addToListModalClose = () => this.setState({ showAddToListsModal: false })
    let communicationModalClose = () => this.setState({ showCommunicationModal: false })


    const { loading, data } = this.state;

    return (
      <Container fluid>
          <Row>
            <Col>

              <h1>
                Influencers {this.props.permissions.can("createInfluencers") && <Link to="/influencers/dupe" className="btn btn-secondary">Create</Link>}
                {this.props.permissions.can("editInfluencers") && <Fragment>
                </Fragment>
                }
                {this.props.permissions.can("createCommunications") && <Fragment>
                  <Button variant="outline-primary" className="ml-2" onClick={() => this.setState({ showCommunicationModal: true })}>Create Communications</Button>
                  <CommunicationModal
                    show={this.state.showCommunicationModal}
                    onHide={communicationModalClose}
                    bulk="true" />
                </Fragment>}
              </h1>
            </Col>
            {this.props.permissions.can("searchInfluencers") && <Col className="float-left">
              <SearchContainer
                filterModel={"ModelInfluencersFilterInput"}
                queryFilterCallback={this.updateList}>
              </SearchContainer>
            </Col>}
          </Row>
          {loading ? "loading..." :
            <Fragment>
              <Row>
                <Col>
                  <h4>Bulk Actions <ButtonGroup>
                    {this.props.permissions.can("createInfluencers") && <Fragment>
                      <Button variant="outline-primary" onClick={() => this.setState({ showImportModal: true })}>Import</Button>
                      <BulkInfluencerImportModal
                        show={this.state.showImportModal}
                        hideModalCB={() => { this.setState({ showImportModal: false }) }}
                      />
                    </Fragment>}
                    {this.props.permissions.can("downloadInfluencers") && <Fragment>
                      <Button variant="outline-primary" onClick={() => this.setState({ showReportModal: true })}>Export</Button>
                      <ReportModal
                        show={this.state.showReportModal}
                        influencers={this.getSelectedData()}
                        onHide={reportModalClose}
                      />
                    </Fragment>}
                    {this.props.permissions.can("editLists")
                      && <Button variant="outline-primary" onClick={() => this.setState({ showAddToListsModal: true })}>Add to List</Button>}
                    <AddToListsModal
                      show={this.state.showAddToListsModal}
                      influencers={this.getSelectedData()}
                      onHide={addToListModalClose} />
                  </ButtonGroup> </h4>
                </Col>
              </Row>
              {this.renderInfluencersList(data)}
              <Row>
                <Col>
                  <Paging
                    active={this.state.currentPage + 1}
                    pageCount={this.state.pages.length}
                    pageCallback={this.updateData}>
                  </Paging>
                </Col>
              </Row>
            </Fragment>
          }
        </Container>
    )
  }
}

export default withPermissions(makeComponentTrashable(IndexInfluencers));
