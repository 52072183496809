import CloudWatchClient from '../client/CloudWatchClient'
import { getEnvName } from '../utils/Amplify'
import { Auth } from 'aws-amplify';
import _ from 'lodash'

const envName = getEnvName();
const logGroupName = "WALogging-" + getEnvName();

const logLevel = {
    ERROR: "ERROR",
    WARNING: "WARNING",
    INFO: "INFO",
    TRACE: "TRACE"
}

class LoggerUtils {
    static cloudWatchConfig = null;
    static jobQueue = [];

    static async processQueue() {
        //Keep looping and checking the log queue
        if (LoggerUtils.jobQueue.length > 0) {
            let jobsToProcess = [];
            while (LoggerUtils.jobQueue.length > 0) jobsToProcess.push(LoggerUtils.jobQueue.pop())
            await LoggerUtils.logToCloudwatch(jobsToProcess);
            LoggerUtils.processQueue();
        } else {
            setTimeout(LoggerUtils.processQueue, 1000)
        }
    }

    static async verifyCloudWatch() {
        const currCredentials = await Auth.currentUserInfo();
        const username = currCredentials.username;

        if (!LoggerUtils.cloudWatchConfig || LoggerUtils.cloudWatchConfig.username !== username) {
            const cloudWatchProvider = await CloudWatchClient.getCloudWatchProvider();

            const logStreamName = (Date.now() + "-" + username).replace(/[:* ]/g, "_");

            await cloudWatchProvider.createLogStream({
                logGroupName,
                logStreamName
            }).promise();

            LoggerUtils.cloudWatchConfig = {
                cloudWatchProvider,
                logStreamName,
                username
            }
        }
    }

    static async logToCloudwatch(logEvents) {
        await LoggerUtils.verifyCloudWatch();
        const logPutData = await LoggerUtils.cloudWatchConfig.cloudWatchProvider.putLogEvents({
            logEvents,
            logGroupName,
            logStreamName: LoggerUtils.cloudWatchConfig.logStreamName,
            ...(LoggerUtils.cloudWatchConfig.sequenceToken && {sequenceToken: LoggerUtils.cloudWatchConfig.sequenceToken})
        }).promise()

        LoggerUtils.cloudWatchConfig.sequenceToken = logPutData.nextSequenceToken;
    }

    static async logObj(fileName, content, type) {
        if (JSON.stringify(content).length > 10000) {
            console.log("hey")
            const stringified = JSON.stringify(content)
            content = stringified.substring(0, 5000) + " ... " + stringified.substring(stringified.length - 5000)
        }

        const message = {type, fileName, content}

        if (type !== logLevel.TRACE) {
            LoggerUtils.jobQueue.unshift({
                message: JSON.stringify(message), 
                timestamp: Date.now()
            });
        }

        if (envName !== "master") console.log(message);
    }
}

class Logger {
    constructor(fileName) {
        this.fileName = fileName;
    }    

    handleError = async (obj) => {
        // TODO: fix this super hacky way of ignoring the mapping template errors that occur when no result is returned
        if (_.get(obj, 'errors[0].errorType', null) === "MappingTemplate" && _.get(obj, 'errors[0].message', '').startsWith("Error invoking method 'get(java.lang.Integer)' in java.util.ArrayList")) {
            return;
        }

        if (_.get(obj, 'errors[0].errorType', null) === "ExecutionTimeout") {
            alert("Error: The database could not be reached. Please try again later or contact support if this issue continues.")
        }

        LoggerUtils.logObj(this.fileName, obj, logLevel.ERROR)
    }

    handleTrace = async (obj) => {
        LoggerUtils.logObj(this.fileName, obj, logLevel.TRACE)
    }

    handleInfo = async (obj) => {
        LoggerUtils.logObj(this.fileName, obj, logLevel.INFO)
    }

    handleWarning = async (obj) => {
        LoggerUtils.logObj(this.fileName, obj, logLevel.WARNING)
    }
}

LoggerUtils.processQueue();

export default Logger;