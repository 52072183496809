import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row
} from 'react-bootstrap'
import Bottleneck from 'bottleneck'
import {
  Redirect,
  Link
} from 'react-router-dom'
import makeComponentTrashable from 'trashable-react';

import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import Logger from '../../utils/Logger'
const logger = new Logger("EditList.js");

const Title = ({ mode }) =>
  mode === 'create' ? <h2>Create List</h2> : <h2>Edit List</h2>

const FormErrors = ({ message, show }) =>
  show ? <Alert variant="danger">{message}</Alert> : null;

const FormSuccess = ({ message, show }) =>
  show ? <Alert variant="success">{message}</Alert> : null;

class EditCampaign extends Component {

  gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      mode: 'create',
      loading: true,
      data: {
        dovetaleId: '',
        name: ''
      },
      redirect: false,
      listId: 0
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    if (id) {
      this.setState({ mode: 'edit', listId: id })
      this.graphqlOp(graphqlOperation(queries.getList, { listId: id }))
        .then(response => this.setState({ loading: false, data: response.data.getList }))
        .catch(logger.handleError)
    } else {
      this.setState({ loading: false })
    }
  }

  handleChange = event => {
    const dataCopy = JSON.parse(JSON.stringify(this.state.data))
    dataCopy[event.target.name] = event.target.value
    this.setState({ data: dataCopy })
  }

  handleSubmit = event => {
    event.preventDefault()
    let inputData = this.state.data
    inputData.dovetaleId = inputData.dovetaleId ? parseInt(inputData.dovetaleId) : 0

    if (this.state.mode === 'create') {
      delete inputData.listId
      this.graphqlOp(graphqlOperation(mutations.createList, {
        createListInput: inputData
      }))
      .then((response) => {
        this.setState({ 
          formSuccess: true,
          formSuccessMessage: 'List created!', 
          listId: response.data.createList.listId,
          redirect: true
        });
      })
      .catch(logger.handleError)
    } else {
      this.graphqlOp(graphqlOperation(mutations.updateList, {
        updateListInput: inputData
      }))
      .then(() => {
        this.setState({
          formSuccess: true, 
          formSuccessMessage: 'List updated!', 
          redirect: true
        });
      })
      .catch(logger.handleError)
    }
  }

  renderLoading = message => {
    return (
      <Row>
        <Col>
          <Alert variant="info" className="mt-4">Loading {message}...</Alert>
        </Col>
      </Row>
    )
  }

  renderCampaignForm = () => {
    return (
      <Row>
        <Col>
          <Form.Group controlId="name">
            <Form.Label>List Name</Form.Label>
            <Form.Control
              maxLength="45"
              name="name"
              value={this.state.data.name}
              onChange={this.handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
    )
  }

  render() {
    const { loading, redirect } = this.state;
    const { id } = this.props.match.params;

    if (redirect) {
      return <Redirect to={`/lists/details/${this.state.listId}`} />;
    }



    return (
      <Container fluid>
        <Form id="EditInfluencerForm" onSubmit={event => this.handleSubmit(event)}>
          <Row>
            <Col>
              <Title mode={this.state.mode} />
              <FormSuccess message={this.state.formSuccessMessage} show={this.state.formSuccess} />
              <FormErrors message={this.state.formErrorMessage} show={this.state.formError} />
            </Col>
          </Row>
          {loading ? this.renderLoading('Campaign') : this.renderCampaignForm()}
          <Row>
            <Col className="mt-4 mb-4">
                <Button variant="secondary" type="submit" onClick={this.handleSubmit}>
                  Submit
                </Button>                
                <Link to={`/lists/details/${id}`}>
                  <Button variant="outline-secondary" className="ml-2">Cancel</Button>  
                </Link>
            </Col>
          </Row>
        </Form>
      </Container>
    )
  }
}

export default makeComponentTrashable(EditCampaign)