import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { transform } from '../common/StringTransform'
import DatePicker from 'react-datepicker'
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Container,
  Row,
  Form,
  Collapse
} from 'react-bootstrap'
import * as schema from '../../graphql/schema.json'
import "react-datepicker/dist/react-datepicker.css";
import { getTimestampFromMilli } from '../../utils/format'

class SearchContainer extends Component {

  constructor(props) {
    super(props)

    // this.state = {
    //     operators: [],
    //     fieldData : [this.fieldModel()],
    //     open: false,
    // }
    let fieldData = [this.fieldModel()];  // default value
    let operators=[];
    let open=false;
    if (sessionStorage.getItem('state')) {
      try {
        const parsedData = JSON.parse(sessionStorage.getItem('state'));
        if (parsedData.fieldData) {
          fieldData = parsedData.fieldData;
          operators = parsedData.operators;
          open=true;
        }
      } catch (error) {
        console.error('Failed to parse state from sessionStorage:', error);
      }
    }
    this.state = {
      operators: operators,
      fieldData: fieldData,
      open: open,
    };

    // Emulating onChange events for each field to load operators
    fieldData.forEach((field, index) => {
      const fakeEvent = {
        target: {
          id: `fieldName-${index}`,
          value: field.fieldName
        }
      };
      this.handleFieldListChange(fakeEvent);
    });
  }

  defaultFlags = {
    hideValue: false,
    operatorAsValue: false,
    hideDatepicker: true
  }

  fieldModel = () => {
    return {
      fieldName : "",
      fieldOperator: "",
      fieldValue: "",
      fieldJoin: "AND",
      ...this.defaultFlags
    }
  }

  handleFieldAdd = () => {   
    this.setState((prevState) => ({
      fieldData: [...prevState.fieldData, this.fieldModel()],
    }));

  }

  handleFieldRemove = (event) => {
    event.preventDefault()
    const fieldID = _.split(event.target.id, '-')[1]
    let currentFieldData = this.state.fieldData
    _.pullAt(currentFieldData, [fieldID])
    this.setState(state => ({
      fieldData: [...currentFieldData],
    }))
  }

  getInput = (name) => {
    return _.find(
      schema.data.__schema.types, 
      { 
        "kind" : "INPUT_OBJECT",
        "name" : name
      }
    )
  }

  handleChangePrecise = (fieldID, value, elementName) => {
    let currentFieldData = this.state.fieldData
    currentFieldData[fieldID][elementName] = value  
    this.setState({fieldData: currentFieldData})
  }

  handleChange = (event, elementName) => {
    const fieldID = _.split(event.target.id, '-')[1]
    this.handleChangePrecise(fieldID, event.target.value, elementName)
  }

  getFieldList = () => {
    const inputs = this.getInput(this.props.filterModel)
    let fieldList = [];
    const fields = inputs.inputFields.filter(item => !item.name.endsWith("Id"));
    _.forEach(fields, (value, key) => {
      if (value.name !== "or" && value.name !== "and" && value.name !== "not") {
        fieldList.push(<option key={key} value={value.name}>{transform(value.name)}</option>)
      }
    });
    
    fieldList.unshift(<option key={""} value={""}>{"--none--"}</option>) 
    fieldList = _.sortBy(fieldList, (x) => x.props.children.toLowerCase())

    return fieldList
  }

  renderFields = () => {

    const {fieldData} = this.state;
    const fieldList = this.getFieldList()
    return (
      <Col>
        {
          fieldData.map((field, index) => {
            return (
              <Form.Row key={"searchRow-" + index}>
              <Form.Group controlId={"fieldName-" + index} className="mx-2">
                <Form.Label>Field Name</Form.Label>
                <Form.Control 
                  as = "select"
                  onChange = {(event) => this.handleFieldListChange(event)}
                  value = {field.fieldName}>
                    {fieldList}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId={"fieldOperator-" + index} className="mx-2" >
                <Form.Label>{field.operatorAsValue ? "Value" : "Operator"}</Form.Label>
                <Form.Control 
                  as = "select"
                  onChange = {(event) => this.handleChange(event, 'fieldOperator')}
                  value = {field.fieldOperator !== "" ? field.fieldOperator : !_.isNil(this.state.operators[index]) ? this.state.operators[index][0].props.value  : ""}>
                    {this.state.operators[index]}
                </Form.Control>
              </Form.Group>
              {field.hideValue || 
                <Form.Group controlId={"fieldValue-" + index} className="mx-2" >
                  <Form.Label>Value</Form.Label>
                  <Form.Control
                    as = "input"
                    type = "text"
                    onChange = {(event) => this.handleChange(event, 'fieldValue')}
                    value = {field.fieldValue}>
                  </Form.Control>
                </Form.Group>
              }         
              {field.hideDatepicker ||
                <Form.Group controlId={"fieldValue-" + index} className="mx-2">
                  <Form.Label>Value</Form.Label><br />
                  <DatePicker
                    className="form-control"
                    selected={field.fieldValue}
                    onChange={(value) => this.handleChangePrecise(index, value, 'fieldValue')}
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                    timeInputLabel="Time:"
                  />
                </Form.Group>
              }       
              <Form.Group controlId={"fieldJoin-" + index} className="mx-2">
                <Form.Label>And/Or</Form.Label>
                <Form.Control 
                  as = "select"
                  onChange = {(event) => this.handleChange(event, 'fieldJoin')}
                  value = {field.fieldJoin}>
                    <option key={"AND"} value={"AND"}>{"And"}</option>
                    <option key={"OR"} value={"OR"}>{"Or"}</option>
                </Form.Control>
              </Form.Group>
              <Col>
              { index
                ? (<ButtonGroup className="mt-4">
                  <Button id={"fieldRemove-" + index} variant="link" onClick={(event) => this.handleFieldRemove(event)}>
                    Remove Field
                  </Button>
                  </ButtonGroup>) 
                : ""
              }
              </Col>
              </Form.Row>
            )
          })
        }
      </Col>

    );
  }

  // Provides a hook for using operators as values
  operatorTransform = (operator, fieldModelName) => {
    switch (fieldModelName) {
      case "ModelBooleanFilterInput":
        if (operator === "eq")
          return "No"
        else if (operator === "ne")
          return "Yes"
        else
          return transform(operator)
      default:
        return transform(operator)
    }
  }

  handleFieldListChange = (event) => {
    const fieldID = _.split(event.target.id, '-')[1]
    const inputs = this.getInput(this.props.filterModel).inputFields;  
    const fieldSchema = _.find(inputs, {name : event.target.value})
    let operators = []
    let currentOperators = this.state.operators
    let currentFieldData = this.state.fieldData
    Object.assign(currentFieldData[fieldID], this.defaultFlags)

    // Update the operator.
    if (fieldSchema) {
      const fieldModelName = this.getInput(fieldSchema.type.name).name;

      let fieldModel = []
      // Look to see if the field being searched has it's own fields in the schema and therefore is a sub query.
      if (this.getInput(this.getInput(fieldModelName).inputFields[0].type.name)) {
        fieldModel = this.getInput(this.getInput(fieldModelName).inputFields[0].type.name).inputFields
      }
      else {
        fieldModel = this.getInput(fieldSchema.type.name).inputFields;
      }

      // Place operator names in order of appearance in this array
      const operatorOrderOverride = ['gt', 'lt', 'ge', 'le', 'contains', 'eq', 'ne', 'notContains', 'beginsWith'].reverse()
      fieldModel = _.orderBy(fieldModel, (x) => operatorOrderOverride.indexOf(x.name), 'desc')

      _.forEach(fieldModel, (value, key) => {
          operators.push(<option key={key} value={value.name}>{this.operatorTransform(value.name, fieldModelName)}</option>)
      })
      currentOperators[fieldID] = operators    
      currentFieldData[fieldID].fieldOperator = fieldModel[0].name
      //currentFieldData[fieldID].fieldValue = ''

      // Special hook section for models
      if (fieldModelName === "ModelBooleanFilterInput") {
        currentFieldData[fieldID].fieldValue = 0
        currentFieldData[fieldID].hideValue = true
        currentFieldData[fieldID].operatorAsValue = true
      } else if (fieldModelName === "ModelIntFilterInput" && fieldSchema.name.endsWith("Time")) {
        currentFieldData[fieldID].fieldValue = new Date()
        currentFieldData[fieldID].hideValue = true
        currentFieldData[fieldID].hideDatepicker = false
      }
    }
    else {
      _.pullAt(currentOperators[fieldID])
    }
    
    // Update the field name value.
    currentFieldData[fieldID].fieldName = event.target.value  

    this.setState({fieldData: currentFieldData,
      operators: currentOperators})

  }

  handleSearch = () => {
    // Save to sessionStorage
    sessionStorage.setItem('state', JSON.stringify(this.state));

    let filter = {}
    let currentGroup = {}
    let orGroup = []
    _.forEach(this.state.fieldData, (field, index) => {
      if (_.isEmpty(field.fieldName) || (_.isEmpty(field.fieldValue) && field.fieldValue !== 0 && !_.isDate(field.fieldValue)) || _.isEmpty(field.fieldOperator)) {
        return
      }
      let searchableValue = field.fieldValue;

      if (_.isDate(searchableValue)) searchableValue = getTimestampFromMilli(searchableValue.getTime())
      let criteria = {[field.fieldOperator] : searchableValue}
      // Check if this field is a Sub Query.
      const inputs = this.getInput(this.props.filterModel).inputFields
      const fieldTypeSchemaName = _.find(inputs, {name : field.fieldName}).type.name
      const subQueryModel = this.getInput(this.getInput(fieldTypeSchemaName).inputFields[0].type.name)
      if (subQueryModel) {
        const subQueryFieldModel = this.getInput(fieldTypeSchemaName)
        criteria = {[subQueryFieldModel.inputFields[0].name] : {[field.fieldOperator] : searchableValue}}
      }
      if (_.has(currentGroup, field.fieldName )) {
          _.merge(currentGroup[field.fieldName], criteria)
      }
      else {
        currentGroup[field.fieldName] = criteria         
      }
      if (field.fieldJoin.toUpperCase() === "OR" && !_.isEmpty(currentGroup) ) {
        orGroup.push(currentGroup)
        currentGroup = {}
      }
    })
    if (_.size(orGroup)) {
      orGroup.push(currentGroup)
      filter = {'or': orGroup}
    }
    else {
      filter = currentGroup
    }
    
    sessionStorage.setItem('searchFilter', JSON.stringify(filter));

    this.props.queryFilterCallback(filter)
  }

  resetSearch = () => {
    this.setState({
      fieldData : [this.fieldModel()],
      operators: []
    }, () => {this.handleSearch()})
    sessionStorage.removeItem('state');
    sessionStorage.removeItem('searchFilter');
  }

  render() {
    const {open} = this.state
    return (
      <Container fluid="false" >
          <Form id="SearchForm">
            <Row>
              <Col>
              <ButtonToolbar className="mx-4 mb-4 pull-right">
                <Button variant="primary"
                  onClick={() => this.setState({ open: !open })}
                  aria-controls="collapse-form"
                  aria-expanded={open}>
                  {this.state.open ? "Close Search Form" : "Display Search Form"}
                </Button>
              </ButtonToolbar>
              </Col>
            </Row>
            <Collapse in={this.state.open}>
            <div id="collapse-form" className="border border-gray my-3">
              <Row className="ml-2">
                <Col>
                  <ButtonToolbar className="my-2 pull-right">
                    <Button variant="primary" onClick={this.handleSearch}>
                      Perform Search
                    </Button>
                    <Button variant="secondary" onClick={this.handleFieldAdd} className="ml-3">
                      Add Search Field
                    </Button>
                    <Button variant="secondary" onClick={this.resetSearch} className="mx-3">
                      Reset Search
                    </Button>
                  </ButtonToolbar>
                </Col>
              </Row>                
              <Row className="ml-2">
                {this.renderFields()}
              </Row>
            </div>
            </Collapse>

          </Form>
      </Container>
    )
  }
}

SearchContainer.propTypes = {
  filterModel: PropTypes.string.isRequired,
  queryFilterCallback: PropTypes.func.isRequired
}

export default SearchContainer