import React from 'react'
import  { API, graphqlOperation } from 'aws-amplify'
import uuid from 'uuid/v1'
import TableActions from '../common/TableActions'

import {
  Table,
  Row,
  Col,
  Alert,
  Container
} from 'react-bootstrap'

import {truthyToYesNo} from '../../utils/format'
import * as queries from '../../graphql/queries'
import withPermissions from '../../security/withPermissions'
import Paging from '../common/Paging'
import makeComponentTrashable from 'trashable-react';
import Bottleneck from 'bottleneck'

import Logger from '../../utils/Logger'
const logger = new Logger("ListWACampaigns.js");

class ListWACampaigns extends React.Component {
  gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

    constructor(props) {
      super(props)
      this.state = { 
        loading: true, 
        data: [],
        pages: [],
        defaultLimit: 100,
        currentPage: 0
      }

      this.currentPages = []
    }

      componentDidMount() {
        this.getSourceData()
          .then(response => {
            this.setState({ 
              loading: false, 
              data: response.data.listCampaigns.map(item => {
                item.selected = false
                return item
              }) 
            })
          })
          .then(this.getPages())
      }
    
      getPages = (page = 0) => {
        this.getSourceData(page)
          .then((response) => {
            // Add new set of results to final results array.
            this.currentPages[page] = response.data.listCampaigns
            if (response.data.listCampaigns.length === this.state.defaultLimit) {
              // Make call against next page
              page++
              return this.getPages(page)
            } else {
              this.setState({pages : this.currentPages})
              return
            }
          })
      }

      updateData = (pageNumber) => {
        const pageData =this.state.pages[pageNumber -1]
        this.setState({
          currentPage: pageNumber - 1,
          data: pageData.map(item => {
            item.selected = false
            return item
          }) 
        })
      }
    
      getSourceData = (queryPage = 0) => {
        return this.graphqlOp(graphqlOperation(queries.listCampaigns, {page: queryPage, limit: this.state.defaultLimit}))
          .then(response => response)
          .catch(logger.handleError)
      }

      renderCampaignsList = data => {
        return (
          <Table striped bordered>
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Job Number</th>
                <th>Deliverables</th>
                <th>From Dovetale?</th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr key={uuid()}>
                  <td>
                      <TableActions 
                        itemId={item.campaignId}
                        itemPath="campaigns"
                        canView={this.props.permissions.can("viewCampaigns")}
                        canEdit={this.props.permissions.can("editCampaigns")}
                        canDelete={this.props.permissions.can("deleteCampaigns")}
                      />
                  </td>
                  <td>{item.campaignName}</td>
                  <td>{item.jobNumber}</td>
                  <td>{item.deliverables}</td>
                  <td>{truthyToYesNo(item.dovetaleId)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )
      }

      renderLoading = message => {
        return (
          <Row>
            <Col>
              <Alert variant="info" className="mt-4">Loading {message}...</Alert>
            </Col>
          </Row>
        )
      }
    
      render() {
        const { loading, data } = this.state
    
        return (
          <Container fluid>
            {
              loading ? this.renderLoading('Campaigns') : this.renderCampaignsList(data)
            }
            <Row>
              <Col>
                <Paging
                  active= {this.state.currentPage +1}
                  pageCount= {this.state.pages.length}
                  pageCallback = {this.updateData}>
                </Paging>
              </Col>
            </Row>
          </Container>

        )

      }
}

export default makeComponentTrashable(withPermissions(ListWACampaigns))