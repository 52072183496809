import React from 'react'

import {
  Col,
  Container,
  Row
} from 'react-bootstrap'

import { Link } from 'react-router-dom'

import ListWACampaigns from '../components/campaign/ListWACampaigns'
import ListDovetaleCampaigns from '../components/campaign/ListDovetaleCampaigns'
import Logger from '../utils/Logger';

const logger = new Logger("Campaigns.js");

const waType = "wa";
const dovetaleType = "dovetale";

class IndexCampaigns extends React.Component {
  constructor(props) {
    super(props)
    logger.handleInfo("User arrived at Campaigns page")
  }

  getClassName = (selectedType, linkType) => {
    let className = "nav-item nav-link";
    if (selectedType === linkType)
      className += " active";
    return className
  }

  getListComponent = (selectedType) => {
    switch (selectedType) {
      case waType:
        return (<ListWACampaigns />);
      case dovetaleType:
        return (<ListDovetaleCampaigns />);
      default:
        return null;
    }
  }

  render() {
    let { type } = this.props.match.params;

    type = type || waType;

    return (
      <Container fluid>
        <Row>
          <Col>
            <h1>Campaigns</h1>
            <nav className="nav nav-tabs" role="tablist">
              <Link to={"/campaigns/" + waType} className={this.getClassName(type, waType)}>Wellness Amplified</Link>
              <Link to={"/campaigns/" + dovetaleType} className={this.getClassName(type, dovetaleType)}>Dovetale</Link>
            </nav>
          </Col>
        </Row>
        {this.getListComponent(type)}
      </Container>
    )
  }
}

export default IndexCampaigns