import React, { Component, Fragment } from 'react'
import { Modal, Button, Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv'

class ReportModal extends Component {
    emailReportHeaders = ["firstName", "lastName", "emailAddress1"]

    influencerReportHeaders = [
        "firstName",
        "lastName",
        "websiteURL",
        "blogURL",
        "emailAddress1",
        "bio",
        "unsubscribed",
        "pinterestHandle",
        "pinterestFollowers",
        "linkedInURL",
        "linkedInFollowers",
        "IGhandle",
        "IGfollowers",
        "TWusername",
        "TWfollowers",
        "FBusername",
        "FBpageLikes",
        "YTusername",
        "YTsubscribers",
        'customTags',
        'snapchatHandle',
        'tumblrHandle'
    ]

    renderExports = () =>
        <Container>
            <Row>
                <Col>Exporting data for {this.props.influencers.length} influencers...</Col>
            </Row>
            {this.props.influencers.some(x => x.unsubscribed) &&<Row>
                <Col>Note: {this.props.influencers.filter(x => x.unsubscribed).length} influencer(s) is/are unsubscribed and will not be included in the email export.</Col>
            </Row>}
            <Row>
                <Col><CSVLink
                    filename={"email_export.csv"}
                    headers={this.emailReportHeaders}
                    data={this.props.influencers.filter(x => !x.unsubscribed)}
                    className="btn btn-primary"
                    target="_blank">Email Export</CSVLink></Col>
                <Col><CSVLink
                    filename={"influencer_export.csv"}
                    headers={this.influencerReportHeaders}
                    data={this.props.influencers}
                    className="btn btn-primary"
                    target="_blank">Influencer Export</CSVLink></Col>
            </Row>
        </Container>

    renderNoExports = () =>
        <Fragment><p>You have not selected any influencers. Please go back and select one or more influencers to export.</p></Fragment>

    render() {
        return (
            <Modal onHide={this.props.onHide} show={this.props.show}>
                <Modal.Header >
                    <Modal.Title>Export Influencers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.influencers.length > 0 ? this.renderExports() : this.renderNoExports()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ReportModal.propTypes = {
    /** The list of influencers to generate a report for */
    influencers: PropTypes.array.isRequired,
    /** Should hide the modal */
    onHide: PropTypes.func.isRequired,
    /** Should determine whether modal is opened or not */
    show: PropTypes.bool.isRequired
}

export default ReportModal
