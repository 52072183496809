import React, { Component, Fragment } from 'react'
import  { API, graphqlOperation } from 'aws-amplify'
import DovetaleClient from '../../client/DovetaleClient'
import uuid from 'uuid/v1'
import { Redirect } from 'react-router-dom'
import _ from 'lodash'
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
  Table
} from 'react-bootstrap'
import makeComponentTrashable from 'trashable-react';
import Bottleneck from 'bottleneck'
import { escapeJson, splitNameOrAssignFirst } from '../../utils/format'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import Logger from '../../utils/Logger'
const logger = new Logger("ImportDovetaleList.js");

class DovetaleList extends Component {

  gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      processing: false,
      redirect: false,
      dovetale_list_id: 0,
      list_name: '',
      creators: [],
      listId: 0
    }
  }

  processList = async (response) => {
    let creators = response.creators || []

    this.setState({
      loading: true,
      dovetale_list_id: response.list_id,
      list_name: response.list_name,
      creators: creators
    })

    // For dupe check
    let entries = [];

    creators.forEach(item => {
      item.status = 'loading'
      item.facebook =
        (item.creator_platforms.find(p => p.platform === 'facebook')) ?
          item.creator_platforms.find(p => p.platform === 'facebook')['username'] : ''
      item.instagram =
        (item.creator_platforms.find(p => p.platform === 'instagram')) ?
          item.creator_platforms.find(p => p.platform === 'instagram')['username'] : ''
      item.twitter =
        (item.creator_platforms.find(p => p.platform === 'twitter')) ?
          item.creator_platforms.find(p => p.platform === 'twitter')['username'] : ''
      item.youtube =
        (item.creator_platforms.find(p => p.platform === 'youtube')) ?
          item.creator_platforms.find(p => p.platform === 'youtube')['username'] : ''
      item.youtubeUrl =
        (item.creator_platforms.find(p => p.platform === 'youtube')) ?
          item.creator_platforms.find(p => p.platform === 'youtube')['channel_url'] : ''

      item.name = item.creator_platforms[0].name || item.name;

      if (item.facebook) entries.push({ FBusername: {eq: item.facebook} });
      if (item.instagram) entries.push({ IGhandle: {eq: item.instagram} });
      if (item.twitter) entries.push({ TWusername: {eq: item.twitter} });
      if (item.youtube) entries.push({ YTusername: {eq: item.youtube} });
      if (item.youtubeUrl) entries.push({ YTchannelUrl: {eq: item.youtubeUrl} });
    });

    const filterChunks = _.chunk(entries, 500)
    const influencerPromises = filterChunks.map(filters => this.graphqlOp(graphqlOperation(queries.listInfluencers, escapeJson({filter : { or : filters}}))))

    const dupes = await Promise.all(influencerPromises.map(p => p.catch(logger.handleError)));

    let influencersAll = _.flatten(dupes.filter(result => !_.isNil(result.data.listInfluencers)).map(result => result.data.listInfluencers))

    if (!influencersAll.length) influencersAll = [];

    creators.forEach(item => {
      if (!item) return;
      let match = null;

      if (item.facebook) match = match || influencersAll.find(x => (x.FBusername || '').toLowerCase() === item.facebook.toLowerCase());
      if (item.instagram) match = match || influencersAll.find(x => (x.IGhandle || '').toLowerCase() === item.instagram.toLowerCase());
      if (item.twitter) match = match || influencersAll.find(x => (x.TWusername || '').toLowerCase() === item.twitter.toLowerCase());
      if (item.youtubeUrl) match = match || influencersAll.find(x => (x.YTchannelUrl || '').toLowerCase() === item.youtubeUrl.toLowerCase());
      if (item.youtube) match = match || influencersAll.find(x => (x.YTusername || '').toLowerCase() === item.youtube.toLowerCase());

      if (match) {
        item.status = 'exists';
        item.duplicateInfluencer = match;
      } else {
        item.status = 'new'
      }
    });

    this.setState({
      loading: false,
      creators: creators
    })
  }

  componentDidMount() {
    const { id } = this.props.match.params
    API.get("wellnessamplifiedapi", `/dovetale/lists?id=${id}`).then(this.processList).catch(logger.handleError)
  }

  renderLoading = message => {
    return (
      <Row>
        <Col>
          <Alert variant="info" className="mt-4">Loading {message}...</Alert>
        </Col>
      </Row>
    )
  }

  createInfluencerAndAttachToList = async (creator, listId) => {
    const input = {
      createInfluencerInput: {
        creationSource: "Dovetale List Import",
        unsubscribed: 0,
        ...splitNameOrAssignFirst(creator.name || ''),
        emailAddress1: creator.email || '',
      }
    };
    const newInfluencerResponse = await this.graphqlOp(graphqlOperation(mutations.createInfluencer, escapeJson(input)));

    const influencerId = newInfluencerResponse.data.createInfluencer.influencerId;

    const listEntryPromise = DovetaleClient.createInfluencerListEntry(influencerId, listId);

    // Create a promise for each social media platform. If not included, just resolve an empty object.
    const fbPromise = creator.facebook ? DovetaleClient.createFacebookStat(influencerId, creator.facebook) : Promise.resolve({});
    const igPromise = creator.instagram ? DovetaleClient.createInstagramStat(influencerId, creator.instagram) : Promise.resolve({});
    const twPromise = creator.twitter ? DovetaleClient.createTwitterStat(influencerId, creator.twitter) : Promise.resolve({});
    const ytPromise = (creator.youtube && creator.youtubeUrl) ? DovetaleClient.createYoutubeStat(influencerId, creator.youtube, creator.youtubeUrl) : Promise.resolve({});

    return await Promise.all([listEntryPromise, fbPromise, igPromise, twPromise, ytPromise].map(p => p.catch(logger.handleError)))
  }

  processInfluencers = async (listId) => {

    // creator* should refer to the data coming from Dovetale
    // influencer* should refer to the data in the WAMP db

    const creatorsCopy = JSON.parse(JSON.stringify(this.state.creators))
    let promises = []

    creatorsCopy.forEach((creator) => {
      this.setState({ creators: creatorsCopy })
      if (creator.status === 'new') {
        creator.status = 'updating'
        promises.push(this.createInfluencerAndAttachToList(creator, listId));
      } else if (creator.duplicateInfluencer) {
        creator.status = 'updating'
        // Already exists in DB
        const influencer = creator.duplicateInfluencer;
        const influencerId = influencer.influencerId;

        let fbNew = !Object.keys(influencer).filter(x => x.startsWith("FB")).some(key => !_.isNil(influencer[key]));
        let igNew = !Object.keys(influencer).filter(x => x.startsWith("IG")).some(key => !_.isNil(influencer[key]));
        let twNew = !Object.keys(influencer).filter(x => x.startsWith("TW")).some(key => !_.isNil(influencer[key]));
        let ytNew = !Object.keys(influencer).filter(x => x.startsWith("YT")).some(key => !_.isNil(influencer[key]));
        promises.push(DovetaleClient.createInfluencerListEntry(influencerId, listId));
        if (!_.isEmpty(creator.facebook)) promises.push(DovetaleClient.createFacebookStat(influencerId, creator.facebook, fbNew ? 'create' : 'update'))
        if (!_.isEmpty(creator.instagram)) promises.push(DovetaleClient.createInstagramStat(influencerId, creator.instagram, igNew ? 'create' : 'update'))
        if (!_.isEmpty(creator.twitter)) promises.push(DovetaleClient.createTwitterStat(influencerId, creator.twitter, twNew ? 'create' : 'update'))
        if (!_.isEmpty(creator.youtube) || !_.isEmpty(creator.youtubeUrl)) promises.push(DovetaleClient.createYoutubeStat(influencerId, creator.youtube, creator.youtubeUrl, ytNew ? 'create' : 'update'))
      }
    })

    return await Promise.all(promises.map(p => p.catch(logger.handleError)))
  }

  handleSubmit = async event => {

    event.preventDefault()

    this.setState({processing: true})

    const listName = this.state.list_name
    const dovetaleId = this.state.dovetale_list_id || 0

    const existingList = await this.graphqlOp(graphqlOperation(queries.searchLists, {
      name: listName,
      dovetaleId: dovetaleId
    }))

    let listId = 0;

    if (existingList.data.searchLists.length) {
      listId = existingList.data.searchLists[0].listId;
    } else {
      const newList = await this.graphqlOp(graphqlOperation(mutations.createList, {
        createListInput: {
          name: listName,
          dovetaleId: dovetaleId
        }
      }))
      listId = newList.data.createList.listId;
    }

    this.setState({listId: listId});

    await this.processInfluencers(listId);

    this.setState({redirect: true});
  }

  renderList = () => {
    if (!this.state.creators) return

    return (
      <Row>
        <Col>
          <h2>Influencers</h2>
          {this.state.redirect && <Redirect to={"/lists/details/" + this.state.listId} />}
          <Table striped bordered>
            <thead>
              <tr>
                <th>Status</th>
                <th>Name</th>
                <th>Email</th>
                <th>Facebook</th>
                <th>Instagram</th>
                <th>Twitter</th>
                <th>Youtube</th>
              </tr>
            </thead>
            <tbody>
              {this.state.creators.map(item => (
                <tr key={uuid()}>
                  <td>{item.status ? item.status : ''}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.facebook}</td>
                  <td>{item.instagram}</td>
                  <td>{item.twitter}</td>
                  <td>{item.youtube}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }

  render() {
    const { loading, processing } = this.state;

    return (
      <Container fluid>
        <Row>
          <Col>
            <h1>Import Dovetale List <span className="text-secondary">{this.state.list_name}</span></h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Fragment>
              {loading ? this.renderLoading('List') : this.renderList()}
            </Fragment>
          </Col>
        </Row>
        <Row>
          {loading ? <p>Please wait for the above to finish loading. Then you will be able to import.</p> : <Col>
            {!processing ? 
              <Form id="EditInfluencerForm" onSubmit={event => this.handleSubmit(event)}>
                <ButtonGroup >
                  <Button variant="secondary" type="submit" onClick={this.handleSubmit}>
                    Submit
                  </Button>
                </ButtonGroup>
              </Form>
            :
              <p>Processing... <b>Please wait and do not navigate away...</b> This shouldn't take more than a few minutes. You will be redirected once import is complete.</p>
            }
          </Col>}
        </Row>
      </Container >
    );
  }
}

export default makeComponentTrashable(DovetaleList)