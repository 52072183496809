import React from 'react'
import { Button, Form, Modal,} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from '../../graphql/mutations'
import * as queries from '../../graphql/queries'
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import { escapeJson } from '../../utils/format'
import Bottleneck from 'bottleneck'
import Logger from '../../utils/Logger'
import makeComponentTrashable from 'trashable-react';
import _ from 'lodash'
import { getInfluencerPlatformStats, updateInfluencer, createInfluencer } from '../influencer/influencerUtils';
const logger = new Logger("BulkInfluencerImportModal.js");


class BulkInfluencerImportModal extends React.Component {
  gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

  constructor(props) {
    super(props);

    this.state = {
      lists: [],
      selectedList: null,
      csvData: [],
      influencerRecords: [],
      duplicateInfluencerRecords: [],
      influencersCreated: 0,
      influencersNotCreated: 0,
      successMessages: [],
      errorMessages: [],
      lineErrors: [],
      duplicateOptionSelect: 'augment',
      mode: 'loading' // loading, start, dups, create, complete
    }

    // The library that reads the csv requires an array of arrays, so the template header
    // is considered the first row of that array thus the double square brackets
    this.templateColumnHeaders = [
      'prefix',
      'firstName',
      'lastName',
      'suffix',
      'bio',
      'notes',
      'addressLine1',
      'city',
      'state',
      'zip',
      'country',
      'phone',
      'websiteURL',
      'websiteFollowers',
      'blogURL',
      'emailAddress1',
      'emailAddress2',
      'pinterestHandle',
      'pinterestFollowers',
      'threadsHandle',
      'threadsFollowers',
      'IGhandle',
      'IGfollowers',
      'TWusername',
      'TWfollowers',
      'FBusername',
      'FBpageLikes',
      'YTusername',
      'YTchannelUrl',
      'YTsubscribers'
    ]

    this.socialColumnConversion = {
      FBusername: 'username',
      FBpageLikes: 'pageLikes',
      TWusername: 'username',
      TWfollowers: 'followers',
      IGhandle: 'handle',
      IGfollowers: 'followers',
      YTusername: 'username',
      YTsubscribers: 'subscribers',
      YTchannelUrl: 'channelUrl'
    }

    this.emptyStats = {
      statFB: {username: '', pageLikes: ''},
      statIG: {handle: '', followers: ''},
      statTW: {username: '', followers: ''},
      statYT: {username: '', subscribers: '', channelUrl: ''},
      fbOriginalData: {username: '', pageLikes: ''},
      igOriginalData: {handle: '', followers: ''},
      twOriginalData: {username: '', followers: ''},
      ytOriginalData: {username: '', subscribers: '', channelUrl: ''},
    }
  }

  componentDidMount = () => {
    this.graphqlOp(graphqlOperation(queries.listLists))
    .then(response => {
        let mappedLists = response.data.listLists.map(
          list => <option key={list.listId} value={list.listId}>{list.name}</option>
        )
        this.setState({
            lists: [<option key={0} value={0}>-- Do Not Add To List --</option>, ...mappedLists],
            selectedList: 0,
            mode: "start"
        })
    })
    .catch(logger.handleError)
  }


  verifyInfluencers = () => {
    const { influencerRecords } = this.state

    let allPromises = influencerRecords.map((influencer, index) => {

      let filterInfluencerRecord = {
        emailAddress: influencer.createInfluencerInput.emailAddress1,
        pinterestHandle: influencer.createInfluencerInput.pinterestHandle,
        linkedInURL: influencer.createInfluencerInput.linkedInURL,
        fbUsername: influencer.stats.statFB.username,
        igHandle: influencer.stats.statIG.handle,
        twUsername: influencer.stats.statTW.username,
        ytUsername: influencer.stats.statYT.username,
        ytChannelUrl: influencer.stats.statYT.channelUrl
      }

      return this.graphqlOp(graphqlOperation(queries.listDuplicateInfluencers, { filter: escapeJson(filterInfluencerRecord) }))
      .then(response => {
        if (response.data.listDuplicateInfluencers.length > 0) {
          // Build dup record, insert into dupRecords and sort by csv row #
          let dup = [influencer, response.data.listDuplicateInfluencers[0]];
          delete dup[1].creationTime;
          delete dup[1].creationSource;
          delete dup[1].totalFollowers;
          delete dup[1].resultsCount;
          dup[1].sourceImportRow = index; // Storing this in case user ignores dup and we need to create this record anyway

          return getInfluencerPlatformStats(dup[1].influencerId)
          .then(res => {

            let dupRecords = [...this.state.duplicateInfluencerRecords, dup];
            dupRecords.sort((a, b) => a[0].rowNumber - b[0].rowNumber);

            influencerRecords[index].isDupe = true;

            Object.keys(influencerRecords[index].stats)
            .filter(key => {
              return (key.indexOf('OriginalData') > -1) ? true : false;
            })
            .forEach(key => {
              influencerRecords[index].stats[key] = Object.keys(res[key]).length ? res[key] : _.clone(this.emptyStats[key]);
            });

            this.setState({
              influencerRecords: influencerRecords,
              duplicateInfluencerRecords: dupRecords,
              mode: 'dups',
            });
          })
          .catch(logger.handleError);
        }
      })
      .catch(logger.handleError);
    })

    Promise.all(allPromises).then(() => this.state.mode === 'loading' && this.setState({mode: 'create'}));
  }


  mapInfluencers = (verifyCallback) => {
    let influencerRecords = []
    let csvData = this.state.csvData.slice()
    let keys = this.state.csvData[0]
    let rows = csvData.filter((row, index) => index !== 0)

    rows.forEach((row, index) => {
      // If row length is less than or equal to 1 then return, it's an empty row
      if (row.length <= 1 || row.every(x => !x)) return

      var influencerRecord = {
        stats: _.cloneDeep(this.emptyStats),
        createInfluencerInput: {},
        isDupe: false
        // Other fields as well but not complicated objects
      }
      
      row.forEach((val, col) => {
        if (!val) return;
        let key = keys[col];
    
        // Map 'threadsHandle' to 'snapchatHandle' internally
        if (key === 'threadsHandle') {
          influencerRecord.createInfluencerInput['linkedInURL'] = val;
        }else if (key === 'threadsFollowers') {
          influencerRecord.createInfluencerInput['linkedInFollowers'] = val;
        }else if (Object.keys(this.socialColumnConversion).findIndex(column => column === key) >= 0) {
          let socialTypeObj = "stat" + key.substring(0,2);
          influencerRecord.stats[socialTypeObj][this.socialColumnConversion[key]] = val;
        } else {
          influencerRecord.createInfluencerInput[key] = val;
        }
      });

      influencerRecord.rowNumber = index + 1
      influencerRecord.createInfluencerInput.unsubscribed = 0
      influencerRecord.createInfluencerInput.constantContactId = 0
      influencerRecord.createInfluencerInput.replyId = 0
      influencerRecord.createInfluencerInput.creationSource = "Bulk Influencer Import"
      influencerRecords.push(influencerRecord)
    })

    this.setState({
      influencerRecords: influencerRecords,
      mode: 'loading' // This mode might change in the verify, but if it doesn't it should move onto create mode....I think this is the right way to do it?
    }, () => verifyCallback())
  }


  createInfluencers = () => {
    this.setState({
      influencersCreated: 0,
      influencersNotCreated: 0,
      successMessages: [],
      errorMessages: [],
      lineErrors: [],
      mode: 'complete'
    })

    this.state.influencerRecords.forEach((record, index) => {
      if (!record.isDupe) {

        Object.keys(record.stats)
        .filter(key => {
          return (key.indexOf('OriginalData') > -1) ? true : false;
        })
        .forEach(key => {
          record.stats[key] = '';
        });

        createInfluencer(escapeJson(record.createInfluencerInput), escapeJson(record.stats))

        .then(influencerId => {
          this.logSuccess("Influencer with id " + influencerId + " created!", true)
          this.state.selectedList !== 0 && this.graphqlOp(graphqlOperation(mutations.createInfluencerList, {createInfluencerListInput: {influencerId, listId: this.state.selectedList}}))
        })
        .catch(error => {
          this.logError(error.message, true, index+1)
        })
      }
    })
  }


  logSuccess = (message, wasInfluencerCreated) => {
    logger.handleTrace(message)
    this.setState((state) => ({
      successMessages: [...state.successMessages, message]
    }))

    if (wasInfluencerCreated) {
      this.setState((state) => ({
        influencersCreated: state.influencersCreated + 1
      }))
    }
  }


  logError = (message, wasInfluencerNotCreated, lineNumber) => {
    logger.handleTrace(message)
    this.setState((state) => ({
      errorMessages: [...state.errorMessages, message]
    }))

    if (wasInfluencerNotCreated) {
      this.setState((state) => ({
        influencersNotCreated: state.influencersNotCreated + 1
      }))
    }

    if (lineNumber) {
      this.setState((state) => ({
        lineErrors: [...state.lineErrors, lineNumber]
      }))
    }
  }


  displayErrors = (lineErrors) => {
    let errorMessage = null
    lineErrors.forEach((lineError) => {
      errorMessage = (errorMessage) ? ', ' + lineError : 'The influencers on the following lines were not created : ' + lineError
    })

    return (errorMessage) ? errorMessage : ''
  }


  clearForm = () => {
    this.setState({
      influencersCreated: 0,
      influencersNotCreated: 0,
      selectedList: 0,
      successMessages: [],
      errorMessages: [],
      lineErrors: [],
      duplicateInfluencerRecords: [],
      mode: 'start'
    })
  }


  augmentDuplicate = () => {
    let duplicateInfluencerRecords = [...this.state.duplicateInfluencerRecords];
    let dup = duplicateInfluencerRecords.shift(); // Grab first row of duplicates

    delete dup[1].sourceImportRow;
    let augmentedInfluencer = {};
    let augmentedInfluencerStats = _.cloneDeep(this.emptyStats);

    Object.keys(dup[1]).map((field, index) => (
      augmentedInfluencer[field] = (dup[1][field]) ? dup[1][field] : dup[0].createInfluencerInput[field]
    ))

    _(augmentedInfluencerStats)
    .keys()
    .filter((key) => { return key.indexOf('OriginalData') === -1; })
    .forEach(key => {
      let origKey = _.toLower(_.trimStart(key, 'stat')) + 'OriginalData';
      _.forEach(augmentedInfluencerStats[key], (value, statsKey) => {
        let orig = dup[0].stats[origKey][statsKey];
        let val = dup[0].stats[key][statsKey];
        if (orig){
          augmentedInfluencerStats[key][statsKey] = orig;
          augmentedInfluencerStats[origKey][statsKey] = orig;
        } else {
          augmentedInfluencerStats[key][statsKey] = val;
        }
      })
    })

    updateInfluencer(augmentedInfluencer, augmentedInfluencerStats)
    .then(() => this.state.selectedList === 0 ? Promise.resolve(true) : this.graphqlOp(graphqlOperation(mutations.createInfluencerList, {createInfluencerListInput: {influencerId: augmentedInfluencer.influencerId, listId: this.state.selectedList}})))
    .then(() => {
      let nextMode = (duplicateInfluencerRecords.length === 0) ? 'create' : this.state.mode
      this.setState({
        duplicateInfluencerRecords: duplicateInfluencerRecords,
        mode: nextMode
      })
    })
    .catch(error => {
      this.logError(error.message, false)
    })
  }


  updateDuplicate = () => {
    let duplicateInfluencerRecords = [...this.state.duplicateInfluencerRecords]
    let dup = duplicateInfluencerRecords.shift() // Grab first row of duplicates
    let updatedInfluencer = Object.assign({}, dup[0].createInfluencerInput)
    let influencerId = dup[1].influencerId
    updatedInfluencer.influencerId = influencerId
    delete updatedInfluencer.creationSource
    let updatedStats = _.cloneDeep(this.emptyStats);

    _(updatedStats)
    .keys()
    .filter((key) => { return key.indexOf('OriginalData') === -1; })
    .forEach(key => {
      let origKey = _.toLower(_.trimStart(key, 'stat')) + 'OriginalData';
      _.forEach(updatedStats[key], (value, statsKey) => {
        let orig = dup[0].stats[origKey][statsKey];
        let newStat = dup[0].stats[key][statsKey];
        if (newStat && newStat.length){
          updatedStats[key][statsKey] = newStat;
        } else {
          updatedStats[key][statsKey] = orig;
        }
      })
    })

    updateInfluencer(updatedInfluencer, dup[0].stats)
    .then(() => this.state.selectedList === 0 ? Promise.resolve(true) : this.graphqlOp(graphqlOperation(mutations.createInfluencerList, {createInfluencerListInput: {influencerId: updatedInfluencer.influencerId, listId: this.state.selectedList}})))
    .then(() => {
      let nextMode = (duplicateInfluencerRecords.length === 0) ? 'create' : this.state.mode
      this.setState({
        duplicateInfluencerRecords: duplicateInfluencerRecords,
        mode: nextMode
      })
    })
    .catch(error => {
      this.logError(error.message, false)
    })
  }


  ignoreDuplicate = () => {
    let duplicateInfluencerRecords = [...this.state.duplicateInfluencerRecords]
    let influencerRecords = [...this.state.influencerRecords]

    let dup = duplicateInfluencerRecords.shift()
    influencerRecords[dup[1].sourceImportRow].isDupe = false

    let nextMode = (duplicateInfluencerRecords.length === 0) ? 'create' : this.state.mode

    this.setState({
      duplicateInfluencerRecords: duplicateInfluencerRecords,
      influencerRecords: influencerRecords,
      mode: nextMode
    })
  }


  handleFileUpload = () => {
    let influencerCsv = this.fileUpload.files[0]

    Papa.parse(influencerCsv, {
      complete: (results) => {
        this.setState({ csvData: results.data })
      }
    })
  }


  handleSubmit = () => {
    const {
      duplicateOptionSelect,
      mode
    } = this.state

    if (mode === 'start') {
      let verifyCallback = () => { this.verifyInfluencers() }
      this.mapInfluencers(verifyCallback)
    } else if (mode === 'dups') {
      // Take answer and either merge or replace
      if (duplicateOptionSelect === 'augment') {
        this.augmentDuplicate()
      } else if (duplicateOptionSelect === 'update') {
        this.updateDuplicate()
      } else if (duplicateOptionSelect === 'ignore') {
        this.ignoreDuplicate()
      }

    // If no more dups after last process and still influencer recrods set mode to create
    } else if (mode === 'create') {
        this.createInfluencers()
    }
  }


  renderSuccess = (influencersCreated) =>
    <div className="alert alert-success">
      Influencers created : {influencersCreated}
    </div>


  renderErrorMessages = (lineErrors) =>
    <div className="alert alert-danger">
      {this.displayErrors(lineErrors)}
    </div>


  handleListSelectChange = event => {
    this.setState({
        selectedList: event.target.value
    })
  }


  renderUploadDialog = (influencersCreated, lineErrors) =>
      <Form>
        <p>To create multiple influencers at once, start by clicking "Download Template" below. Fill out the rows after the first row with values for each influencer. Then upload this file to begin the process.</p>
        <Form.Group controlId="lists">
          <Form.Label>If you would like all created influencers to be part of a list, please select it from this dropdown <b>before</b> uploading the document.</Form.Label>
          <Form.Control as="select"
            name="listId"
            value={this.state.selectedList}
            onChange={this.handleListSelectChange}>
            {this.state.lists}
          </Form.Control>
        </Form.Group>
        <Form.Control
          ref={(ref) => this.fileUpload = ref}
          type="file"
          onChange={() => this.handleFileUpload()}
        />
        <CSVLink
          data={[this.templateColumnHeaders]}
          filename={'Influencer Template.csv'}>
          Download Template
        </CSVLink>
      </Form>


  renderFinish = () =>
    <strong>Everything is ready, click Finish to complete import.</strong>


  renderInfluencerIdentifier = (duplicateInfluencer) => {
    let influencerName = ''
    let influencer = duplicateInfluencer[1]
    if (influencer.firstName || influencer.lastName) {
      influencerName = (influencer.firstName) ? (influencer.firstName + ' ') : ''
      influencerName += (influencer.lastName) ? (influencer.lastName) : ''
    } else if (influencer.emailAddress1) {
      influencerName = influencer.emailAddress1
    } else {
      influencerName = ''
    }

    let identifier
    if (influencerName === '') {
      identifier = <strong>CSV row #{duplicateInfluencer[0].rowNumber} has duplicates. </strong>
    } else {
      identifier = <strong>Potential Duplicate with Existing Influencer '{influencerName}' (from csv row #{duplicateInfluencer[0].rowNumber}).</strong>
    }

    return identifier
  }


  renderDuplicateProcess = () => {

    let handleDuplicateOptionChange = (event) => {
      this.setState({
        duplicateOptionSelect: event.target.value
      })
    }

    const {
      duplicateInfluencerRecords
    } = this.state

    let potentialDuplicateId = duplicateInfluencerRecords[0][1].influencerId

    return (
      <div>
        {this.renderInfluencerIdentifier(duplicateInfluencerRecords[0])}
        <div className='form-check'>
          <label>
            <input
              type='radio'
              name='processdup'
              value='augment'
              checked={this.state.duplicateOptionSelect === 'augment'}
              className='form-check-input'
              onChange={handleDuplicateOptionChange}
            />
            Fields not already populated should be augmented with new data
          </label>
        </div>
        <div className='form-check'>
          <label>
            <input
              type='radio'
              name='processdup'
              value='update'
              className='form-check-input'
              onChange={handleDuplicateOptionChange}
              checked={this.state.duplicateOptionSelect === 'update'}
            />
            Existing fields should be updated.
          </label>
        </div>
        <div className='form-check'>
          <label>
            <input
              type='radio'
              name='processdup'
              value='ignore'
              className='form-check-input'
              onChange={handleDuplicateOptionChange}
              checked={this.state.duplicateOptionSelect === 'ignore'}
            />
            Ignore potential duplicate, create influencer anyway.
          </label>
        </div>
        <Link className="btn btn-outline-secondary" to={`/influencers/details/${potentialDuplicateId}`} target="_blank">
          To Possible Duplicate Influencer
        </Link>
      </div>
    )
  }


  render = () => {

    const {
      influencersCreated,
      lineErrors,
      mode
    } = this.state

    let buttonLabel = ''
    if (mode === 'start') {
      buttonLabel = 'Check'
    } else if (mode === 'dups') {
      buttonLabel = 'Process'
    } else if (mode === 'create') {
      buttonLabel = 'Finish'
    }

    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.hideModalCB()}
      >
        <Modal.Header>
          <Modal.Title>Upload Influencers File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mode === 'loading' && <p>Loading...</p>}
          {mode === 'start' && this.renderUploadDialog(influencersCreated, lineErrors)}
          {mode === 'dups' && this.renderDuplicateProcess()}
          {mode === 'create' && this.renderFinish()}
          {mode === 'complete' &&
            lineErrors.length > 0 && this.renderErrorMessages(lineErrors)}
          {mode === 'complete' &&
            influencersCreated > 0 && this.renderSuccess(influencersCreated)}
          {mode === 'complete' &&
            <strong>Import complete.</strong>
          }
        </Modal.Body>
        <Modal.Footer>
          {mode !== 'complete' &&
            <Button variant="primary" onClick={() => this.handleSubmit()}>
              {buttonLabel}
            </Button>
          }
          <Button variant="secondary" onClick={() => this.clearForm() || this.props.hideModalCB()}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default makeComponentTrashable(BulkInfluencerImportModal)
