import _ from 'lodash'
import Logger from '../../utils/Logger'
const logger = new Logger("StringTransform.js");

export const transform = (untransformedString) => {
        const stringList = {
            influencerId: "Influencer ID",
            unsubscribed: "Unsubscribed",
            prefix: "Prefix",
            firstName: "First Name",
            lastName: "Last Name",
            suffix: "Suffix",
            bio: "Bio",
            notes: "Important Notes",
            addressLine1: "Street",
            city: "City",
            state: "State",
            zip: "Zip",
            country: "Country",
            phone: "Phone",
            websiteURL: "Web Site URL",
            websiteFollowers: "Blog UMV",
            blogURL: "Blog",
            constantContactId: "Constant Contact ID",
            replyId: "Reply ID",
            emailAddress1: "Primary E-mail",
            emailAddress2: "Secondary E-mail",
            pinterestHandle: "Pinterest Handle",
            pinterestFollowers: "Pinterest Followers",
            linkedInURL: "TikTok URL",
            linkedInFollowers: "TikTok Followers",
            IGhandle: "Instagram Handle",
            IGengagementRate: "Instagram Engagement",
            IGfollowers: "Instagram Followers",
            IGbio: "Instagram Bio",
            IGsponsoredPercentage: "Instagram Sponsored Percentage",
            IGdemoAgeUnder21: "Instatgram Under 21",
            IGdemoAge21To34: "Instagram 21 to 34",
            IGdemoAgeOver34: "Instagram Over 34",
            IGdemoGenderMale: "Instagram Male",
            IGdemoGenderFemale: "Instagram Female",
            IGdemoGenderOther: "Instagram Other",
            IGaudienceUS: "Instagram US Audience",
            USaudienceCA: "Instagram CA Audience",
            TWusername: "Twitter User Name",
            TWengagementRate: "Twitter Engagement",
            TWfollowers: "Twitter Followers",
            TWbio: "Twitter Bio",
            FBusername: "Facebook User Name ",
            FBengagementRate: "Facebook Engagement",
            FBpageLikes: "Facebook Likes",
            FBbio: "Facebook Bio",
            YTusername: "YouTube User Name",
            YTengagementRate: "YouTube Engagment",
            YTsubscribers: "YouTube Subscribers",
            YTbio: "YouTube Bio",
            YTviewsPerVideo: "YouTube Views Per Video",
            YTchannelUrl: "YouTube Channel Url",
            relationshipStatus: "Relationship Status",
            tag: "Particulars Question",
            list: "List Name",
            campaign: "Campaign Name",
            tagValue: "Particulars Answer",
            customTags: "Tag",
            tumblrHandle: "Tumblr Handle",
            snapchatHandle: "Threads Handle", //Snapchat field was reused to save the Threads Handle
            creationTime: "Joined On",
            totalFollowing: "Total Following",
            communication: "Communication Subject",
            contains: "Contains",
            notContains: "Does Not Contain",
            ne: "Not Equal",
            eq: "Equal",
            beginsWith: "Begins with",
            le: "<=",
            ge: ">=",
            gt: ">",
            lt: "<",
        }

        if (_.has(stringList, untransformedString)) {
            return stringList[untransformedString]
        }
        else {
            logger.handleError('No transformation provided for the string ' + untransformedString)
            return untransformedString
        }
    }