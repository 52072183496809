import React, { Component } from 'react'
import { 
  BrowserRouter as Router,
  Link } from 'react-router-dom'

import Amplify from 'aws-amplify'
import awsmobile from './aws-exports'

import { 
  withAuthenticator, 
  SignIn, 
  Greetings, 
  RequireNewPassword, 
  ForgotPassword, 
  Loading } from 'aws-amplify-react'
import { 
  Col, 
  Container, 
  Nav, 
  Navbar, 
  Row } from 'react-bootstrap'

import Routes from './routes/Routes'
import withPermissions from './security/withPermissions'


Amplify.configure(awsmobile)

class App extends Component {
  render() {
    return (
      <Router>
        <Container fluid>
          <Row>
            <Col>
              <Navbar className="navbar-light bg-light">
                <Nav className="mr-auto">
                  <Nav><Link to={'/'} className="nav-link">Home</Link></Nav>
                  {this.props.permissions.can("viewInfluencers") && <Nav>
                    <Link to={'/influencers'} className="nav-link">Influencers</Link>
                  </Nav>}
                  {/* {this.props.permissions.can("viewCampaigns") && <Nav>
                    <Link to={'/campaigns'} className="nav-link">Campaigns</Link>
                  </Nav>} */}
                  {this.props.permissions.can("viewLists") && <Nav>
                    <Link to={'/lists'} className="nav-link">Lists</Link>
                  </Nav>}
                  {this.props.permissions.can("viewUsers") && <Nav>
                    <Link to={'/users'} className="nav-link">Users</Link>
                  </Nav>}
                </Nav>
              </Navbar>
            </Col>
          </Row>
          <Routes />
        </Container>
      </Router>
    )
  }
}

const AppWithPermissions = withPermissions(App);

const AppWithAuth = withAuthenticator(AppWithPermissions, {
  includeGreetings: true,
  authenticatorComponents:
    [
      <Greetings />,
      <SignIn />,
      <RequireNewPassword />,
      <ForgotPassword />,
      <Loading />
    ]
})

export default AppWithAuth