import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from '../graphql/mutations'
import {escapeJson, getCurrentTimestamp, splitNameOrAssignFirst} from '../utils/format'
import _ from 'lodash'
import Logger from '../utils/Logger'
const logger = new Logger("DovetaleClient.js");

export default {
  createFacebookStat: async (influencerId, facebookUsername, op = "create", influencer = null) => {
    if (influencerId && facebookUsername) {
      const response = await API.get("wellnessamplifiedapi", `/dovetale/accounts?platform=facebook&username=${facebookUsername}`);

      var account = response.account;

      const backupName = _.get(account, 'name', facebookUsername)

      if (influencer && !influencer.firstName && !influencer.lastName && backupName) {
        await API.graphql(graphqlOperation(mutations.updateInfluencer, escapeJson({
          updateInfluencerInput: {
            influencerId,
            unsubscribed: influencer.unsubscribed,
            ...splitNameOrAssignFirst(backupName)
          }
        })));
      }

      if (!account) {
        logger.handleTrace("No Dovetale FB Account found for " + facebookUsername)
        return
      }

      const inputName = op === "create" ? "createInfluencerStatFBInput" : "updateInfluencerStatFBInput";
      const mutationName =  op === "create" ? "createInfluencerStatFb" : "updateInfluencerStatFb";

      var input = {};
      input[inputName] = {
        influencerId: influencerId,
        username: facebookUsername
      }
      if (account && account.metrics && account.metrics.engagement_percentage) {
        input[inputName].engagementRate = account.metrics.engagement_percentage;
        input[inputName].engagementRateLastUpdated = getCurrentTimestamp();
      }
      if (account && account.page_likes) {
        input[inputName].pageLikes = account.page_likes;
        input[inputName].pageLikesLastUpdated = getCurrentTimestamp();
      }
      if (account && account.bio) {
        input[inputName].bio = account.bio;
      }

      return await API.graphql(graphqlOperation(mutations[mutationName], escapeJson(input)));
    } else {
      throw new Error("Missing influencerId or facebookUsername");
    }
  },

  createInstagramStat: async (influencerId, instagramUsername, op = "create", influencer = null) => {
    if (influencerId && instagramUsername) {
      const response = await API.get("wellnessamplifiedapi", `/dovetale/accounts?platform=instagram&username=${instagramUsername}`);

      var account = response.account;

      const backupName = _.get(account, 'name', instagramUsername)

      if (influencer && !influencer.firstName && !influencer.lastName && backupName) {
        await API.graphql(graphqlOperation(mutations.updateInfluencer, escapeJson({
          updateInfluencerInput: {
            influencerId,
            unsubscribed: influencer.unsubscribed,
            ...splitNameOrAssignFirst(backupName)
          }
        })));
      }

      if (!account) {
        logger.handleTrace("No Dovetale IG Account found for " + instagramUsername)
        return
      }

      const inputName = op === "create" ? "createInfluencerStatIGInput" : "updateInfluencerStatIGInput";
      const mutationName =  op === "create" ? "createInfluencerStatIg" : "updateInfluencerStatIg";

      var input = {};
      input[inputName] = {
        influencerId: influencerId,
        handle: instagramUsername
      }

      if (account && account.demographics) {
        if (account.demographics.age_under_21_percent) {
          input[inputName].demoAgeUnder21 = account.demographics.age_under_21_percent;
        }

        if (account.demographics.age_21_to_34_percent) {
          input[inputName].demoAge21To34 = account.demographics.age_21_to_34_percent;
        }

        if (account.demographics.age_over_34_percent) {
          input[inputName].demoAgeOver34 = account.demographics.age_over_34_percent;
        }

        if (account.demographics.male_percent) {
          input[inputName].demoGenderMale = account.demographics.male_percent;
        }

        if (account.demographics.female_percent) {
          input[inputName].demoGenderFemale = account.demographics.female_percent;
        }

        if (account.demographics.bot_or_unknown_percent) {
          input[inputName].demoGenderOther = account.demographics.bot_or_unknown_percent;
        }
      }

      if (account && account.metrics) {
        if (account.metrics.engagement_percentage) {
          input[inputName].engagementRate = account.metrics.engagement_percentage;
          input[inputName].engagementRateLastUpdated = getCurrentTimestamp();
        }

        if (account.metrics.sponsored_percentage) {
          input[inputName].sponsoredPercentage = account.metrics.sponsored_percentage;
        }
      }

      if (account.followers) {
        input[inputName].followers = account.followers;
        input[inputName].followersLastUpdated = getCurrentTimestamp();
      }

      if (account.audience_locations && account.audience_locations.length > 0) {
        const audienceUS = account.audience_locations.find(x => x.country_code === "US");
        const audienceCA = account.audience_locations.find(x => x.country_code === "CA");

        if (audienceUS && audienceUS.percentage) {
          input[inputName].audienceUS = audienceUS.percentage;
        }

        if (audienceCA && audienceCA.percentage) {
          input[inputName].audienceCA = audienceCA.percentage;
        }
      }

      if (account.bio) {
        input[inputName].bio = account.bio;
      }

      return await API.graphql(graphqlOperation(mutations[mutationName], escapeJson(input)));
    } else {
      throw new Error("Missing influencerId or instagramUsername");
    }
  },

  createTwitterStat: async (influencerId, twitterHandle, op = "create", influencer = null) => {
    if (influencerId && twitterHandle) {
      const response = await API.get("wellnessamplifiedapi", `/dovetale/accounts?platform=twitter&username=${twitterHandle}`);
    
      var account = response.account;

      const backupName = _.get(account, 'name', twitterHandle)

      if (influencer && !influencer.firstName && !influencer.lastName && backupName) {
        await API.graphql(graphqlOperation(mutations.updateInfluencer, escapeJson({
          updateInfluencerInput: {
            influencerId,
            unsubscribed: influencer.unsubscribed,
            ...splitNameOrAssignFirst(backupName)
          }
        })));
      }

      if (!account) {
        logger.handleTrace("No Dovetale TW Account found for " + twitterHandle)
        return
      }

      const inputName = op === "create" ? "createInfluencerStatTWInput" : "updateInfluencerStatTWInput";
      const mutationName =  op === "create" ? "createInfluencerStatTw" : "updateInfluencerStatTw";

      var input = {};
      input[inputName] = {
        influencerId: influencerId,
        username: twitterHandle
      }

      if (account && account.metrics && account.metrics.engagement_percentage) {
        input[inputName].engagementRate = account.metrics.engagement_percentage;
        input[inputName].engagementRateLastUpdated = getCurrentTimestamp();
      }

      if (account && account.followers) {
        input[inputName].followers = account.followers;
        input[inputName].followersLastUpdated = getCurrentTimestamp();
      }

      if (account && account.bio) {
        input[inputName].bio = account.bio;
      }

      return await API.graphql(graphqlOperation(mutations[mutationName], escapeJson(input)));
    } else {
      throw new Error("Missing influencerId or twitterHandle");
    }
  },

  createYoutubeStat: async (influencerId, youtubeUsername, youtubeUrl, op = "create", influencer = null) => {
    if (influencerId && (youtubeUsername || youtubeUrl)) {
      let apiUrl = `/dovetale/accounts?platform=youtube&username=${youtubeUsername}`
      if (youtubeUrl) {
        apiUrl = `/dovetale/accounts?url=${youtubeUrl}`
      }

      const response = await API.get("wellnessamplifiedapi", apiUrl);

      var account = response.account;

      const backupName = _.get(account, 'name', youtubeUsername)

      if (influencer && !influencer.firstName && !influencer.lastName && backupName) {
        await API.graphql(graphqlOperation(mutations.updateInfluencer, escapeJson({
          updateInfluencerInput: {
            influencerId,
            unsubscribed: influencer.unsubscribed,
            ...splitNameOrAssignFirst(backupName)
          }
        })));
      }

      if (!account) {
        logger.handleTrace("No Dovetale YT Account found for " + youtubeUsername + " " + youtubeUrl)
        return
      }

      const inputName = op === "create" ? "createInfluencerStatYTInput" : "updateInfluencerStatYTInput";
      const mutationName =  op === "create" ? "createInfluencerStatYt" : "updateInfluencerStatYt";

      var input = {};
      input[inputName] = {
        influencerId: influencerId,
        ...(youtubeUsername && {username: youtubeUsername}),
        ...(youtubeUrl && {channelUrl: youtubeUrl})
      }

      if (account && account.metrics) {
        if (account.metrics.engagement_percentage) {
          input[inputName].engagementRate = account.metrics.engagement_percentage;
          input[inputName].engagementRateLastUpdated = getCurrentTimestamp();
        }

        if (account.metrics.views_per_video) 
          input[inputName].viewsPerVideo = account.metrics.views_per_video;
      }

      if (account && account.subscribers) {
        input[inputName].subscribers = account.subscribers;
        input[inputName].subscribersLastUpdated = getCurrentTimestamp();
      }

      if (account && account.bio) {
        input[inputName].bio = account.bio;
      }

      return await API.graphql(graphqlOperation(mutations[mutationName], escapeJson(input)));
    } else {
      throw new Error("Missing influencerId or youtubeUsername or youtubeUrl");
    }
  },

  createInfluencerListEntry: async (influencerId, listId) => {
    const createInfluencerListInput = {
      influencerId: influencerId,
      listId: listId
    };

    return await API.graphql(graphqlOperation(mutations.createInfluencerList, {
      createInfluencerListInput: createInfluencerListInput
    }));
  }
};
