import React, { Component, Fragment } from 'react'
import { Modal, Button, Form} from 'react-bootstrap'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify';
import makeComponentTrashable from 'trashable-react';
import Bottleneck from 'bottleneck'
import Logger from '../../utils/Logger'
const logger = new Logger("AddToListModal.js");

class AddToListModal extends Component {
    gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

    constructor(props) {
        super(props)
        this.state = {
            lists: [],
            influencers: [],
            selectedList: null
        }
    }

    componentDidMount = () => {
        this.graphqlOp(graphqlOperation(queries.listLists))
        .then(response => {
            this.setState({
                lists: response.data.listLists,
                selectedList: response.data.listLists[0].listId
            })
        })
        .catch(logger.handleError)
    }

    handleSubmit = (event) => {
        // For each influencer checked, add to selected list
        const influencers = this.props.influencers
        const selectedList = this.state.selectedList

        logger.handleTrace('s: ' + selectedList)

        this.graphqlOp(graphqlOperation(queries.listInfluencersByListId, { listId: selectedList }))
        .then(response => {
            let influencersInList = response.data.listInfluencersByListId
            influencers.forEach((influencer, indexId) => {
                let found = influencersInList.findIndex(inList => inList.influencerId === influencer.influencerId)
                if (found === -1) {
                    const createInfluencerListInput = {
                        influencerId: influencer.influencerId,
                        listId: selectedList
                    }

                    this.graphqlOp(graphqlOperation(mutations.createInfluencerList, { createInfluencerListInput: createInfluencerListInput }))
                    .then(logger.handleTrace('Influencer added to list'))
                    .catch(logger.handleError)
                }

            })
        })
        .catch(logger.handleError)

        this.props.onHide()

    }

    handleListSelectChange = event => {
        this.setState({
            selectedList: event.target.value
        })
    }

    generateListSelect = () => {
        const lists = this.state.lists

        let listSelect = lists.map(
            list => <option key={list.listId} value={list.listId}>{list.name}</option>
        )

        return listSelect
    }

    rendorNoInfluencers = () =>
        <Fragment><p>You have not selected any influencers. Please go back and select one or more influencers to export.</p></Fragment>

    rendorAddToLists = () => {
        return (
            <Form id="AddToListForm" onSubmit={event => this.handleSubmit(event)}>
            <Form.Group controlId="lists">
                <Form.Label>Lists</Form.Label>
                <Form.Control as="select"
                    name="listId"
                    value={this.state.selectedList}
                    onChange={this.handleListSelectChange}>
                    {this.generateListSelect()}
                </Form.Control>
            </Form.Group>
        </Form>
        )
    }

    render() {
        return(
            <Modal onHide={this.props.onHide} show={this.props.show}>
                <Modal.Header >
                    <Modal.Title>Add To List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.influencers.length > 0 ? this.rendorAddToLists() : this.rendorNoInfluencers()}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.influencers.length > 0 && <Button variant="secondary" type="submit" onClick={this.handleSubmit}>Submit</Button>}
                    <Button variant="danger" onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
           </Modal>
        )
    }
}

export default makeComponentTrashable(AddToListModal)
