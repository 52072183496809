import React from 'react';
import withPermissions from '../security/withPermissions';
import { Route, Switch, Redirect } from 'react-router-dom';

import Influencers from '../pages/Influencers'
import EditInfluencer from '../components/influencer/EditInfluencer'
import InfluencerDetails from '../components/influencer/InfluencerDetails'
import DeleteInfluencer from '../components/influencer/DeleteInfluencer'
import InfluencerDupes from '../components/influencer/InfluencerDupes'
import Users from '../pages/Users'
import UserEdit from '../components/user/UserEdit'
import EditCampaign from '../components/campaign/EditCampaign'
import CampaignDetails from '../components/campaign/CampaignDetails'
import SocialPosts from '../components/common/SocialPosts'
import Campaigns from '../pages/Campaigns'
import DeleteCampaign from '../components/campaign/DeleteCampaign'
import ImportDovetaleCampaign from '../components/campaign/ImportDovetaleCampaign'
import Lists from '../pages/Lists'
import ImportDovetaleList from '../components/list/ImportDovetaleList'
import EditList from '../components/list/EditList'
import ListDetails from '../components/list/ListDetails'
import DeleteList from '../components/list/DeleteList'
import NotFound from '../pages/NotFound'
import Home from '../pages/Home'

export default withPermissions(
    (props) =>
    <React.Fragment>
        {props.permissions.can("deleteInfluencers") && <Route path='/influencers/delete/:id' component={DeleteInfluencer} />}
        {props.permissions.can("deleteLists") && <Route path='/lists/delete/:id' component={DeleteList} />}
        {props.permissions.can("deleteCampaigns") && <Route path='/campaigns/delete/:id' component={DeleteCampaign} />}
        <Switch>
        <Route exact path="/influencers" component={Influencers} />
        <Route path="/influencers/details/:id" component={InfluencerDetails} />
            {props.permissions.can("editInfluencers") && <Route path='/influencers/edit/:id' component={EditInfluencer} />}
            {props.permissions.can("createInfluencers") && <Route exact path='/influencers/create' render={props => <EditInfluencer {...props} /> } />}
            {props.permissions.can("createInfluencers") && <Route exact path='/influencers/dupe' component={InfluencerDupes} />}
            {/* This one is for forcing refresh of influencers */}
            {props.permissions.can("viewInfluencers") && <Route path='/influencers/r' component={() => <Redirect to="/influencers" />} />}
            {props.permissions.can("viewInfluencers") && <Route path='/influencers' component={Influencers} />}
            {props.permissions.can("editUsers") && <Route path='/users/edit/:id' component={UserEdit} />}
            {props.permissions.can("createUsers") && <Route exact path='/users/create' component={UserEdit} />}
            {/* This one is for forcing refresh of users */}
            {props.permissions.can("viewUsers") && <Route path='/users/r' component={() => <Redirect to="/users" />} />}
            {props.permissions.can("viewUsers") && <Route path='/users' component={Users} />}
            {props.permissions.can("createCampaigns") && <Route path='/campaigns/dovetale/:id/import' component={ImportDovetaleCampaign} />}
            {props.permissions.can("editCampaigns") && <Route path='/campaigns/edit/:id' component={EditCampaign} />}
            {props.permissions.can("viewCampaigns") && <Route path='/campaigns/details/:campaignId/posts/:influencerId' component={SocialPosts} />}
            {props.permissions.can("viewCampaigns") && <Route path='/campaigns/details/r/:id' component={props => <Redirect to={"/campaigns/details/" + props.match.params.id} />} />}
            {props.permissions.can("viewCampaigns") && <Route path='/campaigns/details/:id' component={CampaignDetails} />}
            {props.permissions.can("createCampaigns") && <Route exact path='/campaigns/create' component={EditCampaign} />}
            {/* This one is for forcing refresh of campaigns */}
            {props.permissions.can("viewCampaigns") && <Route path='/campaigns/r' component={() => <Redirect to="/campaigns" />} />}
            {props.permissions.can("viewCampaigns") && <Route path='/campaigns/:type' component={Campaigns} />}
            {props.permissions.can("viewCampaigns") && <Route path='/campaigns' component={Campaigns} />}
            {props.permissions.can("editLists") && <Route path='/lists/edit/:id' component={EditList} />}
            {/* This one is for forcing refresh of lists */}
            {props.permissions.can("viewLists") && <Route path='/lists/r' component={() => <Redirect to="/lists" />} />}
            {props.permissions.can("viewLists") && <Route path='/lists/details/:id' component={ListDetails} />}
            {props.permissions.can("createLists") && <Route exact path='/lists/create' component={EditList} />}
            {props.permissions.can("viewLists") && <Route path='/lists/dovetale/:id/import' component={ImportDovetaleList} />}
            {props.permissions.can("viewLists") && <Route path='/lists/:type' component={Lists} />}
            {props.permissions.can("viewLists") && <Route path='/lists' component={Lists} />}
            <Route exact path='/' component={Home} />
            <Route component={NotFound} />
        </Switch>
    </React.Fragment>
);
