import React from 'react';
import { Button, Nav, Jumbotron, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import withPermissions from '../security/withPermissions'
import Logger from '../utils/Logger'

const logger = new Logger("Home.js")

class Home extends React.Component {
    constructor(props) {
        logger.handleInfo("User arrived at main page")
        super(props)
    }

    render() {
        return (
            <Jumbotron>
                <div style={{maxWidth: 850 + 'px'}}>
                    <Image src="/images/walogo.png" fluid />
                    <h1>Welcome to the Wellness Amplified Database!</h1>
                </div>
                <Nav>
                    {this.props.permissions.can("viewInfluencers") && <Nav.Item>
                        <Link to={'/influencers'} className="nav-link"><Button>Influencers</Button></Link>
                    </Nav.Item>}
                    {/* {this.props.permissions.can("viewCampaigns") && <Nav.Item>
                        <Link to={'/campaigns'} className="nav-link"><Button>Campaigns</Button></Link>
                    </Nav.Item>} */}
                    {this.props.permissions.can("viewLists") && <Nav.Item>
                        <Link to={'/lists'} className="nav-link"><Button>Lists</Button></Link>
                    </Nav.Item>}
                    {this.props.permissions.can("viewUsers") && <Nav.Item>
                        <Link to={'/users'} className="nav-link"><Button>Users</Button></Link>
                    </Nav.Item>}
                </Nav>

            </Jumbotron>
        )
    }
}

export default withPermissions(Home);