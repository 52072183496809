import React from 'react'
import ConfirmDeleteModal from '../common/ConfirmDeleteModal';
import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { Redirect } from 'react-router-dom';
import makeComponentTrashable from 'trashable-react';
import Bottleneck from 'bottleneck'
import Logger from '../../utils/Logger'
const logger = new Logger("DeleteCampaign.js");
  

class DeleteCampaign extends React.Component {
    gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

    constructor(props) {
        super(props);
        this.state = {
          loadingCampaign: true,
          cancel: false,
          confirm: false
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.graphqlOp(graphqlOperation(queries.getCampaign, { campaignId: id }))
                .then(response => this.setState({ loadingCampaign: false, campaign: response.data.getCampaign }))
                .catch(logger.handleError);
        }
    }

    deleteCampaign = (id) => {
        this.graphqlOp(graphqlOperation(mutations.deleteCampaign, { campaignId: id }))
            .then(() => this.setState({confirm: true}))
            .catch(logger.handleError)
    }
    
    render() {
        return this.state.loadingCampaign ||
            <React.Fragment>
                {this.state.cancel && <Redirect to="/campaigns" />}
                {this.state.confirm && <Redirect to="/campaigns/r" />}
                <ConfirmDeleteModal
                    confirmmessage={"Are you sure you want to delete the campaign " + this.state.campaign.campaignName + "?"}
                    cancelaction={() => this.setState({cancel: true})}
                    confirmaction={() => this.deleteCampaign(this.state.campaign.campaignId)}
                />
            </React.Fragment>;
    }
}

export default makeComponentTrashable(DeleteCampaign)