import React from 'react'
import { Modal, Button} from 'react-bootstrap'
import PropTypes from 'prop-types';

class ConfirmDeleteModal extends React.Component {
    render() {
        return (
            <Modal show={true} onHide={this.props.cancelaction}>
                <Modal.Header >
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{this.props.confirmmessage}</p>
                    <p>**WARNING** This cannot be undone!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.cancelaction}>Cancel</Button>
                    <Button variant="danger" onClick={this.props.confirmaction}>Delete</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ConfirmDeleteModal.propTypes = {
    /** Confirmation message */
    confirmmessage: PropTypes.string.isRequired,
    /** Callback for cancel */
    cancelaction: PropTypes.func.isRequired,
    /** Callback for confirm */
    confirmaction: PropTypes.func.isRequired
}

export default ConfirmDeleteModal;