import React from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import uuid from 'uuid/v1'

import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Row,
  Table,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'

import * as queries from '../../graphql/queries'
import makeComponentTrashable from 'trashable-react';
import withPermissions from '../../security/withPermissions';
import { convertISOToLocalDate } from '../../utils/format'
import Bottleneck from 'bottleneck'
import Logger from '../../utils/Logger'
const logger = new Logger("ListDovetaleLists.js");

class ListDovetaleLists extends React.Component {

  gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

  constructor(props) {
    super(props);
    this.state = {
      loadingLists: true
    }
  }

  componentDidMount() {
    this.graphqlOp(graphqlOperation(queries.listLists))
      .then(waLists => {
        let dovetaleIds = waLists ? waLists.data.listLists.map(i => i.dovetaleId) : []
        API.get("wellnessamplifiedapi", `/dovetale/lists`)
          .then(dtLists => {
            let filteredLists = (dtLists && dtLists.lists) ? dtLists.lists.filter(i => dovetaleIds.indexOf(i.id) === -1) : []
            this.setState({ loadingLists: false, lists: filteredLists })
          })
          .catch(logger.handleError)
      })
      .catch(logger.handleError);

  }

  renderLoading = message => {
    return (
      <Row>
        <Col>
          <Alert variant="info" className="mt-4">Loading {message}...</Alert>
        </Col>
      </Row>
    )
  }

  renderAccount = data => {
    logger.handleTrace('renderAccount')
    if (!data) return
  }

  renderLists = data => {
    if (!data) return
    return (
      <div className="mt-4">
        <Table striped bordered>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => (
              <tr key={uuid()}>
                <td>
                  <ButtonGroup>
                    {this.props.permissions.can("syncLists") && <Link to={`/lists/dovetale/${item.id}/import`}><Button size="sm" variant="outline-secondary" >Import</Button></Link>}
                  </ButtonGroup>
                </td>
                <td>{item.name}</td>
                <td>{convertISOToLocalDate(item.created_at)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }

  renderDate = data => {
    if (data) {
      let d = new Date(data);
      return d.toLocaleString();
    }
  }

  renderLoading = message => {
    return (
      <Row>
        <Col>
          <Alert variant="info" className="mt-4">Loading {message}...</Alert>
        </Col>
      </Row>
    )
  }

  render() {
    const {
      loadingLists,
      lists } = this.state;

    return loadingLists ? this.renderLoading('Lists') : this.renderLists(lists);
  }
}

export default makeComponentTrashable(withPermissions(ListDovetaleLists))