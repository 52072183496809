import React, { Component } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { replaceNullFieldValues } from '../common/FormFieldStateHelper'
import { API } from 'aws-amplify';
import _ from 'lodash'
import makeComponentTrashable from 'trashable-react';
import Bottleneck from 'bottleneck'

class CustomTagsModal extends Component {
  gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

  constructor(props) {
    super(props)
    this.state = {
      influencer: props.influencer,
      customTags: props.influencer.customTags
    }
  }

  componentDidMount = () => {
    this.setState({
      influencer: replaceNullFieldValues(this.state.influencer),
      customTags: _.isNil(this.state.customTags) ? '' : this.state.customTags
    });
  }

  handleCustomTagsChange = (event) => {
    this.setState({
      customTags: event.target.value
    })
  }

  renderCustomTagsCard = () => {

    return (
      <Form.Group controlId="customTags">
        <p>Please enter a comma separated list of the tags for this influencer.</p>
        <Form.Control
          as="textarea"
          name="customTags"
          value={this.state.customTags}
          onChange={this.handleCustomTagsChange}
        />
      </Form.Group>
    )
  }

  handleSubmit = (event) => {

    const {
      customTags
    } = this.state

    this.props.onSubmit(customTags);
    
    this.props.onHide();
    
  }

  render() {
    return (
      <Modal onHide={this.props.onHide} show={this.props.show}>
        <Modal.Header>
          <Modal.Title>Tags</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderCustomTagsCard()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="submit" onClick={this.handleSubmit}>Save</Button>
          <Button variant="danger" onClick={this.props.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    )
  }

}

export default makeComponentTrashable(CustomTagsModal)