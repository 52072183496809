import React, {
    Component,
    Fragment
  } from 'react'
import { Card } from 'react-bootstrap'
import { renderExternalUrl, renderTimeStamp, renderDateStamp } from '../../utils/format'

const LabeledField = ({ label, data, subtext }) =>
  data ? <p><strong>{label}:</strong> {data} {subtext &&
     <Fragment><br /><i>{subtext}</i></Fragment>
    }</p>
  : null;

const renderPercent = (value) => value && (value.toFixed(3) + " %");
const renderDecimal = (value) => value && (value.toFixed(5));

const generateSharedFields = (input) => (
  <Fragment>
    <LabeledField label="Engagement Rate" data={renderDecimal(input.engagementRate)} subtext={renderTimeStamp(input.engagementRateLastUpdated)} />
    <LabeledField label="Followers" data={input.followers} subtext={renderTimeStamp(input.followersLastUpdated)} />
    <LabeledField label="Page Likes" data={input.pageLikes} subtext={renderTimeStamp(input.pageLikesLastUpdated)} />
    <LabeledField label="Subscribers" data={input.subscribers} subtext={renderTimeStamp(input.subscribersLastUpdated)} />
    <LabeledField label="Views Per Video" data={renderDecimal(input.viewsPerVideo)} />
    <LabeledField label="Bio" data={input.bio} />

    <LabeledField label="Earliest Post Date" data={renderDateStamp(input.earliestPostDate)} />
    <LabeledField label="Latest Post Date" data={renderDateStamp(input.latestPostDate)} />

    <LabeledField label="Sponsored" data={renderPercent(input.sponsoredPercentage)} />
    <LabeledField label="Age Under 21" data={renderPercent(input.demoAgeUnder21)} />
    <LabeledField label="Age 21-34" data={renderPercent(input.demoAge21To34)} />
    <LabeledField label="Age Over 34" data={renderPercent(input.demoAgeOver34)} />
    <LabeledField label="Gender Male" data={renderPercent(input.demoGenderMale)} />
    <LabeledField label="Gender Female" data={renderPercent(input.demoGenderFemale)} />
    <LabeledField label="Gender Other" data={renderPercent(input.demoGenderOther)} />

    <LabeledField label="Female 13-17" data={renderPercent(input.demoFemale13To17)} />
    <LabeledField label="Female 18-24" data={renderPercent(input.demoFemale18To24)} />
    <LabeledField label="Female 25-34" data={renderPercent(input.demoFemale25To34)} />
    <LabeledField label="Female 35-44" data={renderPercent(input.demoFemale35To44)} />
    <LabeledField label="Female 45-54" data={renderPercent(input.demoFemale45To54)} />
    <LabeledField label="Female 55-64" data={renderPercent(input.demoFemale55To64)} />
    <LabeledField label="Female Over 65" data={renderPercent(input.demoFemaleOver65)} />
    
    <LabeledField label="Male 13-17" data={renderPercent(input.demoMale13To17)} />
    <LabeledField label="Male 18-24" data={renderPercent(input.demoMale18To24)} />
    <LabeledField label="Male 25-34" data={renderPercent(input.demoMale25To34)} />
    <LabeledField label="Male 35-44" data={renderPercent(input.demoMale35To44)} />
    <LabeledField label="Male 45-54" data={renderPercent(input.demoMale45To54)} />
    <LabeledField label="Male 55-64" data={renderPercent(input.demoMale55To64)} />
    <LabeledField label="Male Over 65" data={renderPercent(input.demoMaleOver65)} />

    <LabeledField label="Audience US" data={renderPercent(input.audienceUS)} />
    <LabeledField label="Audience CA" data={renderPercent(input.audienceCA)} />

    <LabeledField label="Link" data={renderExternalUrl(input.link)} />
    <LabeledField label="Content URL" data={renderExternalUrl(input.contentUrl)} />
    <LabeledField label="Media URL" data={renderExternalUrl(input.mediaUrl)} />
    <LabeledField label="Thumbnail URL" data={renderExternalUrl(input.thumbnailUrl)} />

    <LabeledField label="Posted By" data={input.postedBy} />
 
    <LabeledField label="Retweets" data={input.retweets} />
    <LabeledField label="Likes" data={input.likes} />
    <LabeledField label="Dislikes" data={input.dislikes} />
    <LabeledField label="Reach" data={input.reach} />
    <LabeledField label="Estimated Impressions" data={input.estimatedImpressions} />
    <LabeledField label="Impressions" data={input.impressions} />
    <LabeledField label="Estimated Minutes Watched" data={input.estimatedMinutesWatched} />
    <LabeledField label="Engagements" data={input.engagements} />
    <LabeledField label="Comments" data={input.comments} />
    <LabeledField label="Shares" data={input.shares} />
    <LabeledField label="Reactions" data={input.reactions} />
    <LabeledField label="Replies" data={input.replies} />
    <LabeledField label="Views" data={input.views} />

    <LabeledField label="Sentiment Negative" data={renderPercent(input.sentimentNegative)} />
    <LabeledField label="Sentiment Neutral" data={renderPercent(input.sentimentNeutral)} />
    <LabeledField label="Sentiment Positive" data={renderPercent(input.sentimentPositive)} />
    <LabeledField label="Total Comments" data={input.totalComments} />
    <LabeledField label="Total Content Creators" data={input.totalContentCreators} />
    <LabeledField label="Total Engagement Rate" data={renderDecimal(input.totalEngagementRate)} />
    <LabeledField label="Total Engagements" data={input.totalEngagements} />
    <LabeledField label="Total Impressions" data={input.totalImpressions} />
    <LabeledField label="Total Likes" data={input.totalLikes} />
    <LabeledField label="Total Dislikes" data={input.totalDislikes} />
    <LabeledField label="Total Posts" data={input.totalPosts} />
    <LabeledField label="Total Reach" data={input.totalReach} />
    <LabeledField label="Total Shares" data={input.totalShares} />
    <LabeledField label="Total Retweets" data={input.totalRetweets} />
    <LabeledField label="Total Reactions" data={input.totalReactions} />
    <LabeledField label="Total Video Views" data={input.totalVideoViews} />
    <LabeledField label="Total Videos" data={input.totalVideos} />
    <LabeledField label="Total Views" data={input.totalViews} />
    <LabeledField label="Total Minutes Watched" data={input.totalMinutesWatched} />

    <LabeledField label="Title" data={input.title} />
    <LabeledField label="Description" data={input.description} />
    <LabeledField label="Message" data={input.message} />
    <LabeledField label="Caption" data={input.caption} />
    <LabeledField label="Tweet" data={input.tweet} />
    <LabeledField label="Tags" data={input.tags} />
  </Fragment>
)

class StatIG extends Component {
  render() {
    const input = this.props.data || {};
    const text = !this.props.data ? <p>No Data Available</p> :
      <Fragment>
        <LabeledField label="Handle" data={input.handle} />
        {generateSharedFields(input)}
      </Fragment>;

    return this.props.useCard ? (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Instagram</Card.Title>
          {text}
        </Card.Body>
      </Card>
    ) : text;
  }
}

class StatIGB extends Component {
  render() {
    const input = this.props.data || {};
    const text = !this.props.data ? <p>No Data Available</p> :
      <Fragment>
        <LabeledField label="Handle" data={input.handle} />
        {generateSharedFields(input)}
      </Fragment>

    return this.props.useCard ? (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Instagram Business</Card.Title>
          {text}
        </Card.Body>
      </Card>
    ) : text;
  }
}

class StatTW extends Component {
  render() {
    const input = this.props.data || {};
    const text = !this.props.data ? <p>No Data Available</p> :
      <Fragment>
        <LabeledField label="Username" data={input.username} />
        {generateSharedFields(input)}
      </Fragment>;

    return this.props.useCard ? (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Twitter</Card.Title>
          {text}
        </Card.Body>
      </Card>
    ) : text;
  }
}

class StatFB extends Component {
  render() {
    const input = this.props.data || {};
    const text = !this.props.data ? <p>No Data Available</p> :
      <Fragment>
        <LabeledField label="Username" data={input.username} />
        {generateSharedFields(input)}
      </Fragment>

    return this.props.useCard ? (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Facebook</Card.Title>
          {text}
        </Card.Body>
      </Card>
    ) : text;
  }
}

class StatYT extends Component {
  render() {
    const input = this.props.data || {};
    const text = !this.props.data ? <p>No Data Available</p> :
      <Fragment>
        <LabeledField label="Username" data={input.username} />
        <LabeledField label="Channel URL" data={renderExternalUrl(input.channelUrl)} />
        {generateSharedFields(input)}
      </Fragment>;

    return this.props.useCard ? (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>YouTube</Card.Title>
          {text}
        </Card.Body>
      </Card>
    ) : text;
  }
}

class StatPI extends Component {
  render() {
    const input = this.props.data || {};

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Pinterest</Card.Title>
          {!this.props.data.pinterestHandle && !this.props.data.pinterestFollowers ? <p>No Data Available</p> :
            <Fragment>
              <LabeledField label="Handle" data={input.pinterestHandle} />
              <LabeledField label="Followers" data={input.pinterestFollowers} subtext={renderTimeStamp(input.pinterestFollowersUpdatedTime)} />
            </Fragment>}
        </Card.Body>
      </Card>
    );
  }
}

class StatLI extends Component {
  render() {
    const input = this.props.data || {};

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>TikTok</Card.Title>
          {!this.props.data.linkedInURL && !this.props.data.linkedInFollowers ? <p>No Data Available</p> :
            <Fragment>
              <LabeledField label="URL" data={input.linkedInURL} />
              <LabeledField label="Followers" data={input.linkedInFollowers} subtext={renderTimeStamp(input.linkedInFollowersUpdatedTime)} />
            </Fragment>}
        </Card.Body>
      </Card>
    );
  }
}

class StatWB extends Component {
  render() {
    const input = this.props.data || {};

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Website</Card.Title>
          {!this.props.data.websiteURL && !this.props.data.websiteFollowers ? <p>No Data Available</p> :
            <Fragment>
              <LabeledField label="URL" data={renderExternalUrl(input.websiteURL)} />
              <LabeledField label="Blog UMV" data={input.websiteFollowers} subtext={renderTimeStamp(input.websiteFollowersUpdatedTime)} />
            </Fragment>}
        </Card.Body>
      </Card>
    );
  }
}

class StatSC extends Component {
  render() {
    const input = this.props.data || {};

    return (
      <Card className="mt-4" /*Snapchat field was reused to save the Threads Handle*/ >
        <Card.Body>
          <Card.Title>Threads</Card.Title>
          {!this.props.data.snapchatHandle ? <p>No Data Available</p> :
            <Fragment>
              <LabeledField label="Handle" data={input.snapchatHandle} />
            </Fragment>}
        </Card.Body>
      </Card>
    )
  }
}

class StatTM extends Component {
  render() {
    const input = this.props.data || {};

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Tumblr</Card.Title>
          {!this.props.data.tumblrHandle ? <p>No Data Available</p> :
            <Fragment>
              <LabeledField label="Handle" data={input.tumblrHandle} />
            </Fragment>}
        </Card.Body>
      </Card>
    )
  }
}

class StatBlog extends Component {
  render() {
    const input = this.props.data || {}
    const text = !this.props.data.link ? <p>No Data Available</p> : generateSharedFields(input)
    return this.props.useCard ? (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Blog</Card.Title>
            {text}
        </Card.Body>
      </Card>
    ) : text;
  }
}

export {StatIG, StatIGB, StatTW, StatFB, StatYT, StatPI, StatLI, StatWB, StatSC, StatTM, StatBlog}
