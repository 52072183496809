import React, { Component } from 'react'
import {
  Pagination,
} from 'react-bootstrap'
import PropTypes from 'prop-types'

class Paging extends Component {
  render() {
    const {active, pageCount} = this.props
    let items = []
    for (let number = 1; number <= pageCount; number++) {
      items.push(
        <Pagination.Item 
          key={number} 
          active={number === active}
          onClick = {() => {this.props.pageCallback(number)}}>
          {number}
        </Pagination.Item>
      );
    }  
   
    return (
      <Pagination>
        {items.length > 1 ? <Pagination.First onClick = {() => {this.props.pageCallback(1)}} /> : ""}
        {active > 1 ? <Pagination.Prev onClick = {() => {this.props.pageCallback(active - 1)}}/> : ''}
        <Pagination>{items}</Pagination>
        {active < pageCount ? <Pagination.Next onClick = {() => {this.props.pageCallback(active + 1)}}/> : ''}
        {items.length > 1 ? <Pagination.Last onClick = {() => {this.props.pageCallback(pageCount)}}/> : "" }
      </Pagination>

    )
  }
}

Paging.defaultProps = {
  pagesRendered:10
}

Paging.propTypes = {
  active: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageCallback: PropTypes.func.isRequired,
  pagesRendered: PropTypes.number,
}

export default Paging