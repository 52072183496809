// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:001a5bb5-e692-41f6-88dc-6b4959d7456e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_NAUUhqJFX",
    "aws_user_pools_web_client_id": "3t1vd03r9jjmj0623rk3g7hs4r",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "wellnessamplifiedapi",
            "endpoint": "https://3fm4ans4ch.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://j6hvnzis6bfb3lq6wrs2q5wrta.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "wa-appd91ba7c7cb534e15ba0d4f6e0828b185-master",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
