import React, { Component, Fragment } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import DovetaleClient from '../../client/DovetaleClient'
import uuid from 'uuid/v1'

import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
  Card
} from 'react-bootstrap'

import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import * as format from '../../utils/format'
import _ from 'lodash'
import url from 'url'
import makeComponentTrashable from 'trashable-react';

import { Redirect } from 'react-router-dom'
import Logger from '../../utils/Logger'
const logger = new Logger("ImportDovetaleCampaign.js");

const Platforms = {
  displayFB: 'Facebook',
  apiFB: 'facebook',
  displayIG: 'Instagram',
  apiIG: 'instagram',
  displayIGB: 'Instagram Business',
  apiIGB: 'instagram_business',
  displayTW: 'Twitter',
  apiTW: 'twitter',
  displayYT: 'YouTube',
  apiYT: 'youtube'
}

class DovetaleList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      importingFB: false,
      loadingFB: true,
      importingIG: false,
      loadingIG: true,
      importingIGB: false,
      loadingIGB: true,
      importingTW: false,
      loadingTW: true,
      importingYT: false,
      loadingYT: true,
      processing: false,
      redirect: false
    }
  }

  async componentDidMount() {
    const { id } = this.props.match.params

    API.get("wellnessamplifiedapi", `/dovetale/reports?id=${id}&platform=${Platforms.apiFB}&summary=true`)
      .then(summary => {
        this.setState({
          summaryFB: summary
        })
        this.setReportProperties(summary)
      })
    
    const contentFB = await this.getPlatformContent(id, Platforms.apiFB)
    this.setState({
      contentFB: contentFB,
      loadingFB: false
    })

    API.get("wellnessamplifiedapi", `/dovetale/reports?id=${id}&platform=${Platforms.apiIG}&summary=true`)
      .then(summary => {
        this.setState({
          summaryIG: summary
        })
        this.setReportProperties(summary)
      })

    const contentIG = await this.getPlatformContent(id, Platforms.apiIG)
    this.setState({
      contentIG: contentIG,
      loadingIG: false
    })

    API.get("wellnessamplifiedapi", `/dovetale/reports?id=${id}&platform=${Platforms.apiIGB}&summary=true`)
      .then(summary => {
        this.setState({
          summaryIGB: summary
        })
        this.setReportProperties(summary)
      })

    const contentIGB = await this.getPlatformContent(id, Platforms.apiIGB)
    this.setState({
      contentIGB: contentIGB,
      loadingIGB: false
    })

    API.get("wellnessamplifiedapi", `/dovetale/reports?id=${id}&platform=${Platforms.apiTW}&summary=true`)
      .then(summary => {
        this.setState({
          summaryTW: summary
        })
        this.setReportProperties(summary)
      })

    const contentTW = await this.getPlatformContent(id, Platforms.apiTW)
    this.setState({
      contentTW: contentTW,
      loadingTW: false
    })

    API.get("wellnessamplifiedapi", `/dovetale/reports?id=${id}&platform=${Platforms.apiYT}&summary=true`)
      .then(summary => {
        this.setState({
          summaryYT: summary
        })
        this.setReportProperties(summary)
      })

    const contentYT = await this.getPlatformContent(id, Platforms.apiYT)
    this.setState({
      contentYT: contentYT,
      loadingYT: false
    })
  }

  async getPlatformContent(reportId, platform, content, next_page_url) {

    let path = `/dovetale/reports?id=${reportId}&platform=${platform}`

    if (next_page_url) {
      let next = url.parse(next_page_url, true)
      path = `/dovetale/reports?id=${reportId}&platform=${platform}&page=${next.query.page}`
    }

    const result = await API.get("wellnessamplifiedapi", path)
    if (result.next_page_url) {
      return this.getPlatformContent(reportId, platform, result.content, result.next_page_url)
    } else {
      return _.concat(result.content, content)
    }
  }

  getCreatorsFromData(data) {
    if (!data || !data.length) {
      return []
    }
    let platformCreators = data
      .map(item => {
        if (item && item.account) {
          return item.account.username
        } else if (item && item.posted_by) {
          return item.posted_by
        } else {
          return null
        }
      })
      .filter(item => item !== null)
    platformCreators = [...new Set(platformCreators)].sort()
    return platformCreators
  }

  async importCampaign(dovetaleId) {

    logger.handleTrace(' * importCampaign')

    let campaignId = 0

    const existingCampaigns = await API.graphql(graphqlOperation(queries.listCampaigns))

    const match = existingCampaigns.data.listCampaigns && existingCampaigns.data.listCampaigns.length && existingCampaigns.data.listCampaigns.find(c => (c.dovetaleId && c.dovetaleId.toString()) === dovetaleId.toString())

    if (match) {
        await API.graphql(graphqlOperation(mutations.deleteCampaign, {campaignId: match.campaignId})).catch(logger.handleError)
      }
    let newCampaign = await API.graphql(graphqlOperation(mutations.createCampaign, {
      createCampaignInput: {
        campaignName: this.state.report_name,
        dovetaleId: this.state.report_id
      }
    })).catch(logger.handleError)
    campaignId = newCampaign.data.createCampaign.campaignId
    return campaignId
  }
  

  async importCampaignPlatformStats(campaignId) {

    logger.handleTrace(' * importCampaignPlatformStats')

    let campaignPlatformStats = []

    const facebookSummary = this.state.summaryFB

    if (!facebookSummary.error) {

      this.setState({ importingFB: true })

      const facebookDemographics = facebookSummary.demographics
      const earliestPostDate = new Date(facebookSummary.earliest_post_date)
      const latestPostDate = new Date(facebookSummary.latest_post_date)

      campaignPlatformStats.push(API.graphql(graphqlOperation(mutations.createCampaignStatFb, {
        createCampaignStatFBInput: {
          campaignId: campaignId,
          ...(_.isDate(earliestPostDate) && {earliestPostDate: format.getTimestampFromMilli(earliestPostDate.getTime())}),
          ...(_.isDate(latestPostDate) && {latestPostDate: format.getTimestampFromMilli(latestPostDate.getTime())}),
          ...(facebookSummary.total_content_creators && {totalContentCreators: facebookSummary.total_content_creators}),
          ...(facebookSummary.total_posts && {totalPosts: facebookSummary.total_posts}),
          ...(facebookSummary.total_impressions && {totalImpressions: facebookSummary.total_impressions}),
          ...(facebookSummary.total_reach && {totalReach: facebookSummary.total_reach}),
          ...(facebookSummary.total_likes && {totalLikes: facebookSummary.total_likes}),
          ...(facebookSummary.total_comments && {totalComments: facebookSummary.total_comments}),
          ...(facebookSummary.total_engagement_percent && {totalEngagementRate: facebookSummary.total_engagement_percent}),
          ...(facebookSummary.total_shares && {totalShares: facebookSummary.total_shares}),
          ...(facebookSummary.total_reactions && {totalReactions: facebookSummary.total_reactions}),
          ...(facebookDemographics.female_13_to_17_percent && {demoFemale13To17: facebookDemographics.female_13_to_17_percent}),
          ...(facebookDemographics.female_18_to_24_percent && {demoFemale18To24: facebookDemographics.female_18_to_24_percent}),
          ...(facebookDemographics.female_25_to_34_percent && {demoFemale25To34: facebookDemographics.female_25_to_34_percent}),
          ...(facebookDemographics.female_35_to_44_percent && {demoFemale35To44: facebookDemographics.female_35_to_44_percent}),
          ...(facebookDemographics.female_45_to_54_percent && {demoFemale45To54: facebookDemographics.female_45_to_54_percent}),
          ...(facebookDemographics.female_55_to_64_percent && {demoFemale55To64: facebookDemographics.female_55_to_64_percent}),
          ...(facebookDemographics.female_over_65_percent && {demoFemaleOver65: facebookDemographics.female_over_65_percent}),
          ...(facebookDemographics.female_percent && {demoFemale: facebookDemographics.female_percent}),
          ...(facebookDemographics.male_13_to_17_percent && {demoMale13To17: facebookDemographics.male_13_to_17_percent}),
          ...(facebookDemographics.male_18_to_24_percent && {demoMale18To24: facebookDemographics.male_18_to_24_percent}),
          ...(facebookDemographics.male_25_to_34_percent && {demoMale25To34: facebookDemographics.male_25_to_34_percent}),
          ...(facebookDemographics.male_35_to_44_percent && {demoMale35To44: facebookDemographics.male_35_to_44_percent}),
          ...(facebookDemographics.male_45_to_54_percent && {demoMale45To54: facebookDemographics.male_45_to_54_percent}),
          ...(facebookDemographics.male_55_to_64_percent && {demoMale55To64: facebookDemographics.male_55_to_64_percent}),
          ...(facebookDemographics.male_over_65_percent && {demoMaleOver65: facebookDemographics.male_over_65_percent}),
          ...(facebookDemographics.male_percent && {demoMale: facebookDemographics.male_percent}),
        }
      })))
    }

    const instagramSummary = this.state.summaryIG
    if (!instagramSummary.error) {

      this.setState({ importingIG: true })

      const instagramDemographics = instagramSummary.demographics
      const instagramAudience = instagramSummary.audience_locations
      const instagramSentiment = instagramSummary.comment_sentiment

      const usInstagramAudience = instagramAudience && instagramAudience.find(x => x.country_code === 'US')

      const caInstagramAudience = instagramAudience && instagramAudience.find(x => x.country_code === 'CA')

      const earliestPostDate = new Date(instagramSummary.earliest_post_date)
      const latestPostDate = new Date(instagramSummary.latest_post_date)

      const input = {
        createCampaignStatIGInput: {
          campaignId: campaignId,
          ...(_.isDate(earliestPostDate) && {earliestPostDate: format.getTimestampFromMilli(earliestPostDate.getTime())}),
          ...(_.isDate(latestPostDate) && {latestPostDate: format.getTimestampFromMilli(latestPostDate.getTime())}),
          ...(instagramSummary.total_content_creators && {totalContentCreators: instagramSummary.total_content_creators}),
          ...(instagramSummary.total_posts && {totalPosts: instagramSummary.total_posts}),
          ...(instagramSummary.total_reach && {totalReach: instagramSummary.total_reach}),
          ...(instagramSummary.total_impressions && {totalImpressions: instagramSummary.total_impressions}),
          ...(instagramSummary.total_likes && {totalLikes: instagramSummary.total_likes}),
          ...(instagramSummary.total_comments && {totalComments: instagramSummary.total_comments}),
          ...(instagramSummary.total_video_views && {totalVideoViews: instagramSummary.total_video_views}),
          ...(instagramSummary.total_engagements && {totalEngagements: instagramSummary.total_engagements}),
          ...(instagramSummary.total_engagement_percent && {totalEngagementRate: instagramSummary.total_engagement_percent}),
          ...(instagramDemographics.age_under_21_percent && {demoAgeUnder21: instagramDemographics.age_under_21_percent}),
          ...(instagramDemographics.age_21_to_34_percent && {demoAge21To34: instagramDemographics.age_21_to_34_percent}),
          ...(instagramDemographics.age_over_34_percent && {demoAgeOver34: instagramDemographics.age_over_34_percent}),
          ...(instagramDemographics.male_percent && {demoGenderMale: instagramDemographics.male_percent}),
          ...(instagramDemographics.female_percent && {demoGenderFemale: instagramDemographics.female_percent}),
          ...(instagramDemographics.bot_or_unknown_percent && {demoGenderOther: instagramDemographics.bot_or_unknown_percent}),
          ...(usInstagramAudience && usInstagramAudience.percentage && {audienceUS: usInstagramAudience.percentage}),
          ...(caInstagramAudience && caInstagramAudience.percentage && {audienceCA: caInstagramAudience.percentage}),
          ...(instagramSentiment.positive_percent && {sentimentPositive: instagramSentiment.positive_percent}),
          ...(instagramSentiment.neutral_percent && {sentimentNeutral: instagramSentiment.neutral_percent}),
          ...(instagramSentiment.negative_percent && {sentimentNegative: instagramSentiment.negative_percent}),
        }
      }

      campaignPlatformStats.push(API.graphql(graphqlOperation(mutations.createCampaignStatIg, input)))
    }

    const instagramBusinessSummary = this.state.summaryIGB

    if (!instagramBusinessSummary.error) {

      this.setState({ importingIGB: true })

      const igbDemographics = instagramBusinessSummary.demographics
      const igbAudience = instagramBusinessSummary.audience_locations

      const usIgbAudience = igbAudience && igbAudience.find(x => x.country_code === 'US')

      const caIgbudience = igbAudience && igbAudience.find(x => x.country_code === 'CA')

      const earliestPostDate = new Date(instagramBusinessSummary.earliest_post_date)
      const latestPostDate = new Date(instagramBusinessSummary.latest_post_date)

      campaignPlatformStats.push(API.graphql(graphqlOperation(mutations.createCampaignStatIgb, {
        createCampaignStatIGBInput: {
          campaignId: campaignId,
          ...(_.isDate(earliestPostDate) && {earliestPostDate: format.getTimestampFromMilli(earliestPostDate.getTime())}),
          ...(_.isDate(latestPostDate) && {latestPostDate: format.getTimestampFromMilli(latestPostDate.getTime())}),
          ...(instagramBusinessSummary.total_content_creators && {totalContentCreators: instagramBusinessSummary.total_content_creators}),
          ...(instagramBusinessSummary.total_posts && {totalPosts: instagramBusinessSummary.total_posts}),
          ...(instagramBusinessSummary.total_impressions && {totalImpressions: instagramBusinessSummary.total_impressions}),
          ...(instagramBusinessSummary.total_likes && {totalLikes: instagramBusinessSummary.total_likes}),
          ...(instagramBusinessSummary.total_comments && {totalComments: instagramBusinessSummary.total_comments}),
          ...(instagramBusinessSummary.total_engagement_percent && {totalEngagementRate: instagramBusinessSummary.total_engagement_percent}),
          ...(igbDemographics.female_13_to_17_percent && {demoFemale13To17: igbDemographics.female_13_to_17_percent}),
          ...(igbDemographics.female_18_to_24_percent && {demoFemale18To24: igbDemographics.female_18_to_24_percent}),
          ...(igbDemographics.female_25_to_34_percent && {demoFemale25To34: igbDemographics.female_25_to_34_percent}),
          ...(igbDemographics.female_35_to_44_percent && {demoFemale35To44: igbDemographics.female_35_to_44_percent}),
          ...(igbDemographics.female_45_to_54_percent && {demoFemale45To54: igbDemographics.female_45_to_54_percent}),
          ...(igbDemographics.female_55_to_64_percent && {demoFemale55To64: igbDemographics.female_55_to_64_percent}),
          ...(igbDemographics.female_over_65_percent && {demoFemaleOver65: igbDemographics.female_over_65_percent}),
          ...(igbDemographics.female_percent && {demoFemale: igbDemographics.female_percent}),
          ...(igbDemographics.male_13_to_17_percent && {demoMale13To17: igbDemographics.male_13_to_17_percent}),
          ...(igbDemographics.male_18_to_24_percent && {demoMale18To24: igbDemographics.male_18_to_24_percent}),
          ...(igbDemographics.male_25_to_34_percent && {demoMale25To34: igbDemographics.male_25_to_34_percent}),
          ...(igbDemographics.male_35_to_44_percent && {demoMale35To44: igbDemographics.male_35_to_44_percent}),
          ...(igbDemographics.male_45_to_54_percent && {demoMale45To54: igbDemographics.male_45_to_54_percent}),
          ...(igbDemographics.male_55_to_64_percent && {demoMale55To64: igbDemographics.male_55_to_64_percent}),
          ...(igbDemographics.male_over_65_percent && {demoMaleOver65: igbDemographics.male_over_65_percent}),
          ...(igbDemographics.male_percent && {demoMale: igbDemographics.male_percent}),
          ...(usIgbAudience && usIgbAudience.percentage && {audienceUS: usIgbAudience.percentage}),
          ...(caIgbudience && caIgbudience.percentage && {audienceCA: caIgbudience.percentage}),
        }
      })))
    }

    const twitterSummary = this.state.summaryTW

    if (!twitterSummary.error) {

      this.setState({ importingTW: true })

      const earliestPostDate = new Date(twitterSummary.earliest_post_date)
      const latestPostDate = new Date(twitterSummary.latest_post_date)

      campaignPlatformStats.push(API.graphql(graphqlOperation(mutations.createCampaignStatTw, {
        createCampaignStatTWInput: {
          campaignId: campaignId,
          ...(_.isDate(earliestPostDate) && {earliestPostDate: format.getTimestampFromMilli(earliestPostDate.getTime())}),
          ...(_.isDate(latestPostDate) && {latestPostDate: format.getTimestampFromMilli(latestPostDate.getTime())}),
          ...(twitterSummary.total_content_creators && {totalContentCreators: twitterSummary.total_content_creators}),
          ...(twitterSummary.total_posts && {totalPosts: twitterSummary.total_posts}),
          ...(twitterSummary.total_likes && {totalLikes: twitterSummary.total_likes}),
          ...(twitterSummary.total_retweets && {totalRetweets: twitterSummary.total_retweets}),
          ...(twitterSummary.total_engagement_percent && {totalEngagementRate: twitterSummary.total_engagement_percent}),
        }
      })))
    }

    const youTubeSummary = this.state.summaryYT

    if (!youTubeSummary.error) {

      this.setState({ importingYT: true })

      const youTubeDemographics = youTubeSummary.demographics
      const youTubeAudience = youTubeSummary.audience_locations

      const usYouTubeAudience = youTubeAudience && youTubeAudience.find(x => x.country_code === 'US')

      const caYouTubeAudience = youTubeAudience && youTubeAudience.find(x => x.country_code === 'CA')

      const earliestPostDate = new Date(youTubeSummary.earliest_post_date)
      const latestPostDate = new Date(youTubeSummary.latest_post_date)

      campaignPlatformStats.push(API.graphql(graphqlOperation(mutations.createCampaignStatYt, {
        createCampaignStatYTInput: {
          campaignId: campaignId,
          ...(_.isDate(earliestPostDate) && {earliestPostDate: format.getTimestampFromMilli(earliestPostDate.getTime())}),
          ...(_.isDate(latestPostDate) && {latestPostDate: format.getTimestampFromMilli(latestPostDate.getTime())}),
          ...(youTubeSummary.total_content_creators && {totalContentCreators: youTubeSummary.total_content_creators}),
          ...(youTubeSummary.total_videos && {totalVideos: youTubeSummary.total_videos}),
          ...(youTubeSummary.total_views && {totalViews: youTubeSummary.total_views}),
          ...(youTubeSummary.total_likes && {totalLikes: youTubeSummary.total_likes}),
          ...(youTubeSummary.total_dislikes && {totalDislikes: youTubeSummary.total_dislikes}),
          ...(youTubeSummary.total_comments && {totalComments: youTubeSummary.total_comments}),
          ...(youTubeSummary.total_engagements && {totalEngagements: youTubeSummary.total_engagements}),
          ...(youTubeSummary.total_engagement_percent && {totalEngagementRate: youTubeSummary.total_engagement_percent}),
          ...(youTubeSummary.total_minutes_watched && {totalMinutesWatched: youTubeSummary.total_minutes_watched}),
          ...(youTubeDemographics.female_13_to_17_percent && {demoFemale13To17: youTubeDemographics.female_13_to_17_percent}),
          ...(youTubeDemographics.female_18_to_24_percent && {demoFemale18To24: youTubeDemographics.female_18_to_24_percent}),
          ...(youTubeDemographics.female_25_to_34_percent && {demoFemale25To34: youTubeDemographics.female_25_to_34_percent}),
          ...(youTubeDemographics.female_35_to_44_percent && {demoFemale35To44: youTubeDemographics.female_35_to_44_percent}),
          ...(youTubeDemographics.female_45_to_54_percent && {demoFemale45To54: youTubeDemographics.female_45_to_54_percent}),
          ...(youTubeDemographics.female_55_to_64_percent && {demoFemale55To64: youTubeDemographics.female_55_to_64_percent}),
          ...(youTubeDemographics.female_over_65_percent && {demoFemaleOver65: youTubeDemographics.female_over_65_percent}),
          ...(youTubeDemographics.female_percent && {demoFemale: youTubeDemographics.female_percent}),
          ...(youTubeDemographics.male_13_to_17_percent && {demoMale13To17: youTubeDemographics.male_13_to_17_percent}),
          ...(youTubeDemographics.male_18_to_24_percent && {demoMale18To24: youTubeDemographics.male_18_to_24_percent}),
          ...(youTubeDemographics.male_25_to_34_percent && {demoMale25To34: youTubeDemographics.male_25_to_34_percent}),
          ...(youTubeDemographics.male_35_to_44_percent && {demoMale35To44: youTubeDemographics.male_35_to_44_percent}),
          ...(youTubeDemographics.male_45_to_54_percent && {demoMale45To54: youTubeDemographics.male_45_to_54_percent}),
          ...(youTubeDemographics.male_55_to_64_percent && {demoMale55To64: youTubeDemographics.male_55_to_64_percent}),
          ...(youTubeDemographics.male_over_65_percent && {demoMaleOver65: youTubeDemographics.male_over_65_percent}),
          ...(youTubeDemographics.male_percent && {demoMale: youTubeDemographics.male_percent}),
          ...(usYouTubeAudience && usYouTubeAudience.percentage && {audienceUS: usYouTubeAudience.percentage}),
          ...(caYouTubeAudience && caYouTubeAudience.percentage && {audienceCA: caYouTubeAudience.percentage}),
        }
      })))
    }

    return await Promise.all(campaignPlatformStats.map(p => p.catch(logger.handleError)))
  }

  async importInfluencersAndPosts(campaignId) {

    logger.handleTrace(' * importInfluencersAndPosts')

    const facebookContent = this.state.contentFB || []
    const instagramContent = this.state.contentIG || []
    const instagramBusinessContent = this.state.contentIGB || []
    const twitterContent = this.state.contentTW || []
    const youTubeContent = this.state.contentYT || []

    const facebookPosts = []
    const instagramPosts = []
    const instagramBusinessPosts = []
    const twitterPosts = []
    const youTubePosts = []

    const fbInfluencers = _.uniqBy(_.compact(facebookContent).map(entry => ({
      platform: Platforms.apiFB,
      username: _.get(entry, "account.username", entry.posted_by)
    })), 'username')
    const igInfluencers = _.uniqBy(_.compact(instagramContent).concat(_.compact(instagramBusinessContent)).map(entry => ({
      platform: Platforms.apiIG,
      username: _.get(entry, "account.username", entry.posted_by)
    })), 'username')
    const twInfluencers = _.uniqBy(_.compact(twitterContent).map(entry => ({
      platform: Platforms.apiTW,
      username: _.get(entry, "account.username", entry.posted_by)
    })), 'username')
    const ytInfluencers = _.uniqBy(_.compact(youTubeContent).map(entry => ({
      platform: Platforms.apiYT,
      url: _.get(entry, "account.youtubeUrl", null),
      username: _.get(entry, "account.username", entry.posted_by)
    })), 'url')

    const allInfs = fbInfluencers.concat(igInfluencers).concat(twInfluencers).concat(ytInfluencers);

    const newMap = await this.mapInfluencersForPlatform(allInfs, campaignId);

    const fbMap = newMap.filter(x => x.platform === Platforms.apiFB);
    const twMap = newMap.filter(x => x.platform === Platforms.apiTW);
    const igMap = newMap.filter(x => x.platform === Platforms.apiIG);
    const ytMap = newMap.filter(x => x.platform === Platforms.apiYT);

    for (const content of facebookContent) {

      if (!content) continue;

      let match = fbMap.find(x => x.username.toLowerCase() === _.get(content, "account.username", content.posted_by).toLowerCase());
      if (!match) continue;
      const influencerId = match.id;

      facebookPosts.push(API.graphql(graphqlOperation(mutations.createPostFacebook, format.escapeJson({
        createPostFacebookInput: {
          influencerId,
          campaignId: campaignId,
          ...(content.link && {link: content.link}),
          ...(content.demographics.female_13_to_17_percent && {demoFemale13To17: content.demographics.female_13_to_17_percent}),
          ...(content.demographics.female_18_to_24_percent && {demoFemale18To24: content.demographics.female_18_to_24_percent}),
          ...(content.demographics.female_25_to_34_percent && {demoFemale25To34: content.demographics.female_25_to_34_percent}),
          ...(content.demographics.female_35_to_44_percent && {demoFemale35To44: content.demographics.female_35_to_44_percent}),
          ...(content.demographics.female_45_to_54_percent && {demoFemale45To54: content.demographics.female_45_to_54_percent}),
          ...(content.demographics.female_55_to_64_percent && {demoFemale55To64: content.demographics.female_55_to_64_percent}),
          ...(content.demographics.female_over_65_percent && {demoFemaleOver65: content.demographics.female_over_65_percent}),
          ...(content.demographics.female_percent && {demoFemale: content.demographics.female_percent}),
          ...(content.demographics.male_13_to_17_percent && {demoMale13To17: content.demographics.male_13_to_17_percent}),
          ...(content.demographics.male_18_to_24_percent && {demoMale18To24: content.demographics.male_18_to_24_percent}),
          ...(content.demographics.male_25_to_34_percent && {demoMale25To34: content.demographics.male_25_to_34_percent}),
          ...(content.demographics.male_35_to_44_percent && {demoMale35To44: content.demographics.male_35_to_44_percent}),
          ...(content.demographics.male_45_to_54_percent && {demoMale45To54: content.demographics.male_45_to_54_percent}),
          ...(content.demographics.male_55_to_64_percent && {demoMale55To64: content.demographics.male_55_to_64_percent}),
          ...(content.demographics.male_over_65_percent && {demoMaleOver65: content.demographics.male_over_65_percent}),
          ...(content.demographics.male_percent && {demoMale: content.demographics.male_percent}),
          ...(content.engagement_percent && {engagementRate: content.engagement_percent}),
          ...(content.impressions && {impressions: content.impressions}),
          ...(content.likes && {likes: content.likes}),
          ...(content.message && {message: content.message}),
          ...(content.posted_at && {postedAt: format.getTimestampFromDateString(content.posted_at)}),
          ...(content.posted_by && {postedBy: content.posted_by}),
          ...(content.reach && {reach: content.reach}),
          ...(content.reactions && {reactions: content.reactions}),
          ...(content.shares && {shares: content.shares}),
          ...(content.tags && content.tags.length && {tags: content.tags.join(', ')}),
          ...(content.thumbnail_url && {thumbnailUrl: content.thumbnail_url}),
        }
      }))))
    }

    for (const content of instagramContent) {

      if (!content) continue;
      let match = igMap.find(x => x.username.toLowerCase() === _.get(content, "account.username", content.posted_by).toLowerCase());
      if (!match) continue;
      const influencerId = match.id;

      const input = format.escapeJson({createPostInstagramInput: {
        influencerId,
        campaignId: campaignId,
        ...(content.link && {link: content.link}),
        ...(content.content_url && {contentUrl: content.content_url}),
        ...(content.caption && {caption: content.caption}),
        ...(content.posted_at && {postedAt: format.getTimestampFromDateString(content.posted_at)}),
        ...(content.posted_by && {postedBy: content.posted_by}),
        ...(content.engagement_percent && {engagementRate: content.engagement_percent}),
        ...(content.estimated_impressions && {estimatedImpressions: content.estimated_impressions}),
        ...(content.likes && {likes: content.likes}),
        ...(content.demographics.age_under_21_percent && {demoAgeUnder21: content.demographics.age_under_21_percent}),
        ...(content.demographics.age_21_to_34_percent && {demoAge21To34: content.demographics.age_21_to_34_percent}),
        ...(content.demographics.age_over_34_percent && {demoAgeOver34: content.demographics.age_over_34_percent}),
        ...(content.demographics.male_percent && {demoGenderMale: content.demographics.male_percent}),
        ...(content.demographics.female_percent && {demoGenderFemale: content.demographics.female_percent}),
        ...(content.demographics.bot_or_unknown_percent && {demoGenderOther: content.demographics.bot_or_unknown_percent}),
        ...(content.tags && content.tags.length && {tags: content.tags.join(', ')}),
      }})

      instagramPosts.push(API.graphql(graphqlOperation(mutations.createPostInstagram, input)))
    }

    for (const content of instagramBusinessContent) {

      if (!content) continue;

      let match = igMap.find(x => x.username.toLowerCase() === _.get(content, "account.username", content.posted_by).toLowerCase());
      if (!match) continue;
      const influencerId = match.id;

      instagramBusinessPosts.push(API.graphql(graphqlOperation(mutations.createPostInstagramBusiness, format.escapeJson({
        createPostInstagramBusinessInput: {
          influencerId,
          campaignId: campaignId,
          ...(content.link && {link: content.link}),
          ...(content.caption && {caption: content.caption}),
          ...(content.demographics.female_13_to_17_percent && {demoFemale13To17: content.demographics.female_13_to_17_percent}),
          ...(content.demographics.female_18_to_24_percent && {demoFemale18To24: content.demographics.female_18_to_24_percent}),
          ...(content.demographics.female_25_to_34_percent && {demoFemale25To34: content.demographics.female_25_to_34_percent}),
          ...(content.demographics.female_35_to_44_percent && {demoFemale35To44: content.demographics.female_35_to_44_percent}),
          ...(content.demographics.female_45_to_54_percent && {demoFemale45To54: content.demographics.female_45_to_54_percent}),
          ...(content.demographics.female_55_to_64_percent && {demoFemale55To64: content.demographics.female_55_to_64_percent}),
          ...(content.demographics.female_over_65_percent && {demoFemaleOver65: content.demographics.female_over_65_percent}),
          ...(content.demographics.female_percent && {demoFemale: content.demographics.female_percent}),
          ...(content.demographics.male_13_to_17_percent && {demoMale13To17: content.demographics.male_13_to_17_percent}),
          ...(content.demographics.male_18_to_24_percent && {demoMale18To24: content.demographics.male_18_to_24_percent}),
          ...(content.demographics.male_18_to_24_percent && {demoMale25To34: content.demographics.male_18_to_24_percent}),
          ...(content.demographics.male_25_to_34_percent && {demoMale35To44: content.demographics.male_25_to_34_percent}),
          ...(content.demographics.male_35_to_44_percent && {demoMale45To54: content.demographics.male_35_to_44_percent}),
          ...(content.demographics.male_45_to_54_percent && {demoMale55To64: content.demographics.male_45_to_54_percent}),
          ...(content.demographics.male_55_to_64_percent && {demoMaleOver65: content.demographics.male_55_to_64_percent}),
          ...(content.demographics.male_percent && {demoMale: content.demographics.male_percent}),
          ...(content.engagement_percent && {engagementRate: content.engagement_percent}),
          ...(content.impressions && {impressions: content.impressions}),
          ...(content.likes && {likes: content.likes}),
          ...(content.media_url && {mediaUrl: content.media_url}),
          ...(content.posted_at && {postedAt: format.getTimestampFromDateString(content.posted_at)}),
          ...(content.posted_by && {postedBy: content.posted_by}),
          ...(content.reach && {reach: content.reach}),
          ...(content.replies && {replies: content.replies}),
          ...(content.tags && content.tags.length && {tags: content.tags.join(",")}),
        }
      }))))
    }

    for (const content of twitterContent) {

      if (!content) continue;

      let match = twMap.find(x => x.username.toLowerCase() === _.get(content, "account.username", content.posted_by).toLowerCase());
      if (!match) continue;
      const influencerId = match.id;

      twitterPosts.push(API.graphql(graphqlOperation(mutations.createPostTwitter, format.escapeJson({
        createPostTwitterInput: {
          influencerId,
          campaignId: campaignId,
          ...(content.link && {link: content.link}),
          ...(content.engagement_percent && {engagementRate: content.engagement_percent}),
          ...(content.followers && {followers: content.followers}),
          ...(content.likes && {likes: content.likes}),
          ...(content.posted_at && {postedAt: format.getTimestampFromDateString(content.posted_at)}),
          ...(content.posted_by && {postedBy: content.posted_by}),
          ...(content.reach && {reach: content.reach}),
          ...(content.retweets && {retweets: content.retweets}),
          ...(content.tags && content.tags.length && {tags: content.tags.join(', ')}),
          ...(content.tweet && {tweet: content.tweet}),
        }
      }))))
    }

    for (const content of youTubeContent) {

      if (!content) continue;

      let match = ytMap.find(x => x.username.toLowerCase() === _.get(content, "account.username", content.posted_by).toLowerCase() || x.url.toLowerCase() === _.get(content, "account.youtubeUrl", content.posted_by).toLowerCase());
      if (!match) continue;
      const influencerId = match.id;

      const youTubeAudience = content.audience_locations

      const usYouTubeAudience = youTubeAudience && youTubeAudience.find(x => x.country_code === 'US')

      const caYouTubeAudience = youTubeAudience && youTubeAudience.find(x => x.country_code === 'CA')

      youTubePosts.push(API.graphql(graphqlOperation(mutations.createPostYoutube, format.escapeJson({
        createPostYoutubeInput: {
          influencerId,
          campaignId: campaignId,
          ...(content.link && {link: content.link}),
          ...(content.comments && {comments: content.comments}),
          ...(content.demographics.female_13_to_17_percent && {demoFemale13To17: content.demographics.female_13_to_17_percent}),
          ...(content.demographics.female_18_to_24_percent && {demoFemale18To24: content.demographics.female_18_to_24_percent}),
          ...(content.demographics.female_25_to_34_percent && {demoFemale25To34: content.demographics.female_25_to_34_percent}),
          ...(content.demographics.female_35_to_44_percent && {demoFemale35To44: content.demographics.female_35_to_44_percent}),
          ...(content.demographics.female_45_to_54_percent && {demoFemale45To54: content.demographics.female_45_to_54_percent}),
          ...(content.demographics.female_55_to_64_percent && {demoFemale55To64: content.demographics.female_55_to_64_percent}),
          ...(content.demographics.female_over_65_percent && {demoFemaleOver65: content.demographics.female_over_65_percent}),
          ...(content.demographics.female_percent && {demoFemale: content.demographics.female_percent}),
          ...(content.demographics.male_13_to_17_percent && {demoMale13To17: content.demographics.male_13_to_17_percent}),
          ...(content.demographics.male_18_to_24_percent && {demoMale18To24: content.demographics.male_18_to_24_percent}),
          ...(content.demographics.male_25_to_34_percent && {demoMale25To34: content.demographics.male_25_to_34_percent}),
          ...(content.demographics.male_35_to_44_percent && {demoMale35To44: content.demographics.male_35_to_44_percent}),
          ...(content.demographics.male_45_to_54_percent && {demoMale45To54: content.demographics.male_45_to_54_percent}),
          ...(content.demographics.male_55_to_64_percent && {demoMale55To64: content.demographics.male_55_to_64_percent}),
          ...(content.demographics.male_over_65_percent && {demoMaleOver65: content.demographics.male_over_65_percent}),
          ...(content.demographics.male_percent && {demoMale: content.demographics.male_percent}),
          ...(content.description && {description: content.description}),
          ...(content.dislikes && {dislikes: content.dislikes}),
          ...(content.engagement_percent && {engagementRate: content.engagement_percent}),
          ...(content.estimated_minutes_watched && {estimatedMinutesWatched: content.estimated_minutes_watched}),
          ...(content.likes && {likes: content.likes}),
          ...(content.posted_at && {postedAt: format.getTimestampFromDateString(content.posted_at)}),
          ...(content.posted_by && {postedBy: content.posted_by}),
          ...(content.shares && {shares: content.shares}),
          ...(content.tags && content.tags.length && {tags: content.tags.join(', ')}),
          ...(content.thumbnail_url && {thumbnailUrl: content.thumbnail_url}),
          ...(content.title && {title: content.title}),
          ...(content.views && {views: content.views}),
          ...(usYouTubeAudience && usYouTubeAudience.percent_of_views && {audienceUS: usYouTubeAudience.percent_of_views}),
          ...(caYouTubeAudience && caYouTubeAudience.percent_of_views && {audienceCA: caYouTubeAudience.percent_of_views}),
        }
      }))))
    }

    let facebookResults = await Promise.all(facebookPosts.map(p => p.catch(logger.handleError)))
      .catch(logger.handleError)
      .then(() => this.setState({ importingFB: false }))

    let instagramResults = await Promise.all(instagramPosts.map(p => p.then(logger.handleTrace).catch(logger.handleError)))
      .catch(logger.handleError)
      .then(() => this.setState({ importingIG: false }))

    let instagramBusinessResults = await Promise.all(instagramBusinessPosts.map(p => p.catch(logger.handleError)))
      .catch(logger.handleError)
      .then(() => this.setState({ importingIGB: false }))

    let twitterResults = await Promise.all(twitterPosts.map(p => p.catch(logger.handleError)))
      .catch(logger.handleError)
      .then(() => this.setState({ importingTW: false }))

    let youTubeResults = await Promise.all(youTubePosts.map(p => p.catch(logger.handleError)))
      .catch(logger.handleError)
      .then(() => this.setState({ importingYT: false }))

    let results = [facebookResults, instagramResults, instagramBusinessResults, twitterResults, youTubeResults]

    logger.handleTrace('   importInfluencersAndPosts done')
    return results
  }

  async mapInfluencersForPlatform(listOfInfluencers, campaignId) {
    const entries = [];
    if (!listOfInfluencers.length) return listOfInfluencers;

    listOfInfluencers.forEach(entry => {
      switch (entry.platform) {
        case Platforms.apiFB:
          entries.push({ FBusername: {eq: entry.username} })
          break;
        case Platforms.apiIG:
          entries.push({ IGhandle: {eq: entry.username} })
          break;
        case Platforms.apiTW:
          entries.push({ TWusername: {eq: entry.username} })
          break;
        case Platforms.apiYT:
          if (entry.username) entries.push({YTusername: {eq: entry.username}})
          if (entry.url) entries.push({YTchannelUrl: {eq: entry.url}})
          break;
        default:
          return;
      }
    });

    const filterChunks = _.chunk(entries, 500)
    const influencerPromises = filterChunks.map(filters => API.graphql(graphqlOperation(queries.listInfluencers, format.escapeJson({filter : { or : filters}}))))

    const dupes = await Promise.all(influencerPromises.map(p => p.catch(logger.handleError)));

    let influencersAll = _.flatten(dupes.filter(result => !_.isNil(result.data.listInfluencers)).map(result => result.data.listInfluencers))

    if (!influencersAll.length) influencersAll = [];

    let fbDupeMatch = [];
    let ytDupeMatch = [];
    let twDupeMatch = [];
    let igDupeMatch = [];

    influencersAll.forEach(inf => {
      if (inf.FBusername) fbDupeMatch.push({username: inf.FBusername, id: inf.influencerId, inf})
      if (inf.TWusername) twDupeMatch.push({username: inf.TWusername, id: inf.influencerId, inf})
      if (inf.IGhandle) igDupeMatch.push({username: inf.IGhandle, id: inf.influencerId, inf})
      if (inf.YTusername || inf.YTchannelUrl) ytDupeMatch.push({
        ...(inf.YTusername && {username: inf.YTusername}), 
        ...(inf.YTchannelUrl && {url: inf.YTchannelUrl}), 
        id: inf.influencerId,
        inf
      })
    })

    const newInfMap = listOfInfluencers.map(inf => {
      let match;
      switch (inf.platform) {
        case Platforms.apiFB:
          match = fbDupeMatch.find(x => x.username.toLowerCase() === inf.username.toLowerCase())
          break;
        case Platforms.apiIG:
          match = igDupeMatch.find(x => x.username.toLowerCase() === inf.username.toLowerCase())
          break;
        case Platforms.apiTW:
          match = twDupeMatch.find(x => x.username.toLowerCase() === inf.username.toLowerCase())
          break;
        case Platforms.apiYT:
          match = ytDupeMatch.find(x => x.username.toLowerCase() === inf.username.toLowerCase() || x.url.toLowerCase() === inf.url.toLowerCase())
          break;
        default:
          break;
      }
      let newInf = {...inf};
      if (match) {
        newInf.id = match.id;
        newInf.inf = match.inf;
      }
      return newInf;
    })

    const infsToCreate = newInfMap.filter(x => !x.id);

    let infCreationPromises = infsToCreate.map(inf => {
      const createInfluencerInput = {
        unsubscribed: 0,
        creationSource: "Dovetale Campaign Import"
      }
      return API.graphql(graphqlOperation(mutations.createInfluencer, format.escapeJson({
        createInfluencerInput
      }))).then(newInf => {inf.id = newInf.data.createInfluencer.influencerId; inf.inf = newInf.data.createInfluencer})
    })

    await Promise.all(infCreationPromises.map(p => p.catch(logger.handleError)));

    const infCampaignPromises = _.uniq(newInfMap.map(x => x.id)).map(influencerId => {
      if (!(campaignId && influencerId)) return Promise.resolve(false);
      return API.graphql(graphqlOperation(mutations.createInfluencerCampaign, {
        createInfluencerCampaignInput: {
          influencerId,
          campaignId: campaignId
        }
      }));
    })

    await Promise.all(infCampaignPromises.map(p => p.catch(logger.handleError)));

    const infStatPromises = newInfMap.map(entry => {
      const influencer = entry.inf;
      const influencerId = entry.id;
      const fbNew = !Object.keys(influencer).filter(x => x.startsWith("FB")).some(key => !_.isNil(influencer[key]));
      const igNew = !Object.keys(influencer).filter(x => x.startsWith("IG")).some(key => !_.isNil(influencer[key]));
      const twNew = !Object.keys(influencer).filter(x => x.startsWith("TW")).some(key => !_.isNil(influencer[key]));
      const ytNew = !Object.keys(influencer).filter(x => x.startsWith("YT")).some(key => !_.isNil(influencer[key]));

      switch (entry.platform) {
        case Platforms.apiFB:
          logger.handleTrace(`   * createFacebookStat`)
          return DovetaleClient.createFacebookStat(influencerId, entry.username, fbNew ? "create" : "update", influencer)
            .then(logger.handleTrace(`     createFacebookStat done`))
        case Platforms.apiIG:
            logger.handleTrace(`   * createInstagramStat`)
          return DovetaleClient.createInstagramStat(influencerId, entry.username, igNew ? "create" : "update", influencer)
            .then(logger.handleTrace(`     createInstagramStat done`))
        case Platforms.apiTW:
          logger.handleTrace(`   * createTwitterStat`)
          return DovetaleClient.createTwitterStat(influencerId, entry.username, twNew ? "create" : "update", influencer)
            .then(logger.handleTrace(`     createTwitterStat done`))
        case Platforms.apiYT:
          logger.handleTrace(`   * createYoutubeStat`)
          return DovetaleClient.createYoutubeStat(influencerId, entry.username, entry.url, ytNew ? "create" : "update", influencer)
            .then(logger.handleTrace(`     createYoutubeStat done`))
        default:
          logger.handleError("No API Detected")
          return Promise.resolve(false)
      }
    })

    await Promise.all(infStatPromises.map(p => p.catch(logger.handleError)));

    return newInfMap;
  }

  handleSubmit = async event => {
    logger.handleTrace(` * handleSubmit`)
    event.preventDefault()

    this.setState({processing: true})

    const campaignId = await this.importCampaign(this.props.match.params.id)
    this.setState({ campaignId: campaignId })

    await this.importCampaignPlatformStats(campaignId).then(
      logger.handleTrace('   importCampaignPlatformStats done')
    )
    await this.importInfluencersAndPosts(campaignId).then(
      logger.handleTrace('   importInfluencersAndPosts done')
    )

    this.setState({ redirect: true });
  }

  // all of the platform reports contain the report ID and name, 
  // but we don't know which platform reports we'll get 
  setReportProperties = (data) => {
    if (!data.error && !this.state.report_name) {
      this.setState({
        report_name: data.report_name,
        report_id: data.report_id
      })
    }
  }

  renderInfluencers = (platform) => {

    let platformCreators = {}
    let cardTitle = ''

    switch (platform) {
      case Platforms.apiFB:
        platformCreators = this.getCreatorsFromData(this.state.contentFB)
        cardTitle = Platforms.displayFB
        break
      case Platforms.apiIG:
        platformCreators = this.getCreatorsFromData(this.state.contentIG)
        cardTitle = Platforms.displayIG
        break
      case Platforms.apiIGB:
        platformCreators = this.getCreatorsFromData(this.state.contentIGB)
        cardTitle = Platforms.displayIGB
        break
      case Platforms.apiTW:
        platformCreators = this.getCreatorsFromData(this.state.contentTW)
        cardTitle = Platforms.displayTW
        break
      case Platforms.apiYT:
        platformCreators = this.getCreatorsFromData(this.state.contentYT)
        cardTitle = Platforms.displayYT
        break
      default:
        platformCreators = []
    }

    if (!platformCreators.length) {
      return (
        <Card className="mt-4">
          <Card.Body>
            <Card.Title>
              <h3>{cardTitle}</h3>
            </Card.Title>
            <p>No creator content for this platform.</p>
          </Card.Body>
        </Card>
      )
    } else {
      return (
        <Card className="mt-4">
          <Card.Body>
            <Card.Title>
              <h3>{cardTitle}</h3>
            </Card.Title>
            {platformCreators.map(item => (
              <div key={uuid()}>{item}</div>
            ))}
          </Card.Body>
        </Card>
      )
    }
  }

  renderPlatform = platform => {

    const {
      importingFB,
      loadingFB,
      importingIG,
      loadingIG,
      importingIGB,
      loadingIGB,
      importingTW,
      loadingTW,
      importingYT,
      loadingYT
    } = this.state;

    if (platform === Platforms.apiFB) {
      if (loadingFB) {
        return this.renderLoading(`Loading ${Platforms.displayFB}...`)
      } else if (importingFB) {
        return this.renderLoading(`Importing ${Platforms.displayFB}...`)
      } else {
        return this.renderInfluencers(platform)
      }
    }

    if (platform === Platforms.apiIG) {
      if (loadingIG) {
        return this.renderLoading(`Loading ${Platforms.displayIG}...`)
      } else if (importingIG) {
        return this.renderLoading(`Importing ${Platforms.displayIG}...`)
      } else {
        return this.renderInfluencers(platform)
      }
    }

    if (platform === Platforms.apiIGB) {
      if (loadingIGB) {
        return this.renderLoading(`Loading ${Platforms.displayIGB}...`)
      } else if (importingIGB) {
        return this.renderLoading(`Importing ${Platforms.displayIGB}...`)
      } else {
        return this.renderInfluencers(platform)
      }
    }

    if (platform === Platforms.apiTW) {
      if (loadingTW) {
        return this.renderLoading(`Loading ${Platforms.displayTW}...`)
      } else if (importingTW) {
        return this.renderLoading(`Importing ${Platforms.displayTW}...`)
      } else {
        return this.renderInfluencers(platform)
      }
    }

    if (platform === Platforms.apiYT) {
      if (loadingYT) {
        return this.renderLoading(`Loading ${Platforms.displayYT}...`)
      } else if (importingYT) {
        return this.renderLoading(`Importing ${Platforms.displayYT}...`)
      } else {
        return this.renderInfluencers(platform)
      }
    }
  }

  renderLoading = message => {
    return (
      <Row>
        <Col>
          <Alert variant="info" className="mt-4">{message}...</Alert>
        </Col>
      </Row>
    )
  }

  render() {
    const {
      redirect,
      processing,
      loadingFB,
      loadingIG,
      loadingIGB,
      loadingTW,
      loadingYT
    } = this.state

    const loading = loadingFB || loadingIG || loadingIGB || loadingTW || loadingYT

    return (
      <Container fluid>
        {redirect && <Redirect to={"/campaigns/details/" + this.state.campaignId} />}
        <Row>
          <Col>
            <h1>Import Dovetale Campaign <span className="text-secondary">{this.state.report_name}</span></h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Fragment>
              {this.renderPlatform(Platforms.apiFB)}
              {this.renderPlatform(Platforms.apiIG)}
              {this.renderPlatform(Platforms.apiIGB)}
            </Fragment>
          </Col>
          <Col>
            <Fragment>
              {this.renderPlatform(Platforms.apiTW)}
              {this.renderPlatform(Platforms.apiYT)}
            </Fragment>
          </Col>
        </Row>
        <Row>
          {loading ? <p>Please wait for the above to finish loading. Then you will be able to import.</p> :<Col>
            {!processing ?
              <Form id="EditInfluencerForm" onSubmit={event => this.handleSubmit(event)} className="mt-4">
                <ButtonGroup >
                  <Button variant="secondary" type="submit" onClick={this.handleSubmit}>
                    Submit
                  </Button>
                </ButtonGroup>
              </Form>
            :
              <p>Processing... <b>Please wait and do not navigate away...</b> This shouldn't take more than a few minutes. You will be redirected once import is complete.</p>
            }
          </Col>}
        </Row>
      </Container >
    );
  }
}
export default makeComponentTrashable(DovetaleList)