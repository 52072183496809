import React, { Component } from 'react'
import {
    Modal,
    Button,
    Form,
    Row,
    Col
} from 'react-bootstrap'

import { transformFieldArray, replaceNullFieldValues } from '../common/FormFieldStateHelper'
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations'
import * as queries from '../../graphql/queries'
import DatePicker from "react-datepicker";
import makeComponentTrashable from 'trashable-react';
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';

import Logger from '../../utils/Logger'
const logger = new Logger("CommunicationModal.js");

class CommunicationModal extends Component {
    _isMounted = false;

    constructor(props) {
        super(props)
        this.fields = ['campaignName', 'communicationId', 'communicationSourceId', 'influencerId', 'notes', 'opened', 'openedTime', 'sentTime', 'subject'];
        this.state = {
            communication: transformFieldArray(this.fields),
            influencersToSend: (this.props.influencerid) ? [this.props.influencerid] : [],
            bulkInfluencers: this.props.bulkinfluencers,
            sentTime: new Date(),
            emails: [],
            mode: "create",
            bulk: (this.props.bulk) ? this.props.bulk : false,
            errors: null,
            numericFields: [
                'influencerId',
                'communicationId',
                'communicationSourceId',
                'opened',
                'sentTime',
                'openedTime'
            ]
        }
    }

    componentDidMount = () => {
        this._isMounted = true;
        if (Number.isInteger(this.props.communicationid)) {
            API.graphql(graphqlOperation(queries.getCommunication, { communicationId: this.props.communicationid}))
            .then(response => {
                if (this._isMounted) {
                    const communicationData = replaceNullFieldValues(response.data.getCommunication);
                    this.setState({ communication: communicationData, mode: 'edit', sentTime: new Date(communicationData.sentTime * 1000)});
                }

            })
            .catch(logger.handleError)
        }

        if (this.props.communication) {
            const communicationData = replaceNullFieldValues(this.props.communication);
            this.setState({ communication: communicationData, mode: 'edit', sentTime: new Date(communicationData.sentTime * 1000)});
        }

        if (this.state.bulk) {
            API.graphql(graphqlOperation(queries.listInfluencers))
            .then(response => this._isMounted && this.setState({ bulkInfluencers: response.data.listInfluencers }))
            .catch(logger.handleError)
        }

    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    handleCommunicationChange = (event) => {
        const communicationCopy = Object.assign({}, this.state.communication);
        communicationCopy[event.target.name] = event.target.value;
        this.setState({ communication: communicationCopy });
    }

    handleSentTimeChange = (date) => {
        this.setState({
            sentTime: date
        })
    }

    clearOutFields = () => {
        this.setState({
            communication: transformFieldArray(this.fields),
            sentTime: new Date(),
            emails: [],
            errors: null
        })
    }

    handleBulkEmailChange = (event) => {
        const {
            bulkInfluencers,
        } = this.state

        // let emails = event.target.value

        let errors = null // We don't care about old errors
        let emails = event.target.value
        let hasBadEmails = false
        let influencersToSend = []

        emails = emails.split('\n')
        emails.forEach((email, indexId) => {
            email = email.trim()
            if (email === "") return
            let findEmail = bulkInfluencers.findIndex(influencer => influencer.emailAddress1 === email || influencer.emailAddress2 === email)
            if (findEmail === -1) {
                // errors.push({message: 'Email address not found : ' + email})
                errors = (errors) ? errors + ', ' + email : 'Email(s) not found : ' + email
                hasBadEmails = true
            } else {
                influencersToSend.push(bulkInfluencers[findEmail].influencerId)
            }
        })

        if (!hasBadEmails) {
            this.setState({
                influencersToSend: influencersToSend,
                errors: errors
            })
        } else {
            this.setState({
                errors: errors
            })
        }

    }

    handleSubmit = (event) => {

        event.preventDefault()

        const {
            sentTime,
            errors,
            communication,
            influencersToSend
        } = this.state

        if (errors) return

        communication.sentTime = Math.floor(sentTime.getTime()/1000)

        if (this.state.mode === 'create') {
            logger.handleTrace('creating communication...')
            communication.communicationSourceId = 3
            communication.opened = 0

            _.forEach(communication, (value, key) => {
                if (value !== '') return;
                if (this.state.numericFields.includes(key)){
                    communication[key] = undefined;
                }
            })

            influencersToSend.forEach((influencerId, indexId) => {
                communication.influencerId = influencerId
                API.graphql(graphqlOperation(mutations.createCommunication, { createCommunicationInput: Object.assign({}, communication)}))
                   .then(logger.handleTrace('Communication created!') && this.setState({communication: {}}))
                   .catch(logger.handleError)
            })
        } else {
            logger.handleTrace('saving communication...')
            const input = _.pick(communication, ['communicationId', 'subject', 'campaignName', 'sentTime', 'notes']);
            API.graphql(graphqlOperation(mutations.updateCommunication, { updateCommunicationInput: input }))
               .then(logger.handleTrace('Communication updated!') && this.setState({communication: {}}))
               .catch(logger.handleError)
        }

        this.clearOutFields()
        this.props.onHide()
    }


    render = () => {

        const {
            mode,
            sentTime,
            communication,
            errors
          } = this.state;

        return (
            <Modal onHide={this.props.onHide} show={this.props.show}>
                <Modal.Header >
                    <Modal.Title>{mode === 'create' ? 'Create' : 'Edit'} Communication</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="CreateCommunicationForm" onSubmit={event => this.handleSubmit(event)}>
                        <Row>
                            <Col>
                              <Form.Group controlId="subject">
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control
                                        maxLength="80"
                                        name="subject"
                                        onChange={this.handleCommunicationChange}
                                        value={communication.subject}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="campaignName">
                                    <Form.Label>Campaign Name</Form.Label>
                                    <Form.Control
                                        maxLength="100"
                                        name="campaignName"
                                        onChange={this.handleCommunicationChange}
                                        value={communication.campaignName}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="sentTime">
                                    <Form.Label>Sent Time</Form.Label>
                                    <div>
                                        <DatePicker
                                            selected={sentTime}
                                            onChange={this.handleSentTimeChange}
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            showTimeInput
                                            timeInputLabel="Time:"
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="notes">
                                    <Form.Label>Notes</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="notes"
                                        onChange={this.handleCommunicationChange}
                                        value={communication.notes}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {this.state.bulk &&
                            <Row>
                                <Col>
                                    {errors &&
                                        <div className="alert alert-danger">
                                            {errors}
                                        </div>
                                    }
                                    <Form.Group controlId="bulkEmail">
                                        <Form.Label>Email (Please include one email per line)</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="10"
                                            name="bulkEmail"
                                            onChange={this.handleBulkEmailChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {!errors &&
                        <Button variant="primary" type="submit" onClick={this.handleSubmit}>Submit</Button>
                    }
                    <Button variant="danger" onClick={() => {this.clearOutFields()
                                                            this.props.onHide()}}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default makeComponentTrashable(CommunicationModal)
