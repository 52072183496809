// eslint-disable
// this is an auto generated file. This will be overwritten

export const getAttachment = `query GetAttachment($attachmentId: Int!) {
  getAttachment(attachmentId: $attachmentId) {
    attachmentId
    influencerId
    s3Id
    type
    label
  }
}
`;
export const listAttachments = `query ListAttachments {
  listAttachments {
    attachmentId
    influencerId
    s3Id
    type
    label
  }
}
`;
export const listAttachmentsByInfluencerId = `query ListAttachmentsByInfluencerId($influencerId: Int!) {
  listAttachmentsByInfluencerId(influencerId: $influencerId) {
    attachmentId
    influencerId
    s3Id
    type
    label
  }
}
`;
export const getCampaign = `query GetCampaign($campaignId: Int!) {
  getCampaign(campaignId: $campaignId) {
    campaignId
    campaignName
    jobNumber
    deliverables
    campaignDescription
    client
    assignment
    dovetaleId
  }
}
`;
export const listCampaigns = `query ListCampaigns($limit: Int, $page: Int) {
  listCampaigns(limit: $limit, page: $page) {
    campaignId
    campaignName
    jobNumber
    deliverables
    campaignDescription
    client
    assignment
    dovetaleId
  }
}
`;
export const listCampaignsByName = `query ListCampaignsByName($name: String!) {
  listCampaignsByName(name: $name) {
    campaignId
    campaignName
    jobNumber
    deliverables
    campaignDescription
    client
    assignment
    dovetaleId
  }
}
`;
export const listCampaignsByInfluencerId = `query ListCampaignsByInfluencerId($influencerId: Int!) {
  listCampaignsByInfluencerId(influencerId: $influencerId) {
    campaignId
    campaignName
    jobNumber
    deliverables
    campaignDescription
    client
    assignment
    dovetaleId
  }
}
`;
export const getCampaignStatIg = `query GetCampaignStatIg($campaignId: Int!) {
  getCampaignStatIG(campaignId: $campaignId) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalReach
    totalImpressions
    totalLikes
    totalComments
    totalVideoViews
    totalEngagements
    totalEngagementRate
    demoAgeUnder21
    demoAge21To34
    demoAgeOver34
    demoGenderMale
    demoGenderFemale
    demoGenderOther
    audienceUS
    audienceCA
    sentimentPositive
    sentimentNeutral
    sentimentNegative
  }
}
`;
export const getCampaignStatIgb = `query GetCampaignStatIgb($campaignId: Int!) {
  getCampaignStatIGB(campaignId: $campaignId) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalImpressions
    totalLikes
    totalComments
    totalEngagementRate
    audienceUS
    audienceCA
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
  }
}
`;
export const getCampaignStatTw = `query GetCampaignStatTw($campaignId: Int!) {
  getCampaignStatTW(campaignId: $campaignId) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalLikes
    totalRetweets
    totalEngagementRate
  }
}
`;
export const getCampaignStatFb = `query GetCampaignStatFb($campaignId: Int!) {
  getCampaignStatFB(campaignId: $campaignId) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalPosts
    totalImpressions
    totalReach
    totalLikes
    totalComments
    totalEngagementRate
    totalShares
    totalReactions
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
  }
}
`;
export const getCampaignStatYt = `query GetCampaignStatYt($campaignId: Int!) {
  getCampaignStatYT(campaignId: $campaignId) {
    campaignId
    earliestPostDate
    latestPostDate
    totalContentCreators
    totalVideos
    totalViews
    totalLikes
    totalDislikes
    totalComments
    totalEngagements
    totalEngagementRate
    totalMinutesWatched
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    audienceUS
    audienceCA
  }
}
`;
export const getCommunication = `query GetCommunication($communicationId: Int!) {
  getCommunication(communicationId: $communicationId) {
    communicationId
    communicationSourceId
    influencerId
    subject
    opened
    sentTime
    openedTime
    notes
    campaignName
  }
}
`;
export const listCommunications = `query ListCommunications {
  listCommunications {
    communicationId
    communicationSourceId
    influencerId
    subject
    opened
    sentTime
    openedTime
    notes
    campaignName
  }
}
`;
export const listCommunicationsByInfluencerId = `query ListCommunicationsByInfluencerId($influencerId: Int!) {
  listCommunicationsByInfluencerId(influencerId: $influencerId) {
    communicationId
    communicationSourceId
    influencerId
    subject
    opened
    sentTime
    openedTime
    notes
    campaignName
  }
}
`;
export const getCommunicationSource = `query GetCommunicationSource($communicationSourceId: Int!) {
  getCommunicationSource(communicationSourceId: $communicationSourceId) {
    communicationSourceId
    displayName
    lastSynced
  }
}
`;
export const listCommunicationSources = `query ListCommunicationSources {
  listCommunicationSources {
    communicationSourceId
    displayName
    lastSynced
  }
}
`;
export const getConfig = `query GetConfig($configId: Int!) {
  getConfig(configId: $configId) {
    configId
    configValue
  }
}
`;
export const listConfigs = `query ListConfigs {
  listConfigs {
    configId
    configValue
  }
}
`;
export const getInfluencer = `query GetInfluencer($influencerId: Int!) {
  getInfluencer(influencerId: $influencerId) {
    influencerId
    unsubscribed
    influencerRelationshipStatusId
    prefix
    firstName
    lastName
    suffix
    bio
    notes
    city
    state
    zip
    country
    phone
    websiteURL
    websiteFollowers
    websiteFollowersUpdatedTime
    blogURL
    constantContactId
    replyId
    emailAddress1
    emailAddress2
    pinterestHandle
    pinterestFollowers
    pinterestFollowersUpdatedTime
    linkedInURL
    linkedInFollowers
    linkedInFollowersUpdatedTime
    IGhandle
    IGengagementRate
    IGfollowers
    IGbio
    IGsponsoredPercentage
    IGdemoAgeUnder21
    IGdemoAge21To34
    IGdemoAgeOver34
    IGdemoGenderMale
    IGdemoGenderFemale
    IGdemoGenderOther
    IGaudienceUS
    USaudienceCA
    TWusername
    TWengagementRate
    TWfollowers
    TWbio
    FBusername
    FBengagementRate
    FBpageLikes
    FBbio
    YTusername
    YTengagementRate
    YTsubscribers
    YTbio
    YTviewsPerVideo
    YTchannelUrl
    relationshipStatus
    customTags
    tumblrHandle
    snapchatHandle
    surveyDate
    creationTime
    creationSource
    totalFollowing
    addressLine1
    resultsCount
  }
}
`;
export const listInfluencers = `query ListInfluencers(
  $filter: ModelInfluencersFilterInput
  $limit: Int
  $page: Int
) {
  listInfluencers(filter: $filter, limit: $limit, page: $page) {
    influencerId
    unsubscribed
    influencerRelationshipStatusId
    prefix
    firstName
    lastName
    suffix
    bio
    notes
    city
    state
    zip
    country
    phone
    websiteURL
    websiteFollowers
    websiteFollowersUpdatedTime
    blogURL
    constantContactId
    replyId
    emailAddress1
    emailAddress2
    pinterestHandle
    pinterestFollowers
    pinterestFollowersUpdatedTime
    linkedInURL
    linkedInFollowers
    linkedInFollowersUpdatedTime
    IGhandle
    IGengagementRate
    IGfollowers
    IGbio
    IGsponsoredPercentage
    IGdemoAgeUnder21
    IGdemoAge21To34
    IGdemoAgeOver34
    IGdemoGenderMale
    IGdemoGenderFemale
    IGdemoGenderOther
    IGaudienceUS
    USaudienceCA
    TWusername
    TWengagementRate
    TWfollowers
    TWbio
    FBusername
    FBengagementRate
    FBpageLikes
    FBbio
    YTusername
    YTengagementRate
    YTsubscribers
    YTbio
    YTviewsPerVideo
    YTchannelUrl
    relationshipStatus
    customTags
    tumblrHandle
    snapchatHandle
    surveyDate
    creationTime
    creationSource
    totalFollowing
    addressLine1
    resultsCount
  }
}
`;
export const listInfluencersByCampaignId = `query ListInfluencersByCampaignId($campaignId: Int!) {
  listInfluencersByCampaignId(campaignId: $campaignId) {
    influencerId
    unsubscribed
    influencerRelationshipStatusId
    prefix
    firstName
    lastName
    suffix
    bio
    notes
    city
    state
    zip
    country
    phone
    websiteURL
    websiteFollowers
    websiteFollowersUpdatedTime
    blogURL
    constantContactId
    replyId
    emailAddress1
    emailAddress2
    pinterestHandle
    pinterestFollowers
    pinterestFollowersUpdatedTime
    linkedInURL
    linkedInFollowers
    linkedInFollowersUpdatedTime
    IGhandle
    IGengagementRate
    IGfollowers
    IGbio
    IGsponsoredPercentage
    IGdemoAgeUnder21
    IGdemoAge21To34
    IGdemoAgeOver34
    IGdemoGenderMale
    IGdemoGenderFemale
    IGdemoGenderOther
    IGaudienceUS
    USaudienceCA
    TWusername
    TWengagementRate
    TWfollowers
    TWbio
    FBusername
    FBengagementRate
    FBpageLikes
    FBbio
    YTusername
    YTengagementRate
    YTsubscribers
    YTbio
    YTviewsPerVideo
    YTchannelUrl
    relationshipStatus
    customTags
    tumblrHandle
    snapchatHandle
    surveyDate
    creationTime
    creationSource
    totalFollowing
    addressLine1
    resultsCount
  }
}
`;
export const listInfluencersByListId = `query ListInfluencersByListId($listId: Int!) {
  listInfluencersByListId(listId: $listId) {
    influencerId
    unsubscribed
    influencerRelationshipStatusId
    prefix
    firstName
    lastName
    suffix
    bio
    notes
    city
    state
    zip
    country
    phone
    websiteURL
    websiteFollowers
    websiteFollowersUpdatedTime
    blogURL
    constantContactId
    replyId
    emailAddress1
    emailAddress2
    pinterestHandle
    pinterestFollowers
    pinterestFollowersUpdatedTime
    linkedInURL
    linkedInFollowers
    linkedInFollowersUpdatedTime
    IGhandle
    IGengagementRate
    IGfollowers
    IGbio
    IGsponsoredPercentage
    IGdemoAgeUnder21
    IGdemoAge21To34
    IGdemoAgeOver34
    IGdemoGenderMale
    IGdemoGenderFemale
    IGdemoGenderOther
    IGaudienceUS
    USaudienceCA
    TWusername
    TWengagementRate
    TWfollowers
    TWbio
    FBusername
    FBengagementRate
    FBpageLikes
    FBbio
    YTusername
    YTengagementRate
    YTsubscribers
    YTbio
    YTviewsPerVideo
    YTchannelUrl
    relationshipStatus
    customTags
    tumblrHandle
    snapchatHandle
    surveyDate
    creationTime
    creationSource
    totalFollowing
    addressLine1
    resultsCount
  }
}
`;
export const listDuplicateInfluencers = `query ListDuplicateInfluencers($filter: InfluencerDuplicateInput) {
  listDuplicateInfluencers(filter: $filter) {
    influencerId
    unsubscribed
    influencerRelationshipStatusId
    prefix
    firstName
    lastName
    suffix
    bio
    notes
    city
    state
    zip
    country
    phone
    websiteURL
    websiteFollowers
    websiteFollowersUpdatedTime
    blogURL
    constantContactId
    replyId
    emailAddress1
    emailAddress2
    pinterestHandle
    pinterestFollowers
    pinterestFollowersUpdatedTime
    linkedInURL
    linkedInFollowers
    linkedInFollowersUpdatedTime
    IGhandle
    IGengagementRate
    IGfollowers
    IGbio
    IGsponsoredPercentage
    IGdemoAgeUnder21
    IGdemoAge21To34
    IGdemoAgeOver34
    IGdemoGenderMale
    IGdemoGenderFemale
    IGdemoGenderOther
    IGaudienceUS
    USaudienceCA
    TWusername
    TWengagementRate
    TWfollowers
    TWbio
    FBusername
    FBengagementRate
    FBpageLikes
    FBbio
    YTusername
    YTengagementRate
    YTsubscribers
    YTbio
    YTviewsPerVideo
    YTchannelUrl
    relationshipStatus
    customTags
    tumblrHandle
    snapchatHandle
    surveyDate
    creationTime
    creationSource
    totalFollowing
    addressLine1
    resultsCount
  }
}
`;
export const getInfluencerCampaign = `query GetInfluencerCampaign($campaignId: Int!, $influencerId: Int!) {
  getInfluencerCampaign(campaignId: $campaignId, influencerId: $influencerId) {
    influencerId
    campaignId
    feePaid
  }
}
`;
export const listInfluencerCampaigns = `query ListInfluencerCampaigns {
  listInfluencerCampaigns {
    influencerId
    campaignId
    feePaid
  }
}
`;
export const listInfluencerCampaignDetailsByCampaignInfluencerId = `query ListInfluencerCampaignDetailsByCampaignInfluencerId(
  $influencerId: Int
  $campaignId: Int
) {
  listInfluencerCampaignDetailsByCampaignInfluencerId(
    influencerId: $influencerId
    campaignId: $campaignId
  ) {
    influencerId
    campaignId
    feePaid
    firstName
    lastName
    emailAddress1
    campaignName
    jobNumber
    deliverables
    campaignDescription
    client
    assignment
    dovetaleId
  }
}
`;
export const getInfluencerList = `query GetInfluencerList($listId: Int!) {
  getInfluencerList(listId: $listId) {
    influencerId
    listId
  }
}
`;
export const listInfluencerLists = `query ListInfluencerLists {
  listInfluencerLists {
    influencerId
    listId
  }
}
`;
export const listInfluencerListsByInfluencerId = `query ListInfluencerListsByInfluencerId($influencerId: Int!) {
  listInfluencerListsByInfluencerId(influencerId: $influencerId) {
    listId
    name
    dovetaleId
  }
}
`;
export const listPostBlogsByInfluencerCampaignId = `query ListPostBlogsByInfluencerCampaignId(
  $influencerId: Int
  $campaignId: Int
) {
  listPostBlogsByInfluencerCampaignId(
    influencerId: $influencerId
    campaignId: $campaignId
  ) {
    postBlogId
    influencerId
    campaignId
    link
    postedAt
    impressions
  }
}
`;
export const listPostInstagramsByInfluencerCampaignId = `query ListPostInstagramsByInfluencerCampaignId(
  $influencerId: Int
  $campaignId: Int
) {
  listPostInstagramsByInfluencerCampaignId(
    influencerId: $influencerId
    campaignId: $campaignId
  ) {
    postInstagramId
    influencerId
    campaignId
    link
    contentUrl
    caption
    postedAt
    postedBy
    engagementRate
    estimatedImpressions
    likes
    demoAgeUnder21
    demoAge21To34
    demoAgeOver34
    demoGenderMale
    demoGenderFemale
    demoGenderOther
    tags
    reach
    impressions
    engagements
    comments
    shares
  }
}
`;
export const listPostInstagramBusinesssByInfluencerCampaignId = `query ListPostInstagramBusinesssByInfluencerCampaignId(
  $influencerId: Int
  $campaignId: Int
) {
  listPostInstagramBusinesssByInfluencerCampaignId(
    influencerId: $influencerId
    campaignId: $campaignId
  ) {
    postInstagramBusinessId
    influencerId
    campaignId
    link
    caption
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    engagementRate
    impressions
    likes
    mediaUrl
    postedAt
    postedBy
    reach
    replies
    tags
  }
}
`;
export const listPostTwittersByInfluencerCampaignId = `query ListPostTwittersByInfluencerCampaignId(
  $influencerId: Int
  $campaignId: Int
) {
  listPostTwittersByInfluencerCampaignId(
    influencerId: $influencerId
    campaignId: $campaignId
  ) {
    postTwitterId
    influencerId
    campaignId
    link
    engagementRate
    followers
    likes
    postedAt
    postedBy
    reach
    retweets
    tags
    tweet
    engagements
  }
}
`;
export const listPostFacebooksByInfluencerCampaignId = `query ListPostFacebooksByInfluencerCampaignId(
  $influencerId: Int
  $campaignId: Int
) {
  listPostFacebooksByInfluencerCampaignId(
    influencerId: $influencerId
    campaignId: $campaignId
  ) {
    postFacebookId
    influencerId
    campaignId
    link
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    engagementRate
    impressions
    likes
    message
    postedAt
    postedBy
    reach
    reactions
    shares
    tags
    thumbnailUrl
    engagements
    comments
  }
}
`;
export const listPostYoutubesByInfluencerCampaignId = `query ListPostYoutubesByInfluencerCampaignId(
  $influencerId: Int
  $campaignId: Int
) {
  listPostYoutubesByInfluencerCampaignId(
    influencerId: $influencerId
    campaignId: $campaignId
  ) {
    postYoutubeId
    influencerId
    campaignId
    link
    comments
    demoFemale13To17
    demoFemale18To24
    demoFemale25To34
    demoFemale35To44
    demoFemale45To54
    demoFemale55To64
    demoFemaleOver65
    demoFemale
    demoMale13To17
    demoMale18To24
    demoMale25To34
    demoMale35To44
    demoMale45To54
    demoMale55To64
    demoMaleOver65
    demoMale
    description
    dislikes
    engagementRate
    estimatedMinutesWatched
    likes
    postedAt
    postedBy
    shares
    tags
    thumbnailUrl
    title
    views
    audienceUS
    audienceCA
    reach
    engagements
  }
}
`;
export const getInfluencerProfilePic = `query GetInfluencerProfilePic($influencerProfilePicId: Int!) {
  getInfluencerProfilePic(influencerProfilePicId: $influencerProfilePicId) {
    influencerProfilePicId
    influencerId
    s3Id
    label
  }
}
`;
export const listInfluencerProfilePics = `query ListInfluencerProfilePics {
  listInfluencerProfilePics {
    influencerProfilePicId
    influencerId
    s3Id
    label
  }
}
`;
export const listInfluencerProfilePicsByInfluencerId = `query ListInfluencerProfilePicsByInfluencerId($influencerId: Int!) {
  listInfluencerProfilePicsByInfluencerId(influencerId: $influencerId) {
    influencerProfilePicId
    influencerId
    s3Id
    label
  }
}
`;
export const getInfluencerRelationshipStatus = `query GetInfluencerRelationshipStatus($influencerRelationshipStatusId: Int!) {
  getInfluencerRelationshipStatus(
    influencerRelationshipStatusId: $influencerRelationshipStatusId
  ) {
    influencerRelationshipStatusId
    relationshipLabel
  }
}
`;
export const listInfluencerRelationshipStatuss = `query ListInfluencerRelationshipStatuss {
  listInfluencerRelationshipStatuss {
    influencerRelationshipStatusId
    relationshipLabel
  }
}
`;
export const getInfluencerStatIg = `query GetInfluencerStatIg($influencerId: Int!) {
  getInfluencerStatIG(influencerId: $influencerId) {
    influencerId
    handle
    engagementRate
    engagementRateLastUpdated
    followers
    followersLastUpdated
    bio
    sponsoredPercentage
    demoAgeUnder21
    demoAge21To34
    demoAgeOver34
    demoGenderMale
    demoGenderFemale
    demoGenderOther
    audienceUS
    audienceCA
  }
}
`;
export const getInfluencerStatTw = `query GetInfluencerStatTw($influencerId: Int!) {
  getInfluencerStatTW(influencerId: $influencerId) {
    influencerId
    username
    engagementRate
    engagementRateLastUpdated
    followers
    followersLastUpdated
    bio
  }
}
`;
export const getInfluencerStatFb = `query GetInfluencerStatFb($influencerId: Int!) {
  getInfluencerStatFB(influencerId: $influencerId) {
    influencerId
    username
    engagementRate
    engagementRateLastUpdated
    pageLikes
    pageLikesLastUpdated
    bio
  }
}
`;
export const getInfluencerStatYt = `query GetInfluencerStatYt($influencerId: Int!) {
  getInfluencerStatYT(influencerId: $influencerId) {
    influencerId
    username
    channelUrl
    engagementRate
    engagementRateLastUpdated
    subscribers
    subscribersLastUpdated
    bio
    viewsPerVideo
  }
}
`;
export const getInfluencerTagValue = `query GetInfluencerTagValue($tagValueId: Int!) {
  getInfluencerTagValue(tagValueId: $tagValueId) {
    influencerId
    tagValueId
  }
}
`;
export const listInfluencerTagValues = `query ListInfluencerTagValues {
  listInfluencerTagValues {
    influencerId
    tagValueId
  }
}
`;
export const listInfluencerTagValuesByInfluencerId = `query ListInfluencerTagValuesByInfluencerId($influencerId: Int!) {
  listInfluencerTagValuesByInfluencerId(influencerId: $influencerId) {
    influencerId
    tagValueId
  }
}
`;
export const getList = `query GetList($listId: Int!) {
  getList(listId: $listId) {
    listId
    name
    dovetaleId
  }
}
`;
export const listLists = `query ListLists($limit: Int, $page: Int) {
  listLists(limit: $limit, page: $page) {
    listId
    name
    dovetaleId
  }
}
`;
export const searchLists = `query SearchLists($dovetaleId: Int, $name: String) {
  searchLists(dovetaleId: $dovetaleId, name: $name) {
    listId
    name
    dovetaleId
  }
}
`;
export const getTag = `query GetTag($tagId: Int!) {
  getTag(tagId: $tagId) {
    tagId
    label
  }
}
`;
export const listTags = `query ListTags {
  listTags {
    tagId
    label
  }
}
`;
export const getTagValue = `query GetTagValue($tagValueId: Int!) {
  getTagValue(tagValueId: $tagValueId) {
    tagValueId
    tagId
    label
  }
}
`;
export const listTagValues = `query ListTagValues {
  listTagValues {
    tagValueId
    tagId
    label
  }
}
`;
