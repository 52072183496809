import React from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../../graphql/queries'
import uuid from 'uuid/v1'

import {
  Button,
  ButtonGroup,
  Table,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'

import withPermissions from '../../security/withPermissions';
import { convertISOToLocalDate, renderLoading } from '../../utils/format'
import makeComponentTrashable from 'trashable-react';
import Bottleneck from 'bottleneck'

import Logger from '../../utils/Logger'
const logger = new Logger("ListDovetaleCampaigns.js")

class ListDovetaleCampaigns extends React.Component {

  gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

  constructor(props) {
    super(props);
    this.state = {
      loadingCampaigns: true
    }
  }

  componentDidMount() {
    this.graphqlOp(graphqlOperation(queries.listCampaigns)).then(waCampaigns => {
      let dovetaleIds = waCampaigns ? waCampaigns.data.listCampaigns.map(i => i.dovetaleId) : []
      API.get("wellnessamplifiedapi", `/dovetale/reports`)
        .then(dovetaleData => {
          let filteredCampaigns = (dovetaleData && dovetaleData.reports) ? dovetaleData.reports.filter(i => i.isPartOfCampaign && dovetaleIds.indexOf(i.id) === -1) : [];       
          this.setState({ loadingCampaigns: false, campaigns: filteredCampaigns });
      }).catch(logger.handleError)
    }).catch(logger.handleError);
  }

  renderCampaigns = data => {
    if (!data) return
    return (
      <div className="mt-4">
        <Table striped bordered>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => (
              <tr key={uuid()}>
                <td>
                  <ButtonGroup>
                    {this.props.permissions.can("syncCampaigns") && <Link to={`/campaigns/dovetale/${item.id}/import`}><Button size="sm" variant="outline-secondary">Import</Button></Link>}
                  </ButtonGroup>
                </td>
                <td>{item.name}</td>
                <td>{convertISOToLocalDate(item.created_at)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }


  render() {
    const {
      loadingCampaigns,
      campaigns } = this.state;

    return loadingCampaigns ? renderLoading('Campaigns') : this.renderCampaigns(campaigns);
  }
}

export default makeComponentTrashable(withPermissions(ListDovetaleCampaigns))