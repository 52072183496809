import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider';

import awsmobile from '../aws-exports';
import { Auth } from 'aws-amplify';
import Roles from '../security/Roles'

export default {
    getIdentityServiceProvider: async () => {
        const credentials = await Auth.currentCredentials();
        return await Promise.resolve(new CognitoIdentityServiceProvider({
            apiVersion: '2016-04-18',
            credentials: Auth.essentialCredentials(credentials),
            region: awsmobile.aws_project_region
        }));
    },

    getRolePoolMapping: (client) => {
        return client.listGroups({UserPoolId: awsmobile.aws_user_pools_id}).promise()
        .then(groups => 
            groups.Groups.map(group => {
                const role = Roles.getAllRoles().find(role => group.GroupName.includes(role.group));
                return {roleid: role ? role.roleid : 999,
                groupname: group.GroupName}
            })
        )
    },

    createUser: (username, name, email, roleid, rolePoolMapping, client) => {
        var groupName = rolePoolMapping.find(entry => entry.roleid === roleid).groupname;
        return client.adminCreateUser({
            UserPoolId: awsmobile.aws_user_pools_id,
            Username: username,
            DesiredDeliveryMediums: ["EMAIL"],
            UserAttributes: [
                {Name: 'email', Value: email.trim()},
                {Name: 'name', Value: name},
                {Name: 'email_verified', Value: "true"}
            ]
        }).promise()
        .then(() => client.adminAddUserToGroup({GroupName: groupName, UserPoolId: awsmobile.aws_user_pools_id, Username: username}).promise());
    },

    updateUser: (username, newName, newEmail, client) => 
        client.adminUpdateUserAttributes({
            UserPoolId: awsmobile.aws_user_pools_id,
            Username: username,
            UserAttributes: [
                {Name: 'email', Value: newEmail.trim()},
                {Name: 'name', Value: newName},
                {Name: 'email_verified', Value: "true"}
            ]
        }).promise(),

    changeUserEnabled: (username, newEnabledStatus, client) => {
        if (newEnabledStatus)
            return client.adminEnableUser({UserPoolId: awsmobile.aws_user_pools_id, Username: username}).promise();
        else
            return client.adminDisableUser({UserPoolId: awsmobile.aws_user_pools_id, Username: username}).promise();
    },

    deleteUser: (username, client) => client.adminDeleteUser({UserPoolId: awsmobile.aws_user_pools_id, Username: username}).promise(),

    switchUserGroup: (username, oldRoleId, newRoleId, rolePoolMapping, client) => {
        var currentGroup = rolePoolMapping.find(entry => entry.roleid === oldRoleId).groupname;
        var newGroup = rolePoolMapping.find(entry => entry.roleid === newRoleId).groupname;

        return Promise.all([
            client.adminRemoveUserFromGroup({GroupName: currentGroup, UserPoolId: awsmobile.aws_user_pools_id, Username: username}).promise(),
            client.adminAddUserToGroup({GroupName: newGroup, UserPoolId: awsmobile.aws_user_pools_id, Username: username}).promise()
        ]);
    },

    processUser: (user, client) => {
        var attr = user.UserAttributes || user.Attributes;
        var output = attr.reduce((obj, item) => {
            obj[item.Name] = item.Value
            return obj
        }, {})

        output.enabled = user.Enabled;
        output.username = user.Username;

        output.original = user;

        return client.adminListGroupsForUser({UserPoolId: awsmobile.aws_user_pools_id, Username: user.Username}).promise()
        .then(data => {
            var role = Roles.getAllRoles().find(role => data.Groups[0].GroupName.includes(role.group));
            output.rolename = role.name;
            output.roleid = role.roleid;
            return output;
            }
        )
    },

    userPoolId: awsmobile.aws_user_pools_id
}