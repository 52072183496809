import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import {
  Alert,
  Card,
  Col,
  Container,
  Row,
  Table,
  Button
} from 'react-bootstrap'

import * as mutations from '../../graphql/mutations'
import * as queries from '../../graphql/queries'
import * as format from '../../utils/format'
import PostStatModal from '../common/PostStatModal'
import makeComponentTrashable from 'trashable-react';
import uuid from 'uuid/v1'
import { undoEscapesJson } from '../../utils/format'
import Bottleneck from 'bottleneck'
import _ from 'lodash'
import withPermissions from '../../security/withPermissions'

import Logger from '../../utils/Logger'
const logger = new Logger("SocialPosts.js");

class SocialPosts extends Component {
  gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

  constructor(props) {
    super(props)

    this.state = {
      loadingFbPosts: true,
      loadingIgPosts: true,
      loadingIgbPosts: true,
      loadingTwPosts: true,
      loadingYtPosts: true,
      loadingBlogPosts: true,
      campaignPostsFb: [],
      campaignPostsIg: [],
      campaignPostsIgb: [],
      campaignPostsTw: [],
      campaignPostsYt: [],
      campaignPostsBlog: [],
      displayTitle: this.props.displaytitle || true,
      showPostStat: false,
      activePostStat: {},
      activePostPlatform: null
    }
  }

  componentDidMount() {
    let influencerId = this.props.influencerid || _.get(this.props, 'match.params.influencerId', null)
    let campaignId = this.props.campaignId || _.get(this.props, 'match.params.campaignId', null)

    this.getSocialPosts(campaignId, influencerId)
  }

  getSocialPosts = (campaignId, influencerId) => {
    var input = {
      ...(campaignId && {campaignId}),
      ...(influencerId && {influencerId})
    }

    this.graphqlOp(graphqlOperation(queries.listPostFacebooksByInfluencerCampaignId, input)).then(response => {
      this.setState({ loadingFbPosts: false, campaignPostsFb: undoEscapesJson(response.data.listPostFacebooksByInfluencerCampaignId) })
    }).catch(logger.handleError)

    this.graphqlOp(graphqlOperation(queries.listPostInstagramsByInfluencerCampaignId, input)).then(response => {
      this.setState({ loadingIgPosts: false, campaignPostsIg: undoEscapesJson(response.data.listPostInstagramsByInfluencerCampaignId) })
    }).catch(logger.handleError)

    this.graphqlOp(graphqlOperation(queries.listPostInstagramBusinesssByInfluencerCampaignId, input)).then(response => {
      this.setState({ loadingIgbPosts: false, campaignPostsIgb: undoEscapesJson(response.data.listPostInstagramBusinesssByInfluencerCampaignId) })
    }).catch(logger.handleError)

    this.graphqlOp(graphqlOperation(queries.listPostTwittersByInfluencerCampaignId, input)).then(response => {
      this.setState({ loadingTwPosts: false, campaignPostsTw: undoEscapesJson(response.data.listPostTwittersByInfluencerCampaignId) })
    }).catch(logger.handleError)

    this.graphqlOp(graphqlOperation(queries.listPostYoutubesByInfluencerCampaignId, input)).then(response => {
      this.setState({ loadingYtPosts: false, campaignPostsYt: undoEscapesJson(response.data.listPostYoutubesByInfluencerCampaignId) })
    }).catch(logger.handleError)

    this.graphqlOp(graphqlOperation(queries.listPostBlogsByInfluencerCampaignId, input)).then(response => {
      this.setState({ loadingBlogPosts: false, campaignPostsBlog: undoEscapesJson(response.data.listPostBlogsByInfluencerCampaignId) })
    }).catch(logger.handleError)
  }

  removePostFromPage = (postId, idKey) => {
    this.setState(state => {
      let stateUpdate = null;
      ["campaignPostsFb", "campaignPostsIg", "campaignPostsIgb", "campaignPostsTw", "campaignPostsYt", "campaignPostsBlog"].forEach(s => {
        const match = state[s].find(p => p[idKey] === postId)
        if (match) stateUpdate = {[s]: state[s].filter(p => p[idKey] !== postId)}
      })
      return stateUpdate
    })
  }

  removePost = (post) => {
    const idKey = Object.keys(post).find(key => key.match(/post.+Id/))
    const platform = idKey.match(/post(.+)Id/)[1]
    const postId = post[idKey]
    this.graphqlOp(graphqlOperation(mutations["deletePost" + platform], {[idKey]: postId})).then(response => {
      this.removePostFromPage(postId, idKey)
    }).catch(logger.handleError)
  }

  renderPlatformPosts = (platform, data) => {
    if (!data) return

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>{platform}</Card.Title>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Link</th>
                <th>More Information</th>
                <th>Posted At</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? _.orderBy(data, ['postedAt'], ['desc']).map(item => (
                <tr key={uuid()}>
                  <td>{this.props.permissions.can("editCampaigns") && <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={
                            () => {window.confirm('Are you sure you wish to delete this post? This cannot be undone!') && this.removePost(item) }
                          }>
                            Delete
                          </Button>}</td>
                  <td>{format.renderExternalUrl(item.link)}</td>
                  <td><Button onClick={() => this.setState({activePostStat: item, showPostStat: true, activePostPlatform: platform})}>Stats</Button></td>
                  <td>{format.renderTimeStamp(item.postedAt)}</td>
                </tr>
              )) : <tr>
                  <td colSpan="2">No posts found for this platform.</td>
                </tr>}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    )
  }

  renderLoading = message => {
    return (
      <Row>
        <Col>
          <Alert variant="info" className="mt-4">Loading {message}...</Alert>
        </Col>
      </Row>
    )
  }

  render() {

    const {
      loadingFbPosts,
      campaignPostsFb,
      loadingIgPosts,
      campaignPostsIg,
      loadingIgbPosts,
      campaignPostsIgb,
      loadingTwPosts,
      campaignPostsTw,
      loadingYtPosts,
      campaignPostsYt,
      loadingBlogPosts,
      campaignPostsBlog,
      displayTitle,
      showPostStat,
      activePostStat,
      activePostPlatform} = this.state

    return (
      <Container fluid>
        <PostStatModal
          show={showPostStat}
          onHide={() => this.setState({showPostStat: false})}
          post={activePostStat}
          platform={activePostPlatform}
        />
        {displayTitle &&
          <Row>
          <Col>
            <h1>Influencer Campaign Posts</h1>
          </Col>
        </Row>
        }

        <Row>
          <Col>
            {loadingFbPosts ? this.renderLoading('Facebook Posts') : this.renderPlatformPosts('Facebook', campaignPostsFb)}
            {loadingIgPosts ? this.renderLoading('Instagram Posts') : this.renderPlatformPosts('Instagram', campaignPostsIg)}
            {loadingIgbPosts ? this.renderLoading('Instagram Business Posts') : this.renderPlatformPosts('Instagram Business', campaignPostsIgb)}
          </Col>
          <Col>
            {loadingTwPosts ? this.renderLoading('Twitter Posts') : this.renderPlatformPosts('Twitter', campaignPostsTw)}
            {loadingYtPosts ? this.renderLoading('YouTube Posts') : this.renderPlatformPosts('YouTube', campaignPostsYt)}
            {loadingBlogPosts ? this.renderLoading('Blog Posts') : this.renderPlatformPosts('Blog', campaignPostsBlog)}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default makeComponentTrashable(withPermissions(SocialPosts))