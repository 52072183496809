import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as mutations from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import DatePicker from "react-datepicker";
import { getTimestampFromMilli, convertISOToLocalDate, getDateFromTimestamp, escapeJson } from '../../utils/format'
import PropTypes from 'prop-types'
import makeComponentTrashable from 'trashable-react';
import Bottleneck from 'bottleneck'
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';
import Logger from '../../utils/Logger'
const logger = new Logger("SurveyDateModal.js");

class SurveyDateModal extends Component {
  gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

  constructor(props) {
    super(props)
    this.state = {
      influencer: props.influencer,
      date: getDateFromTimestamp(props.influencer.surveyDate) || new Date()
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(date) {
        this.setState({ date });
  }

  handleSubmit() {
    const newTimestamp = getTimestampFromMilli(this.state.date.getTime());
    this.props.onChange(newTimestamp);
    const input = _.pick(this.state.influencer, ['influencerId', 'unsubscribed', 'surveyDate']);
    input.surveyDate = newTimestamp;
    this.graphqlOp(graphqlOperation(mutations.updateInfluencer, escapeJson({ updateInfluencerInput: input })))
    .then(this.props.onHide())
    .catch(logger.handleError)
  }
  
  render() {

    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header>
            <Modal.Title>Survey Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <p>Select the date on which the survey was taken:</p>
                <DatePicker
                    selected={this.state.date}
                    onChange={this.handleChange}
                    startDate={convertISOToLocalDate(this.state.surveyDate)}
                />
            </div>
                    
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" type="submit" onClick={this.handleSubmit}>Submit</Button>
            <Button variant="danger" onClick={this.props.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    )
  }

}

SurveyDateModal.propTypes = {
  /** Callback to update survey date on influencer object. Will be passed the new survey date. */
  onChange: PropTypes.func.isRequired,
  /** The influencer to edit */
  influencer: PropTypes.object.isRequired,
  /** Should hide the modal */
  onHide: PropTypes.func.isRequired,
  /** Should determine whether modal is opened or not */
  show: PropTypes.bool.isRequired
}

export default makeComponentTrashable(SurveyDateModal)