import React from 'react'
import ConfirmDeleteModal from '../common/ConfirmDeleteModal';
import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { Redirect } from 'react-router-dom';
import makeComponentTrashable from 'trashable-react';
import Bottleneck from 'bottleneck'
import Logger from '../../utils/Logger'
const logger = new Logger("DeleteList.js");
  

class DeleteList extends React.Component {
    gqlLimiter = new Bottleneck({minTime: 50, maxConcurrent: 10})
graphqlOp = (op) => this.props.registerPromise(this.gqlLimiter.schedule(() => API.graphql(op)))

    constructor(props) {
        super(props);
        this.state = {
          loadingList: true,
          cancel: false,
          confirm: false
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.graphqlOp(graphqlOperation(queries.getList, { listId: id }))
                .then(response => this.setState({ loadingList: false, list: response.data.getList }))
                .catch(logger.handleError);
        }
    }

    deleteList(id) {
        this.graphqlOp(graphqlOperation(mutations.deleteList, { listId: id }))
            .then(() => this.setState({confirm: true}))
            .catch(logger.handleError);
    }
    
    render() {
        return this.state.loadingList ||
            <React.Fragment>
                {this.state.cancel && <Redirect to="/lists" />}
                {this.state.confirm && <Redirect to="/lists/r" />}
                <ConfirmDeleteModal
                    confirmmessage={"Are you sure you want to delete the list " + this.state.list.name + "?"}
                    cancelaction={() => this.setState({cancel: true})}
                    confirmaction={() => this.deleteList(this.state.list.listId)}
                />
            </React.Fragment>;
    }
}

export default makeComponentTrashable(DeleteList)